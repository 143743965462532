import React, { useState } from 'react';

function TradeCorrectionEmails(props) {

    const [errorMessage, setErrorMessage] = useState("")
    const [message, setMessage] = useState("")

    async function sendEmails() {
        if (props.role >= 3) {
            const url = "/api/tradeCorrectionEmails?message=" + message;
            console.log(url);
            const r = await (await fetch(url)).json();
            console.log(r);
            setErrorMessage(JSON.stringify(r));
        } else
            setErrorMessage("Not allowed");
    }

    function handleInputChange(event) {
        console.log("Updating message to " + event.target.value);
        setMessage(event.target.value)
    }

    return (
        <>
            <form>
                {errorMessage}
                <br></br>
                <textarea maxLength={200} cols={50} rows={5} value={message} onChange={handleInputChange}></textarea>
                <br></br>
                <button class="btn btn-primary" onClick={sendEmails} type="button">Send Emails</button>
            </form>
        </>
    );
}

export default TradeCorrectionEmails;