import DailyBalanceChart from './DailyBalanceChart';
import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom'
import Tooltip from '../components/Tooltip';

function Replay(props) {

    const [backtestResults, setBacktestResults] = useState({});
    const [chartData, setChartData] = useState([]);
    const [currentDate, setCurrentDate] = useState(null);
    const [maxValue, setMaxValue] = useState(0);
    const [baselineMaxValue, setBaselineMaxValue] = useState(0);
    const [trades, setTrades] = useState([]);
    const [positions, setPositions] = useState([]);
    const [showTrades, setShowTrades] = useState(false)

    useEffect(() => {
        (async function () {

            const url = `/api/lastBacktestResults?planId=` + props.planId + "&liveTradesOnly=0";
            let text = await (await fetch(url)).json();

            const tempChartData = [];//[["Date", "Account Value", "S&P 500"]]
            text.dailyResults.forEach(day => tempChartData.push([new Date(day.date), day.total, day.baseline]))
            setChartData(tempChartData)
            if (text.dailyResults && text.dailyResults.length) {
                setCurrentDate(new Date(text.dailyResults[0].date))
            }
            setBacktestResults(text);

        })();
    }, [props.planId]);

    function updateDate(date) {
        setCurrentDate(date);
        handleNextClicked(date, true, 0);
    }

    function isDateEqualOrAfter(date, otherDate) {
        if (otherDate.getFullYear() > date.getFullYear()) return true;
        if (otherDate.getFullYear() === date.getFullYear() && otherDate.getMonth() > date.getMonth()) return true;
        if (otherDate.getFullYear() === date.getFullYear() && otherDate.getMonth() === date.getMonth() && otherDate.getDate() >= date.getDate()) return true;
        return false;
    }

    async function loadTradePreview() {
        setTrades(null);
        const url = `/api/backtestTradesOnDate?planId=` + props.planId + `&date=` + currentDate;
        const trades = await (await fetch(url)).json();
        setTrades(trades);
    }

    async function loadPositionsPreview() {
        setPositions(null);
        const url = `/api/backtestPositionsOnDate?planId=` + props.planId + `&date=` + currentDate;
        const p = await (await fetch(url)).json();
        setPositions(p);
    }

    function getTradePreviewJson() {
        if (trades === null) {
            return ["Loading..."]
        } else if (trades.length === 0) {
            return ["None"]
        } else {
            let result = []
            trades.sort((a, b) => a.action < b.action ? -1 : 1).forEach(t => {
                result.push(t.action + " " + t.percentage + "% of " + t.trade_symbol + " by " + t.display_name + " (" + t.profit.toFixed(2) + "%)");
            })
            return result;
        }
    }

    function getPositionsPreviewJson() {
        if (positions === null) {
            return ["Loading..."]
        } else if (positions.length === 0) {
            return ["None"]
        } else {
            let result = []
            positions.forEach(p => {
                result.push(p.percentage + "% of " + p.trade_symbol + " by " + p.display_name);
            })
            return result;
        }
    }

    function getPriorDate(date) {
        let lastDate = null;
        if (backtestResults && backtestResults.dailyResults) {
            for (let i = 0; i < backtestResults.dailyResults.length; i++) {
                let d = new Date(backtestResults.dailyResults[i].date);
                if (isDateEqualOrAfter(date, d))
                    return lastDate;
                else
                    lastDate = new Date(d);
            }
        }
        return lastDate;
    }

    function updateState(maxValue, baselineMaxValue, currentDate, chartData) {
        setMaxValue(maxValue);
        setBaselineMaxValue(baselineMaxValue);
        setCurrentDate(currentDate);
        setChartData(chartData)
    }

    function handleNextClicked(date, shouldAdvance, advanceDays) {

        console.log("handleNextClicked", date, advanceDays)
        const tempChartData = [];
        let targetDate = new Date(date);
        if (advanceDays < 0) {
            if (advanceDays < -1) targetDate.setDate(targetDate.getDate() + advanceDays);
            targetDate = getPriorDate(targetDate);
            if (targetDate === null) return;
        } else {
            targetDate.setDate(targetDate.getDate() + advanceDays);
        }

        if (showTrades) loadTradePreview();

        if (backtestResults && backtestResults.dailyResults && backtestResults.dailyResults.length) {
            let max = 0;
            let baselineMax = 0;

            for (let i = 0; i < backtestResults.dailyResults.length; i++) {
                let d = new Date(backtestResults.dailyResults[i].date);
                tempChartData.push([d, backtestResults.dailyResults[i].total, backtestResults.dailyResults[i].baseline]);

                if (backtestResults.dailyResults[i].total > max)
                    max = backtestResults.dailyResults[i].total;
                if (backtestResults.dailyResults[i].baseline > baselineMax)
                    baselineMax = backtestResults.dailyResults[i].baseline;

                if (isDateEqualOrAfter(targetDate, d)) {
                    updateState(max, baselineMax, d, tempChartData);
                    return;
                }
            }
        }
    }

    function handleFindNextBigDay() {
        const tempChartData = [];
        const nextDay = new Date(currentDate);
        nextDay.setDate(nextDay.getDate() + 1);
        if (backtestResults && backtestResults.dailyResults && backtestResults.dailyResults.length) {
            let max = 0;
            let baselineMax = 0;

            for (let i = 0; i < backtestResults.dailyResults.length; i++) {
                let d = new Date(backtestResults.dailyResults[i].date);
                tempChartData.push([d, backtestResults.dailyResults[i].total, backtestResults.dailyResults[i].baseline]);

                if (backtestResults.dailyResults[i].total > max)
                    max = backtestResults.dailyResults[i].total;
                if (backtestResults.dailyResults[i].baseline > baselineMax)
                    baselineMax = backtestResults.dailyResults[i].baseline;

                let yesterdaysResults = i > 0 ? backtestResults.dailyResults[i - 1] : null;
                let todaysResults = backtestResults.dailyResults[i];
                let change = yesterdaysResults ? ((todaysResults.total - yesterdaysResults.total) / yesterdaysResults.total * 100) : 0
                if (isDateEqualOrAfter(nextDay, d) && Math.abs(change) > 2) {
                    updateState(max, baselineMax, d, tempChartData);
                    return;
                }
            }
        }
    }

    function findNextBigDrawdown() {
        const tempChartData = [];
        const nextDay = new Date(currentDate);
        nextDay.setDate(nextDay.getDate() + 1);
        if (backtestResults && backtestResults.dailyResults && backtestResults.dailyResults.length) {
            let max = 0;
            let baselineMax = 0;

            for (let i = 0; i < backtestResults.dailyResults.length; i++) {
                let d = new Date(backtestResults.dailyResults[i].date);
                tempChartData.push([d, backtestResults.dailyResults[i].total, backtestResults.dailyResults[i].baseline]);

                if (backtestResults.dailyResults[i].total > max)
                    max = backtestResults.dailyResults[i].total;
                if (backtestResults.dailyResults[i].baseline > baselineMax)
                    baselineMax = backtestResults.dailyResults[i].baseline;

                let todaysResults = backtestResults.dailyResults[i];
                let drawdown = ((max - todaysResults.total) / max * 100)

                if (isDateEqualOrAfter(nextDay, d) && drawdown > 5) {
                    updateState(max, baselineMax, d, tempChartData);
                    return;
                }
            }
        }
    }

    function getTodaysData() {
        if (backtestResults && backtestResults.dailyResults && backtestResults.dailyResults.length) {
            for (let i = 0; i < backtestResults.dailyResults.length; i++) {
                let d = new Date(backtestResults.dailyResults[i].date);

                if (d.getFullYear() === currentDate.getFullYear() && d.getMonth() === currentDate.getMonth() && d.getDate() === currentDate.getDate()) {
                    let todaysResults = backtestResults.dailyResults[i];
                    let yesterdaysResults = i > 0 ? backtestResults.dailyResults[i - 1] : null;
                    if (yesterdaysResults) {
                        return {
                            percentChange: ((todaysResults.total - yesterdaysResults.total) / yesterdaysResults.total * 100),
                            baselinePercentChange: ((todaysResults.baseline - yesterdaysResults.baseline) / yesterdaysResults.baseline * 100),
                            drawdown: ((maxValue - todaysResults.total) / maxValue * 100),
                            baselineDrawdown: ((baselineMaxValue - todaysResults.baseline) / baselineMaxValue * 100),
                            longPositions: ((yesterdaysResults.long_positions / yesterdaysResults.total) * 100),
                            shortPositions: ((yesterdaysResults.short_positions / yesterdaysResults.total) * 100),
                            eodLongPositions: ((todaysResults.long_positions / todaysResults.total) * 100),
                            eodShortPositions: ((todaysResults.short_positions / todaysResults.total) * 100),
                            closedTrades: todaysResults.closed_trade_count,
                            winningTrades: todaysResults.winning_trade_count,
                        }

                    } else {
                        return null;
                    }
                } else if (d.getFullYear() > currentDate.getFullYear() || (d.getFullYear() === currentDate.getFullYear() && d.getMonth() > currentDate.getMonth())) {
                    return null;
                }
            }
        }
        return null;
    }
    /*
        function getStartOfYear() {
            if (currentDate) {
                let d = new Date(currentDate);
                d.setMonth(0);
                d.setDate(1);
                return d;
            } else
                return null;
        }
                */

    function getDaysAgo(days) {
        if (currentDate) {
            let d = new Date(currentDate);
            d.setDate(d.getDate() - days)
            return d;
        } else
            return null;
    }

    function getEndOfYear() {
        if (currentDate) {
            let d = new Date(currentDate);
            d.setMonth(11);
            d.setDate(31);
            return d;
        } else
            return null;
    }

    const todaysData = getTodaysData();

    return (
        <>
            <center>
                <table width="80%">
                    <tr>
                        <td width="100%">
                            <DailyBalanceChart height={"400px"} startDate={getDaysAgo(252)} endDate={getEndOfYear()} hideOptions={true} defaultToYtd={false} defaultToSyncValues={true} backtestResults={backtestResults} chartData={chartData} hideAllocationChart={true} hideChartSummary={true}></DailyBalanceChart>
                            <br></br>
                            <center>
                                <table width="100%" style={{ backgroundColor: '#F0F0F0', padding: '10px', margin: '5px', borderWidth: '3px', borderStyle: 'solid', borderColor: 'black' }}>
                                    <tr style={{ borderBottom: '2px', borderStyle: 'solid' }}>
                                        <td width="25%" align='left' valign='top'>
                                            <h5>Daily Change</h5>
                                            Plan: {todaysData ? todaysData.percentChange.toFixed(2) + "%" : null}
                                            <br></br>
                                            S&P 500: {todaysData ? todaysData.baselinePercentChange.toFixed(2) + "%" : null}
                                            <br></br>
                                            <b>Difference: {todaysData ? (todaysData.percentChange - todaysData.baselinePercentChange).toFixed(2) + "%" : null}</b>
                                        </td>
                                        <td width="50%">
                                            <center>
                                                <Link id="back" className="plus-minus-button" onClick={e => { handleNextClicked(currentDate, false, -30) }}>⏮️</Link>
                                                &nbsp; &nbsp;<Link id="back" className="plus-minus-button" onClick={e => { handleNextClicked(currentDate, false, -1) }}>⏪</Link>
                                                &nbsp;&nbsp;<DatePicker showMonthYearPicker selected={currentDate} onChange={(date) => updateDate(date)} />
                                                &nbsp; &nbsp;<Link className="plus-minus-button" id="back" onClick={e => { handleNextClicked(currentDate, true, 1) }}>⏩</Link>
                                                &nbsp; &nbsp;<Link id="back" className="plus-minus-button" onClick={e => { handleNextClicked(currentDate, true, 30) }}>⏭️</Link>
                                                <br></br>
                                                <center>
                                                    <Link id="back" className="menu-item" onClick={e => { handleFindNextBigDay(currentDate) }}>Find Next Big Day</Link>
                                                    <br></br>
                                                    <Link id="back" className="menu-item" onClick={e => { findNextBigDrawdown(currentDate) }}>Find Next Pullback</Link>
                                                </center>
                                            </center>
                                        </td>
                                        <td width="25%" align='right' valign='top'>
                                            <h5>Drawdown</h5>
                                            Plan: {todaysData ? todaysData.drawdown.toFixed(2) + "%" : null}
                                            <br></br>
                                            S&P 500: {todaysData ? todaysData.baselineDrawdown.toFixed(2) + "%" : null}
                                            <br></br>
                                            <b>Difference: {todaysData ? (todaysData.baselineDrawdown - todaysData.drawdown).toFixed(2) + "%" : null}</b>
                                        </td>
                                    </tr>
                                    <tr style={{ backgroundColor: '#F0F0F0' }}>
                                        <td align='left' valign='top'>
                                            <b>Beginning of Day:</b>
                                            <br></br>
                                            Long Positions: {todaysData ? todaysData.longPositions.toFixed(1) + "%" : null}
                                            <br></br>
                                            Short Positions: {todaysData ? todaysData.shortPositions.toFixed(1) + "%" : null}
                                        </td>
                                        <td align='center' valign='top'>
                                            <input type="checkbox" value={showTrades} onClick={() => setShowTrades(!showTrades)} /><Tooltip width={'500px'} onOpen={() => loadTradePreview()} textClass="tooltip-link" text="View Trades" position={props.popupLocation ? props.popupLocation : "right bottom"} json={getTradePreviewJson()} />
                                            <br></br><br></br>
                                            <Tooltip width={'500px'} onOpen={() => loadPositionsPreview()} textClass="tooltip-link" text="View Active Trades" position={props.popupLocation ? props.popupLocation : "left bottom"} json={getPositionsPreviewJson()} />
                                        </td>
                                        <td align='right' valign='top'>
                                            <b>End of Day:</b>
                                            <br></br>
                                            Long Positions: {todaysData ? todaysData.eodLongPositions.toFixed(1) + "%" : null}
                                            <br></br>
                                            Short Positions: {todaysData ? todaysData.eodShortPositions.toFixed(1) + "%" : null}
                                        </td>
                                    </tr>
                                </table>
                            </center>
                            <br></br>

                            <DailyBalanceChart height={"200px"} startDate={getDaysAgo(252)} endDate={getEndOfYear()} hideOptions={true} defaultToDrawdown={true} defaultToYtd={false} defaultToSyncValues={true} backtestResults={backtestResults} chartData={chartData} hideAllocationChart={true} hideChartSummary={true}></DailyBalanceChart>

                            {showTrades && trades ?
                                <div style={{ borderWidth: 1, borderStyle: 'solid' }}><ul>
                                    {trades.map(t => {
                                        return <li>{t.action + " " + t.percentage + "% of " + t.trade_symbol + " by " + t.display_name + " (" + t.profit.toFixed(2) + "%)"}</li>
                                    })
                                    }
                                </ul>
                                </div>
                                : null}
                        </td>
                    </tr>
                </table>
            </center>
        </>
    );
}

export default Replay;