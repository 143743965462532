import React, { useState, useEffect } from 'react';
import AlertSettings from './AlertSettings.js';
import ActionResult from '../components/ActionResult.js'

function CreateAlert(props) {
    const [symbol, setSymbol] = useState('Select symbol')
    const [algoName, setAlgoName] = useState('')
    const [timeframe, setTimeframe] = useState(0)
    const [result, setResult] = useState(null)
    const [updates, setUpdates] = useState(0);
    const [selectedStrategyId, setSelectedStrategyId] = useState(0);

    useEffect(() => {
        (async function () {

        })();
    }, [updates, props.planId]);


    function shouldAllowEdits() {
        if (props.role === 3) return true;
        else return false;
    }

    async function handleSubmit() {
        setResult('Creating Alert');
        const url = "/api/strategyAlertCreate?strategyId=" + selectedStrategyId + "&symbol=" + symbol + "&timeframe=" + timeframe + "&algo=" + algoName;
        console.log(url);
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
        setUpdates(updates + 1);
    }

    function handleCreateAlertUpdate(strategyId, symbol, timeframe, name) {
        console.log(strategyId, symbol, timeframe, name);
        setSelectedStrategyId(strategyId);
        setSymbol(symbol);
        setTimeframe(timeframe);
        setAlgoName(name);
    }

    return (
        <>
            <>
                <h3>Create Alert</h3>
                <AlertSettings onAlertUpdated={handleCreateAlertUpdate} /><br></br>
                {shouldAllowEdits() ? <button class="btn btn-secondary" onClick={(e) => handleSubmit()}>Create Alert</button> : null}
            </>

            <ActionResult result={result} />
        </>
    );
}

export default CreateAlert;