import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";

function AlertTradeResultsChart(props) {

    const [performance, setPerformance] = useState({})

    useEffect(() => {
        (async function () {
            if (props.performance)
                setPerformance(props.performance);
        })();
    }, [props.alertId, props.performance]);

    function getChartData() {
        if (performance && performance.trades && performance.trades.length) {
            let ret = [];
            if (props.showDrawdown)
                ret.push(["Trade", "Trade Max Drawdown"])
            else
                ret.push(["Trade", "Trade Profit"])
            let counter = 0;
            let max = null;
            let min = 0;
            performance.trades.forEach(t => {
                if (t.sellDate) {
                    ++counter;
                    let value = t.profitPercentage / 100;
                    if (props.showDrawdown)
                        value = t.maxDrawdown / 100;
                    ret.push([counter, value])

                    if (max === null || value > max)
                        max = value;
                    if (value < min)
                        min = value;
                }
            })
            return [ret, min, max];
        } else {
            return null;
        }
    }

    let profitChartData = getChartData();
    let profitChartOptions = profitChartData ? {
        legend: { position: 'none' },
        vAxis: {
            format: '#%',
            viewWindow: {
                max: profitChartData[2] * 1.1,
                min: profitChartData[1] * 1.1
            },
        },
    } : null;

    return (
        <div>
            {
                props.performance && props.performance.trades && props.performance.trades.length && profitChartData ?
                    <Chart
                        chartType="ColumnChart"
                        width={"100%"}
                        data={profitChartData[0]}
                        options={profitChartOptions}
                        chartPackages={["corechart", "controls"]}
                    />
                    :
                    null
            }
        </div >);
}

export default AlertTradeResultsChart;