import './richTable.css'
import Table from 'react-bootstrap/Table'
import { defaultFmt } from './formatters'
import { getFilter } from './filters'
import { SortOrder, createDefaultSorter, iterativeSort } from './sorters'
import Filter from './Filter'

import { useState } from 'react'
import { ChevronRightIcon, ChevronLeftIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'

/**
 * TODO: Known issues that need to get fixed
 * - Styling, column width resizes when sorting is applied
 * - No search bar yet
 * - When too many columns, sort buttons wrap. Need to keep together. See AlgoStats for a good example
 * - Export to CSV?
 * - implement choice filter
 */

/**
 * RichTable builds a sortable, filterable, and pageable table from a given data set. A data set must be an
 * array of objects, where each object represents a row in the table. Configuration is driven from the mappers
 * object. Each mapper object represents a column in the table. The mapper object has the following properties:
 *  - field: the key in the data object that will be used to populate the column
 *  - title: the title of the column
 *  - formatter: a function that takes the value of the field and the object itself and returns a JSX object
 *  - sorters: one of - true (sorts using the default sorter on the current column name
 *                    - a sort function which compares two values
 *                    - an array of functions that will be used to sort the column in order
 *                      (for primary, secondary, etc. sorting)
 *  - hidden: a boolean that hides the column if true
 *  - isDefaultSort: a boolean that sets the column as the default sort column
 *  - filter: the type of filter to use on the column (only 'text' is implemented right now)
 *  - width: the width of the column
 *  - bgcolor: a function that takes the value of the field and the object itself and returns a color as a string
 * 
 * @param {object} params: {
 *                            data: DataObject[], 
 *                            pageSize?: number,
 *                            mappers: Mapper<DataObject, any>[],
 *                            noDataMessage?: string,
 *                            className: string,
 *                            headless: boolean | undefined,
 *                            onRowClickHandler: function(row)
 *                            rowClassName: function(row)
 *                          }
 * 
 * @returns JSX Table
 */
export default function RichTable({
    data,                           // Data to be displayed in the table
    pageSize = undefined,           // Number of rows per page. If not provided, all data will be displayed
    mappers,                        // Configuration for the table columns. See above for details
    className = '',                 // Additional classes to be added to the table
    noDataMessage = undefined,      // Message to be displayed when there is no data
    headless = undefined,           // If true, the header will not be displayed
    onRowClickHandler = undefined,  // Function to be called when a row is clicked
    rowClassName = undefined        // Function to be called on each row to get additional class names to apply
}) {

    // Currently the table does not support paging and filtering on the same table. Check for that and throw exception if attempted
    if (pageSize && mappers.some(mapper => mapper.filter)) {
        throw new Error('RichTable does not support paging and filtering at the same time. Remove pageSize from RichTable invocation or filter from the mappers and try again.')
    }

    function wrapSort(sorter, order) {
        return (a, b) => {
            return order === SortOrder.NATURAL ? sorter(a, b, SortOrder.NATURAL) : sorter(b, a, SortOrder.REVERSE)
        }
    }

    function updateFilterState(field, value) {
        const newFilterState = { ...filterState }

        if (!value) {
            delete newFilterState[field]
        } else {
            newFilterState[field] = value
        }

        setFilterState(newFilterState)
    }

    const [page, setPage] = useState(1)

    let initialSortState = undefined
    const initialSortMapper = mappers.find(mapper => mapper.isDefaultSort)
    if (initialSortMapper) {
        initialSortState = { field: initialSortMapper.field, order: SortOrder.NATURAL }
    }

    const [sortState, setSortState] = useState(initialSortState)
    const [filterState, setFilterState] = useState(undefined) // Will be: { 'fieldName1': 'filterValue1', 'fieldName2': 'filterValue2' }

    const massagedData = sortData(filterData(data))
    const pagedData = pageData(massagedData)
    const totalPages = pageSize ? Math.ceil(massagedData.length / pageSize) : 1

    function filterData(data) {
        let copy = [...data]
        if (filterState) {
            for (const field in filterState) {
                const mapper = mappers.find(mapper => mapper.field === field)
                if (mapper) {
                    console.log(`filtering on field ${field}`)
                    const filter = getFilter(mapper.filter)
                    if (filter) {
                        copy = copy.filter(row => filter(filterState[field], row[field]))
                    }
                }
            }
        }
        return copy
    }

    function sortData(data) {
        const copy = [...data]
        if (sortState) {
            const mapper = mappers.find(mapper => mapper.field === sortState.field)
            const sorters = mapper?.sorters

            if (sorters) {
                if (typeof sorters === 'boolean') {
                    copy.sort(wrapSort(createDefaultSorter(sortState.field), sortState.order))
                } else if (Array.isArray(sorters)) {
                    copy.sort(wrapSort(iterativeSort(sorters, sortState.order), sortState.order))
                } else {
                    copy.sort(wrapSort(sorters, sortState.order))
                }
            }
        }
        return copy
    }

    function pageData(data) {
        if (pageSize) {
            return data.slice((page - 1) * pageSize, page * pageSize)
        }
        return data
    }

    const handlePageChange = (page) => {
        setPage(page)
    }

    const handleSortBy = (field) => {
        let sortOrder = SortOrder.NATURAL

        if (sortState && field === sortState.field) {
            // Toggle the sort order
            sortOrder = sortState.order === SortOrder.NATURAL ? SortOrder.REVERSE : SortOrder.NATURAL
        }
        setSortState({ field: field, order: sortOrder })
    }

    return (
        <Table className={`rich-table ${className}`}>
            <thead className={`rich-table-header ${headless ? 'hidden' : ''}`}>
                <tr className={`rich-table-header-row`}>
                    {mappers.map((mapper, idx, source) => (
                        <th
                            key={idx}
                            className={`rich-table-header-cell ${mapper.hidden ? 'hidden' : ''}`}
                            style={mapper.width ? { width: mapper.width } : {}}
                            onClick={() => { if (mapper.sorters) handleSortBy(mapper.field) }}
                        >
                            <span className={`rich-table-header-cell-title ${mapper.sorters ? 'sort-column' : ''}`}>{mapper.title}</span>
                            {
                                mapper.sorters
                                    ? <span>
                                        {sortState && mapper.field === sortState.field
                                            ? sortState.order === SortOrder.NATURAL
                                                ? <><ChevronDownIcon className={`sort-icon`} /><span className='sort-icon' /></>
                                                : <><ChevronUpIcon className={`sort-icon`} /><span className='sort-icon' /></>
                                            : <span className='double-chevron'><ChevronDownIcon className={`sort-icon unsorted`} /><ChevronUpIcon className={`sort-icon unsorted`} /></span>
                                        }
                                    </span>
                                    : ''
                            }
                            {
                                mapper.filter
                                    ? <Filter filterType={mapper.filter}
                                        onFilter={updateFilterState}
                                        title={mapper.title}
                                        data={data}
                                        field={mapper.field} />
                                    : ''
                            }
                        </th>
                    ))}

                </tr>
            </thead>
            <tbody className={`rich-table-body`}>
                {
                    pagedData.length
                        ? pagedData.map((row, idxRow) => {
                            return (
                                <tr key={idxRow} className={`rich-table-body-row  ${rowClassName ? rowClassName(row) : ''}`} onClick={() => { if (onRowClickHandler) onRowClickHandler(row) }}>

                                    {mappers.map((mapper, idx) => {
                                        const formatter = mapper.formatter ?? defaultFmt
                                        return (<td
                                            key={`${idxRow}_${idx}`}
                                            className={`rich-table-body-cell  ${mapper.hidden ? 'hidden' : ''}`}
                                            style={{
                                                width: mapper.width ? mapper.width : undefined,
                                                backgroundColor: mapper.bgcolor ? mapper.bgcolor(row[mapper.field], row) : undefined
                                            }}
                                        >{formatter(row[mapper.field], row)}</td>)
                                    })}
                                </tr>
                            )
                        })
                        : <tr className={`rich-table-body-row`}>
                            <td colSpan={mappers.length} className={`rich-table-no-data-message`}>{noDataMessage || 'There is no data to display'}</td>
                        </tr>
                }
            </tbody>
            {
                pageSize
                    ? <tfoot className={`rich-table-footer`}>
                        <tr className='rich-table-footer-row'>
                            <th colSpan={mappers.length} className='rich-table-footer-cell'>
                                <div className={`rich-table-paging ${totalPages <= 0 ? 'hidden' : ''}`}>
                                    <div>
                                        <button onClick={() => handlePageChange(page - 1)} disabled={page === 1} className={`rich-table-page-nav rich-table-page-prev`}>
                                            <ChevronLeftIcon className={`rich-table-page-nav-ico rich-table-page-prev-ico`} />
                                        </button>
                                        <label>Page {page} of {totalPages}</label>
                                        <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} className={`rich-table-page-nav rich-table-page-next`}>
                                            <ChevronRightIcon className={`rich-table-page-nav-ico rich-table-page-next-ico`} />
                                        </button>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </tfoot>
                    : ''
            }
        </Table>
    )
}