import React from 'react';
import SingleBarChart from './SingleBarChart';

function RangeChart(props) {

    if (isNaN(props.avg) || isNaN(props.range) || isNaN(props.value))
        return <></>;

    let value = Number(props.value);
    let range = Math.abs(props.range);
    let avg = Number(props.avg);
    let diff = value < 0 ? Math.abs(value + avg) : Math.abs(value - avg);

    let upperBound = avg * range / 100;
    let lowerBound = avg * range / 100;

    let color = '#A0DDFD';
    if (props.isReversed) {
        if (value > avg && diff > upperBound) color = "yellow";
        else if (value < avg && diff > lowerBound) color = "#B2D8B2";
    } else {
        if (value < avg && diff > upperBound) color = "yellow";
        else if (value > avg && diff > lowerBound) color = "#B2D8B2";
    }

    return (
        <>
            <SingleBarChart width={250} midPoint={avg} value={value} upperRange={upperBound} lowerRange={lowerBound} color={color} decimalPlaces={2} />
        </>
    )
}

export default RangeChart;