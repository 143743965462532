import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";

function AlertProfitChart(props) {

    const [performance, setPerformance] = useState({})

    useEffect(() => {
        (async function () {
            if (props.performance)
                setPerformance(props.performance);
        })();
    }, [props.alertId, props.performance]);


    function getRealizedProfitChartData() {
        if (performance && performance.trades && performance.trades.length) {
            let ret = [];
            if (props.showDrawdown)
                ret.push(["Date", "Realized Drawdown"])
            else
                ret.push(["Date", "Realized Profit"])
            let net = 0.0;
            let min = null;
            let max = null;
            let maxProfit = null;
            let netProfit = 0.0;
            performance.trades.forEach(t => {
                if (t.sellDate) {
                    netProfit += t.profitPercentage;
                    if (maxProfit === null || netProfit > maxProfit)
                        maxProfit = netProfit;
                    if (props.showDrawdown) {
                        net = maxProfit - netProfit;
                    } else {
                        net = netProfit;
                    }
                    let sellDate = new Date(t.sellDate);
                    let date = (sellDate.getUTCMonth() + 1) + "/" + sellDate.getUTCDate() + "/" + (sellDate.getUTCFullYear() - 2000);
                    ret.push([date, net / 100])
                    if (max === null || net > max) max = net;
                    if (min === null || net < min) min = net;
                }
            })
            return [ret, min / 100, max / 100];
        } else {
            return null;
        }
    }

    let realizedProfitChartData = getRealizedProfitChartData();
    let realizedProfitChartOptions = realizedProfitChartData ? {
        legend: { position: 'none' },
        vAxis: {
            format: '#%',
            viewWindow: {
                max: realizedProfitChartData[2],
                min: props.showDrawdown ? 0 : realizedProfitChartData[1] - 0.05
            }
        }
    } : null;

    return (
        <div>
            {
                props.performance && props.performance.trades && props.performance.trades.length && realizedProfitChartData ?
                    <Chart
                        chartType="AreaChart"
                        width={"100%"}
                        data={realizedProfitChartData[0]}
                        options={realizedProfitChartOptions}
                        chartPackages={["corechart", "controls"]}
                    />
                    :
                    null
            }
        </div >);
}

export default AlertProfitChart;