import React from 'react';
import SingleBarChart from './SingleBarChart';

function ExpectancyChart(props) {

    if (isNaN(props.profit) || isNaN(props.avgWin) || isNaN(props.avgLoss))
        return null;

    let value = Number(props.profit);
    let upperRange = Number(props.avgWin);
    let lowerRange = Number(props.avgLoss) * -1;

    let color = 'black';
    if (props.profit > 0) color = "#B2D8B2";
    else if (props.profit < 0) color = "#B85D5D";

    return (
        <SingleBarChart hideLabels={props.hideLabels} decimalPlaces={2} value={value} lowerRange={lowerRange} upperRange={upperRange} midPoint={0} color={color} />
    )
}

export default ExpectancyChart;