import React from 'react';

function SingleBarChart(props) {

    if (isNaN(props.lowerRange) || isNaN(props.upperRange) || isNaN(props.midPoint) || isNaN(props.value))
        return <>Missing data for SingleBarChart</>

    let value = Number(props.value);
    let upperRange = Number(props.upperRange);
    let lowerRange = Number(props.lowerRange);
    let mid = Number(props.midPoint);
    let diff = value > mid ? (value - mid) : (mid - value)
    let size = value > mid ? diff / upperRange : diff / lowerRange;

    let percent = value === mid ? 1 : Math.min(50, size * 50);
    if (percent < 1) percent = 1;
    let leftPercent = value > mid ? 50 : 50 - percent;
    let rightPercent = 100 - leftPercent - percent;
    let color = props.color;

    let upperBound = Math.min(100, mid + upperRange);
    let lowerBound = mid - lowerRange;

    let decimals = props.decimalPlaces ? Number(props.decimalPlaces) : 0;

    return (
        <table height="15px" cellPadding={0} >
            <td hidden={props.hideLabels} width="45px" align="right" className="smaller-text">{lowerBound.toFixed(decimals)}%&nbsp;</td>
            <td width={props.width ? (props.width - 90) + "px" : "120px"} style={{ borderWidth: 2, borderStyle: "solid" }}>
                <table width="100%" height="100%" cellPadding={0} cellSpacing={0}>
                    <td style={{ backgroundColor: 'white', width: leftPercent.toFixed(0) + "%" }} align="right" className="small-text">{value > mid ? value.toFixed(decimals) + "%" : null}</td>
                    <td style={{ backgroundColor: color, width: percent.toFixed(0) + "%" }}></td>
                    <td style={{ backgroundColor: 'white', width: rightPercent.toFixed(0) + "%" }} align="left" className="small-text">{value <= mid ? value.toFixed(decimals) + "%" : null}</td>
                </table>
            </td>
            <td hidden={props.hideLabels} width="45px" align="left" className="smaller-text">&nbsp;{upperBound.toFixed(decimals)}%</td>
        </table>
    )
}

export default SingleBarChart;