export const defaultFmt = (value, obj) => {
  return value ? value.toString() : '-'
}

export const createPctFmt = (decimals) => {
  return (x, obj) => x !== undefined ? `${(Number(x)).toFixed(decimals)}%` : '-'
}

export const pctFmtZeroDec = createPctFmt(0)
export const pctFmtTwoDec = createPctFmt(2)

const priceFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
export const priceFmt = (value, obj) => value ? `$${priceFormatter.format(Number(value))}` : '-'

export const intFmt = (value, obj) => value ? `${parseInt(value)}` : '-'