import React, { useState, useEffect } from 'react';
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import {
    elderRay,
    ema,
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    CurrentCoordinate,
    BarSeries,
    CandlestickSeries,
    ElderRaySeries,
    LineSeries,
    MovingAverageTooltip,
    OHLCTooltip,
    SingleValueTooltip,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY,
    ZoomButtons,
    SvgPathAnnotation,
    Annotate,
} from "react-financial-charts";


function CandleStickChart(props) {

    const [quoteHistory, setQuoteHistory] = useState([]);
    const [tradeHistory, setTradeHistory] = useState([]);
    const [symbol, setSymbol] = useState(null);
    const [averagePrices, setAveragePrices] = useState({});

    useEffect(() => {
        (async function () {

            let qHistory = quoteHistory;
            if (props.symbol && props.symbol !== symbol) {
                setSymbol(props.symbol);
                const url = `/api/quoteHistory?symbol=` + props.symbol +
                    "&startDate=" + (props.startDate ? props.startDate : "2014-1-1") +
                    "&endDate=" + new Date().toLocaleDateString();
                let history = await (await fetch(url)).json();
                qHistory = history;
                history.reverse();
                setQuoteHistory(history);
            }

            function calculateAveragePrices(quoteHistory, trades) {
                let avgPrice = {};
                quoteHistory.forEach(q => {
                    let totalCost = 0;
                    let totalShares = 0;
                    let date = new Date(q.date);
                    trades.forEach(t => {
                        let purchaseDate = new Date(t.purchase_date);
                        let sellDate = new Date(t.sell_date);
                        if (purchaseDate < date && (sellDate >= date || t.sell_date === null || t.sell_date === undefined)) {
                            let allocation = t.allocation ? t.allocation : 1;
                            totalCost += t.purchase_price * allocation;
                            totalShares += allocation;
                        }
                    })
                    avgPrice[q.date] = totalCost / totalShares;
                })
                setAveragePrices(avgPrice)
            }

            if (props.trades) {
                setTradeHistory(props.trades);
                calculateAveragePrices(qHistory, props.trades);
            }
        })();
    }, [props.symbol, symbol, props.trades, quoteHistory, props.startDate]);

    const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
        (d) => new Date(d.date)
    );
    const height = props.height ? props.height : 700;
    const width = props.width ? props.width : 900;
    const margin = { left: 0, right: 48, top: 0, bottom: 24 };

    const ema12 = ema()
        .id(1)
        .options({ windowSize: 12 })
        .merge((d, c) => {
            d.ema12 = c;
        })
        .accessor((d) => d.ema12);

    const ema26 = ema()
        .id(2)
        .options({ windowSize: 26 })
        .merge((d, c) => {
            d.ema26 = c;
        })
        .accessor((d) => d.ema26);

    const elder = elderRay();

    elder(ema26(ema12(quoteHistory)));
    const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider(
        quoteHistory
    );
    const pricesDisplayFormat = format(".2f");
    const max = xAccessor(data[data.length - 1]);
    let initialVisibleBars = props.initialVisibleBars ? props.initialVisibleBars : 100;
    const min = xAccessor(data[Math.max(0, data.length - initialVisibleBars)]);
    const xExtents = [min, max + 10];

    const gridHeight = height - margin.top - margin.bottom;

    const elderRayHeight = 100;
    const elderRayOrigin = (_, h) => [0, h - elderRayHeight];
    const barChartHeight = gridHeight / 4;
    const barChartOrigin = (_, h) => [0, h - barChartHeight - elderRayHeight];
    const chartHeight = gridHeight - elderRayHeight;
    /*
    co8nst yExtents = (data) => {
        return [data.high, data.low];
    };
    */
    const dateTimeFormat = "%d %b";
    const timeDisplayFormat = timeFormat(dateTimeFormat);

    const barChartExtents = (data) => {
        return data.volume;
    };

    const candleChartExtents = (data) => {
        return [data.high * 1.02, data.low * .98];
    };

    const yEdgeIndicator = (data) => {
        return data.close;
    };

    const volumeColor = (data) => {
        return data.close > data.open
            ? "rgba(38, 166, 154, 0.3)"
            : "rgba(239, 83, 80, 0.3)";
    };

    const volumeSeries = (data) => {
        return data.volume;
    };

    const openCloseColor = (data) => {
        return data.close > data.open ? "#26a69a" : "#ef5350";
    };

    function isDateMatch(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    }

    function shouldShowBuyAnnotation(data) {
        const date = new Date(data.date);

        let tradeHappened = false;
        tradeHistory.forEach(trade => {
            const buyDate = new Date(trade.purchase_date);
            if (isDateMatch(date, buyDate)) tradeHappened = true;
        })
        return tradeHappened;
    }

    function shouldShowSellAnnotation(data) {
        const date = new Date(data.date);

        let tradeHappened = false;
        tradeHistory.forEach(trade => {
            const sellDate = trade.sell_date ? new Date(trade.sell_date) : null;
            if (sellDate && isDateMatch(date, sellDate)) tradeHappened = true;
        })
        return tradeHappened;
    }

    const svgBuyAnnotationProps = {
        fill: "#2196f3",
        path: () =>
            "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
        pathWidth: 12,
        pathHeight: 22,
        tooltip: "Buy",
        y: ({ yScale, datum }) => yScale(datum.close),
    }

    const svgSellAnnotationProps = {
        fill: "#000000",
        path: () =>
            "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
        pathWidth: 12,
        pathHeight: 22,
        tooltip: "Sell",
        y: ({ yScale, datum }) => yScale(datum.close),
    }

    function getAveragePrice(item) {
        if (item)
            return averagePrices[item.date];
        else
            return null;
    }

    return (
        <>
            {
                quoteHistory && quoteHistory.length ?
                    <>
                        <ChartCanvas
                            height={height}
                            ratio={3}
                            width={width}
                            margin={margin}
                            data={data}
                            displayXAccessor={displayXAccessor}
                            seriesName="Data"
                            xScale={xScale}
                            xAccessor={xAccessor}
                            xExtents={xExtents}
                            zoomAnchor={lastVisibleItemBasedZoomAnchor}
                        >
                            {props.showVolume ?
                                <Chart
                                    id={2}
                                    height={barChartHeight}
                                    origin={barChartOrigin}
                                    yExtents={barChartExtents}
                                >
                                    <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />
                                </Chart>
                                :
                                null
                            }
                            <Chart id={3} height={chartHeight} yExtents={candleChartExtents}>
                                <XAxis showGridLines ticks={8} showTickLabel={true} />
                                <YAxis showGridLines ticks={8} tickFormat={pricesDisplayFormat} />
                                <CandlestickSeries />
                                <Annotate with={SvgPathAnnotation} usingProps={svgBuyAnnotationProps} when={shouldShowBuyAnnotation} />
                                <Annotate with={SvgPathAnnotation} usingProps={svgSellAnnotationProps} when={shouldShowSellAnnotation} />
                                {props.showMovingAverage ?
                                    <>
                                        <LineSeries yAccessor={ema26.accessor()} strokeStyle={ema26.stroke()} />
                                        <CurrentCoordinate
                                            yAccessor={ema26.accessor()}
                                            fillStyle={ema26.stroke()}
                                        />
                                        <LineSeries yAccessor={ema12.accessor()} strokeStyle={ema12.stroke()} />
                                        <CurrentCoordinate
                                            yAccessor={ema12.accessor()}
                                            fillStyle={ema12.stroke()}
                                        />
                                    </>
                                    :
                                    null
                                }
                                {props.showAveragePrice ?
                                    <>
                                        <LineSeries yAccessor={getAveragePrice} strokeStyle={ema12.stroke()} strokeWidth={2} />
                                        <CurrentCoordinate
                                            yAccessor={getAveragePrice}
                                            fillStyle={ema12.stroke()}
                                        />
                                    </>
                                    :
                                    null
                                }
                                <MouseCoordinateY
                                    rectWidth={margin.right}
                                    displayFormat={pricesDisplayFormat}
                                />
                                <EdgeIndicator
                                    itemType="last"
                                    rectWidth={margin.right}
                                    fill={openCloseColor}
                                    lineStroke={openCloseColor}
                                    displayFormat={pricesDisplayFormat}
                                    yAccessor={yEdgeIndicator}
                                />
                                {props.showMovingAverage ?
                                    <MovingAverageTooltip
                                        origin={[8, 24]}
                                        options={[
                                            {
                                                yAccessor: ema26.accessor(),
                                                type: "EMA",
                                                stroke: ema26.stroke(),
                                                windowSize: ema26.options().windowSize
                                            },
                                            {
                                                yAccessor: ema12.accessor(),
                                                type: "EMA",
                                                stroke: ema12.stroke(),
                                                windowSize: ema12.options().windowSize
                                            }
                                        ]}
                                    />
                                    :
                                    null
                                }

                                <ZoomButtons />
                                <OHLCTooltip origin={[8, 16]} fontWeight={"600"} />
                            </Chart>
                            {props.showLowerIndicator ?
                                <Chart
                                    id={4}
                                    height={elderRayHeight}
                                    yExtents={[0, elder.accessor()]}
                                    origin={elderRayOrigin}
                                    padding={{ top: 8, bottom: 8 }}
                                >
                                    <XAxis showGridLines gridLinesStrokeStyle="#e0e3eb" />
                                    <YAxis ticks={4} tickFormat={pricesDisplayFormat} />

                                    <MouseCoordinateX displayFormat={timeDisplayFormat} />
                                    <MouseCoordinateY
                                        rectWidth={margin.right}
                                        displayFormat={pricesDisplayFormat}
                                    />

                                    <ElderRaySeries yAccessor={elder.accessor()} />

                                    <SingleValueTooltip
                                        yAccessor={elder.accessor()}
                                        yLabel="Elder Ray"
                                        yDisplayFormat={(d) =>
                                            `${pricesDisplayFormat(d.bullPower)}, ${pricesDisplayFormat(
                                                d.bearPower
                                            )}`
                                        }
                                        origin={[8, 16]}
                                    />
                                </Chart>
                                :
                                null
                            }
                            <CrossHairCursor />
                        </ChartCanvas>
                    </>
                    :
                    null
            }

        </>
    );
}

export default CandleStickChart;