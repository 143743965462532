import React, { useState } from 'react';
import HelpFAQ from './HelpFAQ.js';
import { Link } from 'react-router-dom'
import forceLogout from '../helpers/forceLogout.js'


function Help(props) {
    const [question, setQuestion] = useState('')
    const [result, setResult] = useState(null)

    async function handleSubmit() {
        console.log(question);

        const url = "/api/supportQuestion?userId=" + props.userId + "&question=" + question;
        console.log(url);
        const response = await fetch(url, { method: "POST" })
        if (!response.ok) {
            console.error(`Failed to submit question - HTTP ${response.status}`)
            forceLogout()
            return
        }

        const value = response.json()
        setResult(value);
    }

    return (
        <>
            <br></br>
            <br></br>
            <div style={{ width: '500px', borderWidth: '2px' }}>
                Can't find what you're looking for? Submit a question and we'll email you back:<br></br>
                <textarea rows={5} cols={50} onChange={(e) => setQuestion(e.target.value)} />
                <br></br>
                <button class="btn btn-secondary" onClick={(e) => handleSubmit()}>Submit</button>
                <br></br>
                <br></br>
                You can also get help from the community on our <Link target="_blank" className="bg-transparent" to={"https://discord.com/channels/1227965055488757761/1227971717712838747"} >Discord channel.</Link>
                <br></br>
                If you haven't joined the Discord yet, you can <Link target="_blank" className="bg-transparent" to={"https://discord.gg/fWtCEQjVWe"} >join here.</Link>
                {result && result.error ? "Could not submit question" : null}
                {result && result.result ? "We got your question and will get back to you soon!" : null}
            </div>
            <br></br>
            <br></br>
            <HelpFAQ />
        </>
    );
}

export default Help;