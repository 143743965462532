class OptionFinder {

    validateSymbol(sym) {
        let issue = null;

        const parts = sym.split(' ');
        if (parts.length === 4) {
            let underlying = parts[0];
            let date = parts[1];
            let callPut = parts[3];
            let strike = parts[2];

            let dateParts = date.split('/');
            if (underlying.length >= 1 && underlying.length <= 5) {
                if (dateParts.length === 3) {
                    if (callPut === "C" || callPut === "P") {
                        if (isNaN(parseFloat(strike))) {
                            issue = "Invalid strike price";
                        }
                    } else {
                        issue = "Missing call/put symbol"
                    }
                } else {
                    issue = "Date is invalid format";
                }
            } else {
                issue = "Underlying missing";
            }
        } else {
            issue = "Format: ABC MM/DD/YYYY C 500.00"
        }

        return issue;
    }

    findNearestOptionDate(date, duration, durationStdDev, useFridayOnly = false) {
        console.log("Finding option date for ", date, duration, durationStdDev);
        let totalDuration = Math.max(7, duration + durationStdDev * 2);
        let result = new Date((date.setDate(date.getDate() + totalDuration)));
        if (useFridayOnly) {
            while (result.getDay() !== 5) {
                result.setDate(result.getDate() + 1);
            }
        }
        return result;
    }

    findOption(startDate, startPrice, symbol, avgWin, avgDuration, durationStdDev, isShort) {
        let tradeDate = new Date(startDate);
        let date = this.findNearestOptionDate(tradeDate, avgDuration, durationStdDev, true);
        if (isShort) {
            return symbol
                + " "
                + (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()
                + " "
                + (startPrice * ((100 - avgWin) / 100)).toFixed(0)
                + " P"
        } else {
            return symbol
                + " "
                + (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()
                + " "
                + (startPrice * ((100 + avgWin) / 100)).toFixed(0)
                + " C"
        }
    }

    async loadOptionCandidates(userId, brokerage, symbol, avgDuration, durationStdDev, maxPrice, isShort, avgChange) {

        let result = await (await fetch("/api/optionFinder?userId=" + userId +
            "&brokerage=" + brokerage +
            "&symbol=" + symbol +
            "&minDuration=" + Math.round(avgDuration + durationStdDev) +
            "&maxDuration=" + Math.round(avgDuration + (2 * durationStdDev)) +
            "&avgChange=" + avgChange +
            "&maxPrice=" + maxPrice +
            "&isPut=" + (isShort ? "true" : "false")
        )).json();
        if (result && result.success) {
            return result.options;
        } else {
            console.error(result.error);
            console.error(result);
            return null;
        }
    }

    async loadAlertDetails(algoName, symbol) {

        let alert = await (await fetch(`/api/strategyAlertLookup?name=` + algoName + `&symbol=` + symbol)).json();
        if (alert && alert.idtrading_strategy_alerts) {
            const url = "/api/strategyAlertEvaluator?alertId=" + alert.idtrading_strategy_alerts;
            const r = await (await fetch(url, {
                method: 'POST',
            })).json();
            return r;
        }
        return null;
    }
}

module.exports.OptionFinder = OptionFinder;