export default function PrivacyPolicy() {
  return (
    <div>
      <h3>
        Privacy Policy
      </h3>
      <br></br>
      <br></br>

      <b>
        Last Updated: July 9, 2024
      </b>
      <br></br>
      <br></br>

      <b>Introduction</b>
      <br></br>
      <br></br>

      Impossible Things LLC ("We") respects your privacy and is committed to protecting it through our adherence to this policy.
      <br></br>
      <br></br>

      This policy outlines the types of information we may collect from you or that you provide when you visit Stockpiler (our "site") and our practices regarding the collection, use, maintenance, protection, and disclosure of that information.
      <br></br>
      <br></br>

      This policy applies to information we collect:
      <br></br>
      <ul>
        <li>On this website.</li>
        <li>In email, text, and other electronic communications between you and Stockpiler.</li>
      </ul>

      It does not apply to information collected by:
      <ul>
        <li>Any third party, including through any application or content (including advertising) that may link to or be accessible from our site.</li>
      </ul>

      Please read this policy carefully to understand our practices regarding your information and how we will treat it. By accessing or using Stockpiler, you agree to this privacy policy. We may update this policy from time to time (see Changes to Our Privacy Policy). Your continued use of the site after any such modifications constitutes your acceptance of the updated policy.

      <br></br>
      <br></br>
      <b>
        Children Under the Age of 18
      </b>
      <br></br>
      <br></br>

      Stockpiler is not intended for children under 18 years of age, and we do not knowingly collect personal information from children under 18. If you are under 18, do not use or provide any information on this site or through any of its features. If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at team@marketcue.ai.
      <br></br>
      <br></br>

      California residents under 16 years of age may have additional rights regarding their personal information.
      <br></br>
      <br></br>

      <b>Information We Collect and How We Collect It</b>
      <br></br>
      <br></br>

      We collect several types of information from and about users of Stockpiler, including:
      <ul>
        <li>Personal information that can identify you, such as email address or any other identifier by which you may be contacted online or offline.</li>
        <li>Information about your internet connection, the equipment you use to access our site, and usage details.</li>
      </ul>
      We collect this information:
      <ul>
        <li>Directly from you when you provide it to us.</li>
        <li>Automatically as you navigate through the site. This may include usage details, IP addresses, and information collected through cookies.</li>
      </ul>
      <b>
        Information You Provide to Us
      </b>
      <br></br>
      The information we collect on or through Stockpiler may include:
      <ul>
        <li>Information you provide when you purchase a subscription service or other products, register for a newsletter, or post material.</li>
        <li>Records and copies of your correspondence (including email addresses) if you contact us.</li>
      </ul>
      <b>Information We Collect Through Automatic Data Collection Technologies</b>
      <br></br>
      As you navigate through and interact with Stockpiler, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:
      <ul>
        <li>Details of your visits to our site, including traffic data, location data, logs, and other communication data.</li>
        <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
      </ul>
      <b>
        The information collected automatically is statistical data and does not include personal information. It helps us to improve our site and to deliver a better and more personalized service.
      </b>
      <br></br>

      How We Use Your Information
      <br></br>

      We use the information we collect, including personal information, to:
      <ul>
        <li>Present our site and its contents to you.</li>
        <li>Provide you with information, products, or services that you request from us.</li>
        <li>Fulfill any other purpose for which you provide it.</li>
        <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us.</li>
      </ul>
      <b>Disclosure of Your Information</b>
      <br></br>
      We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.
      <br></br>
      <br></br>
      We may disclose personal information that you provide as described in this privacy policy:
      <ul>
        <li>To our subsidiaries and affiliates.</li>
        <li>To fulfill the purpose for which you provide it.</li>
        <li>For any other purpose disclosed by us when you provide the information.</li>
        <li>With your consent.</li>
      </ul>
      <b>We may also disclose your personal information:</b>
      <ul>
        <li>To comply with any court order, law, or legal process.</li>
        <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Stockpiler, our customers, or others.</li>
      </ul>
      <b>Choices About How We Use and Disclose Your Information</b>
      We offer you choices regarding the personal information you provide to us:
      <ul>
        <li>You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent.</li>
        <li>We do not control third parties' collection or use of your information for interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way.</li>
      </ul>
      <br></br>
      <b>Changes to Our Privacy Policy</b>
      <br></br>
      It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for periodically visiting Stockpiler and this privacy policy to check for any changes.
      <br></br>
      <br></br>
      <b>Contact Information</b>
      <br></br>

      To ask questions or comment about this privacy policy and our privacy practices, contact us at team@marketcue.ai.
      <br></br>


    </div>
  )
}