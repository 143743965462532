export default function TermsAndConditions() {
  return (
    <div>
      <h3>Terms &amp; Conditions</h3>
      <b>
        Disclaimer:
      </b>

      <br></br>
      <br></br>
      The content on this website and in any other communications you receive from us is for informational and educational purposes only and is not and should not be construed as professional financial, investment, tax, or legal advice. Trading in stocks, futures trading, and options trading have large potential rewards but also large potential risks. You must be aware of the risks and be willing to accept them in order to trade stocks and other securities. The past performance of any trading system, methodology, or particular trader is not indicative of future results. Impossible Things LLC is not liable for any money lost and cannot guarantee any gains. Traders should only invest what they can afford to lose and always trade with caution. Stockpiler users should independently investigate and fully understand all risks before investing. Review our full Disclaimer before using this website. If you disagree with the terms of the Disclaimer, please exit this website.
      <br></br>
      <br></br>

      <b>
        Terms and Conditions:
      </b>
      <br></br>
      <br></br>

      <b>Trademark Information</b>
      <br></br>

      The Stockpiler logo, and other product and service names are trademarks of Impossible Things LLC.
      <br></br>
      <br></br>

      <b>Copyright Information</b>
      <br></br>
      <br></br>

      All material contained on the Stockpiler website is the property of Impossible Things LLC and is protected under copyright. No material may be reproduced, duplicated, or redistributed in any form without written permission from Impossible Things LLC, with the exception of downloading or printing a single copy for your personal use.
      <br></br>
      <br></br>

    </div>
  )
}