import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import { Link } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress';

function Indicators(props) {

    const [data, setData] = useState(null)

    useEffect(() => {
        (async function () {

            if (props.symbols && Array.isArray(props.symbols) && props.symbols.length) {
                console.log("Getting data", props.symbols)
                let symbols = "";
                props.symbols.forEach(a => {
                    symbols += a + ",";
                })
                const query = `/api/stockIndicators?symbols=` + symbols;
                let d = await (await fetch(query)).json();
                console.log("data", d);
                setData(d);
            }
        })();
    }, [props.symbols]);

    function getData() {
        return data;
    }

    function last10daysFormatter(cell, row) {
        let ret = "";
        if (cell && Array.isArray(cell)) {
            cell.forEach(d => ret += (d > 0 ? "🔺" : "🔻"))
        }
        return ret;
    }

    function symbolFormatter(cell, row) {
        return <Link id="link" className="bg-transparent" to={`/stock/${cell}`}>{cell}</Link>;
    }

    function sma9Formatter(cell, row) {
        return tableHelpers.boolFormatter(cell, row) +
            (row.upward9Sma ? " ⬆️" : " ⬇️")
    }
    function sma20Formatter(cell, row) {
        return tableHelpers.boolFormatter(cell, row) +
            (row.upward20Sma ? " ⬆️" : " ⬇️")
    }
    function sma50Formatter(cell, row) {
        return tableHelpers.boolFormatter(cell, row) +
            (row.upward50Sma ? " ⬆️" : " ⬇️")
    }
    function sma100Formatter(cell, row) {
        return tableHelpers.boolFormatter(cell, row) +
            (row.upward100Sma ? " ⬆️" : " ⬇️")
    }
    function sma200Formatter(cell, row) {
        return tableHelpers.boolFormatter(cell, row) +
            (row.upward200Sma ? " ⬆️" : " ⬇️")
    }

    return (
        <div>
            {data ?
                <>
                    <RichTable data={getData()} mappers={[
                        { title: `Symbol`, field: 'symbol', sorters: true, formatter: symbolFormatter, hidden: props.hideSymbol ? true : false },
                        { title: `Volatility`, field: 'relativeVolatility', formatter: tableHelpers.percentChangeFormatter, sorters: true },
                        { title: `YDay High`, field: 'abovePrevDayHigh', sorters: true, formatter: tableHelpers.boolFormatter },
                        { title: `9 SMA`, field: 'above9sma', sorters: true, formatter: sma9Formatter, hidden: props.mobileView },
                        { title: `20 SMA`, field: 'above20sma', sorters: true, formatter: sma20Formatter },
                        { title: `50 SMA`, field: 'above50sma', sorters: true, formatter: sma50Formatter, hidden: props.mobileView },
                        { title: `100 SMA`, field: 'above100sma', sorters: true, formatter: sma100Formatter, hidden: props.mobileView },
                        { title: `200 SMA`, field: 'above200sma', sorters: true, formatter: sma200Formatter, hidden: props.mobileView },
                        { title: `RSI3`, field: 'rsi3', sorters: true, formatter: tableHelpers.intFormatter, hidden: props.mobileView },
                        { title: `RSI14`, field: 'rsi14', sorters: true, formatter: tableHelpers.intFormatter },
                        { title: `IBS`, field: 'ibs', sorters: true, formatter: tableHelpers.intFormatter, hidden: props.mobileView },
                        { title: `ATR3`, field: 'atr3', sorters: true, formatter: tableHelpers.percentFormatter },
                        { title: `Last 5`, field: 'last5days', formatter: last10daysFormatter, sorters: true, hidden: props.mobileView },
                    ]} className="table-striped table-hover table-condensed" />
                </>
                :
                <LinearProgress />
            }
        </div >);
}

export default Indicators;