export const SortOrder = {
  NATURAL: 'natural',
  REVERSE: 'reverse'
}

/**
 * Create a sorter for a date field. If either date is null, it will be sorted to the end
 * @param {string} field The name of the date field to extract from the data object
 * @returns Sorting function
 */
export const createDateSorter = (field) => {
  return (a, b) => {
    if (!a[field]) return 1
    if (!b[field]) return -1
    return new Date(b[field]) - new Date(a[field])
  }
}

/**
 * Create a default sorter for a field 
 * @param {string} field The name of the field to extract from the data object
 * @returns Sorting function
 */
export const createDefaultSorter = (field) => {
  return (a, b) => {
    if (a[field] > b[field]) return 1
    if (a[field] < b[field]) return -1
    return 0
  }
}

/**
 * 
 * @param {function[]} Sorting functions to apply in order 
 * @returns The first non-zero result from the sorters or 0 if all sorters return 0
 */
export function iterativeSort(sorters) {
  return (a, b) => {
    for (const sorter of sorters) {
      const result = sorter(a, b)
      if (result !== 0) {
        return result
      }
    }
    return 0
  }
}