import React from 'react';
import CandleStickChart from './CandlestickChart.js';

function StockQuickLook(props) {

    let start = new Date();
    start.setMonth(start.getMonth() - 12);
    let dateString = start.getUTCFullYear() + "-" + (start.getMonth() + 1) + "-1";

    return (
        <CandleStickChart initialVisibleBars={50} startDate={dateString} showAveragePrice={false} symbol={props.symbol} trades={null} width={325} height={325} />
    )
}

export default StockQuickLook;