import React from 'react';
import TableHelpers from '../helpers/TableHelpers';

function OHLCBarChart(props) {

    if (isNaN(props.low) || isNaN(props.high) || isNaN(props.open) || isNaN(props.close))
        return <></>;

    let range = props.high - props.low;
    let lowValue = Math.min(props.open, props.close);
    let highValue = Math.max(props.open, props.close);
    let lowerWickPercent = (lowValue - props.low) / range * 100;
    let upperWickPercent = (props.high - highValue) / range * 100;
    let barPercent = 100 - lowerWickPercent - upperWickPercent;
    let color = props.close > props.open ? '#B2D8B2' : '#B85D5D'

    return (
        <>
            <table height="15px" cellPadding={0} >
                <td width="50px">{TableHelpers.percentChangeFormatter(props.close)}&nbsp;</td>
                <td width={props.width ? props.width + "px" : "120px"} style={{ borderWidth: 2, borderStyle: "solid" }}>
                    <table width="100%" height="100%" cellPadding={0} cellSpacing={0}>
                        <td style={{ width: lowerWickPercent.toFixed(0) + "%" }} align="right" className="small-text"></td>
                        <td style={{ backgroundColor: color, width: barPercent.toFixed(0) + "%" }}></td>
                        <td style={{ width: upperWickPercent.toFixed(0) + "%" }} align="left" className="small-text"></td>
                    </table>
                </td>
            </table>
        </>
    )
}

export default OHLCBarChart;