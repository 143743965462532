import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";

function AlgosChart(props) {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        (async function () {
            const text = await (await fetch(`/api/algos?planId=` + props.planId)).json();

            let dataByAlgo = [["Algo", "Percent"]];
            text.forEach(algo => {
                let existing = dataByAlgo.find(a => a[0] === algo.trade_symbol);
                if (existing)
                    existing[1] += algo.percentage;
                else
                    dataByAlgo.push([algo.trade_symbol, algo.percentage])
            }
            )
            setChartData(dataByAlgo)
        })();
    }, [props.planId, props.itemsChanged]);

    return (
        <div>
            {chartData.length ?
                <Chart
                    chartType="PieChart"
                    data={chartData}
                    width={"100%"}
                    height={"400px"}
                />
                :
                null
            }
        </div >
    );
}

export default AlgosChart;