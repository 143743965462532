import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import Dropdown from 'react-bootstrap/Dropdown';
import AlertConfigChanges from '../alerts/AlertConfigChanges.js'
import Tooltip from '../components/Tooltip.js'
import RangeChart from '../components/RangeChart.js';


function AlgoPerformanceComparison(props) {
    const [data, setData] = useState([]);
    const [years, setYears] = useState(0);
    const [compareYears, setCompareYears] = useState(3);
    const [filtered, setFiltered] = useState(true)
    const [showWinningPercentage, setShowWinningPercentage] = useState(true);
    const [showAvgProfit, setShowAvgProfit] = useState(props.mobileView ? false : true);
    const [showAvgWin, setShowAvgWin] = useState(props.mobileView ? false : true);
    const [showAvgLoss, setShowAvgLoss] = useState(props.mobileView ? false : true);
    const [showMaxWin, setShowMaxWin] = useState(false);
    const [showMaxLoss, setShowMaxLoss] = useState(false);
    const [showTradeCount, setShowTradeCount] = useState(false);
    const [warningPercent, setWarningPercent] = useState(.20);
    const [alertId, setAlertId] = useState(null);
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        (async function () {
            fetch(`/api/algoStats?planId=` + props.planId + "&all=" + (filtered ? "0" : "1"))
                .then(res => res.json())
                .then(text => {
                    console.log("stats", text);
                    setData(text);
                });

            const text = await (await fetch(`/api/strategyAlerts`)).json();
            console.log("alerts", text);
            setAlerts(text);

        })();
    }, [filtered, props.planId]);


    function getStats() {
        return data.filter(s => s.years === years).map(stat => {

            const probabilityOfWinning = (stat.winning_percentage / 100);
            const winLossRatio = stat.avg_loss === 0 ? stat.avg_win : Math.abs(stat.avg_win / stat.avg_loss);
            const kelly = probabilityOfWinning === 0 ? 0 : (probabilityOfWinning - ((1 - probabilityOfWinning) / winLossRatio)) * 100

            let compare = data.find(s => s.years === compareYears && s.algoId === stat.algoId);

            return {
                display_name: stat.display_name,
                winning_percentage: stat.winning_percentage,
                comp_winning_percentage: compare ? compare.winning_percentage : null,
                avg_profit: stat.avg_profit,
                comp_avg_profit: compare ? compare.avg_profit : null,
                avg_win: stat.avg_win,
                comp_avg_win: compare ? compare.avg_win : null,
                avg_loss: stat.avg_loss,
                comp_avg_loss: compare ? compare.avg_loss : null,
                avg_duration: stat.avg_duration,
                max_win: stat.max_win,
                comp_max_win: compare ? compare.max_win : null,
                max_loss: stat.max_loss,
                comp_max_loss: compare ? compare.max_loss : null,
                trade_symbol: stat.trade_symbol,
                count: stat.count,
                comp_count: compare ? compare.count : null,
                algo_type: stat.algo_type,
                algoId: stat.algoId,
                percentage: stat.percentage,
                name: stat.name,
                symbol: stat.symbol,
                kelly: kelly,
            }
        })
    }

    function loadAlertId(row) {
        setAlertId(null);
        let alert = alerts.find(a => a.algo_name === row.name && a.symbol === row.symbol);
        if (alert)
            setAlertId(alert.idtrading_strategy_alerts);
    }

    function algoLinkFormatter(cell, row) {
        let algoid = row.algoId;
        return (<>
            <Link className="bg-transparent" to={"/algo/" + algoid}>{cell}</Link>
            &nbsp;&nbsp;<Tooltip width={'500px'} onOpen={() => loadAlertId(row)} textClass="tooltip-link small-text" text="Updates" position={"right center"}>
                {alertId ? <AlertConfigChanges alertId={alertId} /> : <>Not Found</>}
            </Tooltip>
        </>
        )
    }

    function winPercentChartFormatter(cell, row) {
        return <RangeChart width={200} value={row.winning_percentage} avg={row.comp_winning_percentage} range={warningPercent * 100} />
    }

    function avgProfitChartFormatter(cell, row) {
        return <RangeChart width={200} value={row.avg_profit} avg={row.comp_avg_profit} range={warningPercent * 100} />
    }

    function avgWinChartFormatter(cell, row) {
        return <RangeChart width={200} value={row.avg_win} avg={row.comp_avg_win} range={warningPercent * 100} />
    }
    function avgLossChartFormatter(cell, row) {
        return <RangeChart width={200} isReversed={true} value={row.avg_loss * -1} avg={row.comp_avg_loss * -1} range={warningPercent * 100} />
    }
    function maxWinChartFormatter(cell, row) {
        return <RangeChart width={200} value={row.max_win} avg={row.comp_max_win} range={warningPercent * 100} />
    }
    function maxLossChartFormatter(cell, row) {
        return <RangeChart width={200} isReversed={true} value={row.max_loss} avg={row.comp_max_loss} range={warningPercent * 100} />
    }

    return (
        <>
            <br></br>
            <br></br>
            <center>
                <div className='sameline'>
                    <div className='bold-big'>Compare</div>
                    <Dropdown width="150" onSelect={(e) => setYears(Number(e))}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            {years === 0 ? "Live Trades" : years + " years"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu  >
                            <Dropdown.Item eventKey={0} value={0}>Live Trades</Dropdown.Item>
                            <Dropdown.Item eventKey={1} value={1}>1 Years</Dropdown.Item>
                            <Dropdown.Item eventKey={2} value={2}>2 Years</Dropdown.Item>
                            <Dropdown.Item eventKey={3} value={3}>3 Years</Dropdown.Item>
                            <Dropdown.Item eventKey={5} value={5}>5 Years</Dropdown.Item>
                            <Dropdown.Item eventKey={10} value={10}>10 Years</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className='bold-big'>With</div>
                    <Dropdown width="150" onSelect={(e) => setCompareYears(Number(e))}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            {compareYears === 0 ? "Live Trades" : compareYears + " years"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu  >
                            <Dropdown.Item eventKey={0} value={0}>Live Trades</Dropdown.Item>
                            <Dropdown.Item eventKey={1} value={1}>1 Years</Dropdown.Item>
                            <Dropdown.Item eventKey={2} value={2}>2 Years</Dropdown.Item>
                            <Dropdown.Item eventKey={3} value={3}>3 Years</Dropdown.Item>
                            <Dropdown.Item eventKey={5} value={5}>5 Years</Dropdown.Item>
                            <Dropdown.Item eventKey={10} value={10}>10 Years</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <br></br>
                <div className="sameline">
                    <div className='bold-big'>Warning Percent:</div>
                    <Dropdown width="150" onSelect={(e) => setWarningPercent(Number(e / 100))}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            {warningPercent * 100}%
                        </Dropdown.Toggle>

                        <Dropdown.Menu  >
                            <Dropdown.Item eventKey={10} value={10}>10%</Dropdown.Item>
                            <Dropdown.Item eventKey={20} value={20}>20%</Dropdown.Item>
                            <Dropdown.Item eventKey={30} value={30}>30%</Dropdown.Item>
                            <Dropdown.Item eventKey={40} value={40}>40%</Dropdown.Item>
                            <Dropdown.Item eventKey={50} value={50}>50%</Dropdown.Item>
                            <Dropdown.Item eventKey={0} value={0}>Off</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <br></br>
                <input type="checkbox" checked={filtered} onChange={(e) => setFiltered(!filtered)}></input>&nbsp;Show Plan Only
                <br></br>
                <input type="checkbox" checked={showWinningPercentage} onChange={(e) => setShowWinningPercentage(!showWinningPercentage)}></input>&nbsp;Winning Percentage
                &nbsp;<input type="checkbox" checked={showAvgProfit} onChange={(e) => setShowAvgProfit(!showAvgProfit)}></input>&nbsp;Avg Profit
                &nbsp;<input type="checkbox" checked={showAvgWin} onChange={(e) => setShowAvgWin(!showAvgWin)}></input>&nbsp;Avg Win
                &nbsp;<input type="checkbox" checked={showAvgLoss} onChange={(e) => setShowAvgLoss(!showAvgLoss)}></input>&nbsp;Avg Loss
                &nbsp;<input type="checkbox" checked={showMaxWin} onChange={(e) => setShowMaxWin(!showMaxWin)}></input>&nbsp;Max Win
                &nbsp;<input type="checkbox" checked={showMaxLoss} onChange={(e) => setShowMaxLoss(!showMaxLoss)}></input>&nbsp;Max Loss
                &nbsp;<input type="checkbox" checked={showTradeCount} onChange={(e) => setShowTradeCount(!showTradeCount)}></input>&nbsp;Trade Count
                <br></br>
                <Link id="managealgos" className="bg-transparent" to={"/managealgos"}>Update Algos</Link>
            </center>
            <br></br>
            <RichTable data={getStats()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter, sorters: true, filter: 'text' },
                { title: 'Symbol', field: 'trade_symbol', sorters: true, filter: 'text' },
                { title: 'Win %', field: 'winning_percentage', formatter: winPercentChartFormatter, sorters: true, hidden: !showWinningPercentage },
                { title: 'Avg P/L', field: 'avg_profit', formatter: avgProfitChartFormatter, sorters: true, hidden: !showAvgProfit },
                { title: 'Avg Win', field: 'avg_win', formatter: avgWinChartFormatter, sorters: true, hidden: !showAvgWin },
                { title: 'Avg Loss', field: 'avg_loss', formatter: avgLossChartFormatter, sorters: true, hidden: !showAvgLoss },
                { title: 'Max Win', field: 'max_win', formatter: maxWinChartFormatter, sorters: true, hidden: !showMaxWin },
                { title: 'Max Loss', field: 'max_loss', formatter: maxLossChartFormatter, sorters: true, hidden: !showMaxLoss },
                { title: 'Trades', field: 'count', formatter: tableHelpers.intFormatter, sorters: true, hidden: !showTradeCount },
                { title: compareYears + ' Trades', field: 'comp_count', formatter: tableHelpers.intFormatter, sorters: true, hidden: !showTradeCount },
            ]} />

            <br></br>
            <br></br>
        </>
    );
}

export default AlgoPerformanceComparison;