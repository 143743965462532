import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

function AlertDataSample(props) {
    const [selectedSampleName, setSelectedSampleName] = useState("Default Data Sample");
    const [addRandomness, setAddRandomness] = useState(false);

    useEffect(() => {
        (async function () {

        })();
    }, []);

    function getSampleOptions() {
        let threeYearsAgo = new Date(); threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);
        return [
            { id: 0, name: 'Default Data Sample', startDate: new Date(2014, 0, 1), endDate: new Date() },
            { id: 1, name: '2005-2014', startDate: new Date(2005, 0, 1), endDate: new Date(2014, 0, 1) },
            { id: 2, name: '2008-2014', startDate: new Date(2008, 0, 1), endDate: new Date(2014, 0, 1) },
            { id: 3, name: '2005-Present', startDate: new Date(2005, 0, 1), endDate: new Date() },
            { id: 4, name: '2008-Present', startDate: new Date(2008, 0, 1), endDate: new Date() },
            { id: 5, name: '2011-2014', startDate: new Date(2011, 0, 1), endDate: new Date(2014, 0, 1) },
            { id: 6, name: '2011-present', startDate: new Date(2011, 0, 1), endDate: new Date() },
            { id: 7, name: '2014-2021', startDate: new Date(2014, 0, 1), endDate: new Date(2021, 0, 1) },
            { id: 8, name: '2021-present', startDate: new Date(2021, 0, 1), endDate: new Date() },
            { id: 9, name: 'Random 3 Years', startDate: null, endDate: null },
        ]
    }

    function onSampleSelected(id) {
        console.log("onSampleSelected", id);
        const sample = getSampleOptions().find(s => s.id === Number(id));
        if (sample) {
            setSelectedSampleName(sample.name);
            if (props.onDataSampleUpdated) {
                if (sample.id === 9) {
                    let start = new Date();
                    let startYearsAgo = 3 + Math.trunc(Math.random() * 10);
                    start.setFullYear(start.getFullYear() - startYearsAgo);
                    let end = new Date(start);
                    end.setFullYear(end.getFullYear() + 3);
                    props.onDataSampleUpdated(start, end, addRandomness);
                } else {
                    props.onDataSampleUpdated(sample.startDate, sample.endDate, addRandomness);
                }
            }
        }
    }

    function onAddRandomnessUpdated(newValue) {
        if (props.onAddRandomnessUpdated)
            props.onAddRandomnessUpdated(newValue);
        setAddRandomness(newValue);
    }

    return (
        <div className="sameline">
            < Dropdown width="150" onSelect={(e) => onSampleSelected(e)
            }>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {selectedSampleName}
                </Dropdown.Toggle>

                <Dropdown.Menu  >
                    {getSampleOptions().map(sample => {
                        return (<Dropdown.Item as='sampleId' eventKey={sample.id} value={sample.name}>{sample.name}</Dropdown.Item>)
                    })}
                </Dropdown.Menu>
            </Dropdown >

            &nbsp;&nbsp;<input type='checkbox' checked={addRandomness} onClick={() => onAddRandomnessUpdated(!addRandomness)} />Add Randomness
        </div>
    );
}

export default AlertDataSample;