import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function BacktestUpload(props) {

    const [file, setFile] = useState()
    const [algoId, setAlgoId] = useState(0);
    const [algos, setAlgos] = useState([])
    const [postResult, setPostResult] = useState();
    const [backtestMetadata, setBacktestMetadata] = useState([]);
    const [uploadCount, setUploadCount] = useState(0);


    useEffect(() => {
        (async function () {

            async function fetchAlgoData() {
                const text = await (await fetch(`/api/algos?planId=` + props.planId)).json();
                setAlgos(text);
                const backtestMetaDataText = await (await fetch(`/api/backtestTradeMetadata?planId=` + props.planId)).json();
                setBacktestMetadata(backtestMetaDataText)
            }

            fetchAlgoData();
            if (uploadCount === 1) {
                const interval = setInterval(fetchAlgoData, 10000);
                return () => clearInterval(interval);
            }
        })();
    }, [props.planId, uploadCount]);

    function handleChange(event, id) {
        setFile(event.target.files[0])
        setAlgoId(id)
    }

    async function handleSyncWithStrategyAlerts(algoId) {
        console.log("handleSyncWithStrategyAlerts", algoId);

        const url = `/api/algoSyncWithStrategyAlerts?algoId=` + algoId;
        const text = await (await fetch(url, {
            method: 'POST'
        })).json();
        console.log("Results of post", text);
        setPostResult(text);
        setUploadCount(uploadCount + 1);
    }

    async function handleSubmit(event) {
        event.preventDefault()

        const formData = new FormData();
        formData.append('file', file);
        //formData.append('fileName', file.name);

        const url = `/api/backtestfile?planId=` + props.planId + `&algoId=` + algoId;
        const text = await (await fetch(url, {
            method: 'POST', body: file
        })).json();
        console.log("Results of post", text);
        setPostResult(text);
        setUploadCount(uploadCount + 1);
    };

    function getMetadata(id) {
        return backtestMetadata.find(algo => algo.algoId === id);
    }

    return (
        <div>
            <h1>TradingView File Upload</h1>

            <Table border="1">
                <tr>
                    <th>Algo</th>
                    <th>Symbol</th>
                    <th>Win %</th>
                    <th>Avg Profit</th>
                    <th>Last Updated</th>
                    <th>Trades</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Sync w/ Strategy</th>
                    <th>Upload</th>
                </tr>

                {algos.map(algo =>
                    <tr>
                        <td>{algo.display_name}</td>
                        <td>{algo.trade_symbol}</td>
                        <td>{algo.winning_percentage ? algo.winning_percentage.toFixed(1) + "%" : "N/A"}</td>
                        <td>{algo.avg_profit ? algo.avg_profit.toFixed(2) + "%" : "N/A"}</td>
                        <td>{algo.last_updated ? algo.last_updated : "N/A"}</td>
                        <td>{getMetadata(algo.algoId) ? getMetadata(algo.algoId).count : 0}</td>
                        <td>{getMetadata(algo.algoId) ? new Date(getMetadata(algo.algoId).start_date).toLocaleDateString() : "N/A"}</td>
                        <td>{getMetadata(algo.algoId) ? new Date(getMetadata(algo.algoId).end_date).toLocaleDateString() : "N/A"}</td>
                        <td>
                            {props.role >= 3 ?
                                <Link id="sync" className="bg-transparent" onClick={e => { handleSyncWithStrategyAlerts(algo.algoId) }}>Sync</Link>
                                :
                                null}
                        </td>
                        <td>
                            {props.role >= 3 ?
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <input class="btn btn-secondary" type="file" onChange={(event) => handleChange(event, algo.algoId)} />
                                        <button class="btn btn-primary" type="submit">Upload</button>
                                    </form>
                                </>
                                :
                                null}
                        </td>
                    </tr>
                )}

            </Table>

            {JSON.stringify(postResult)}
        </div>
    );
}

export default BacktestUpload;