import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import DatePicker from "react-datepicker";
import AlertConfigChanges from '../alerts/AlertConfigChanges.js'
import Tooltip from '../components/Tooltip.js'

function TradeAudit(props) {
    const [audit, setAudit] = useState(null);
    const [date, setDate] = useState(new Date());
    const [alerts, setAlerts] = useState([]);
    const [alertId, setAlertId] = useState(null);

    useEffect(() => {
        (async function () {
            setAudit(null);
            fetch(`/api/tradeAudit?date=` + date.toLocaleDateString())
                .then(res => res.json())
                .then(text => {
                    console.log(text);
                    setAudit(text);
                })

            const text = await (await fetch(`/api/strategyAlerts`)).json();
            console.log("alerts", text);
            setAlerts(text);
        })();
    }, [date]);

    function getMissingBacktestTrades() {
        if (audit && audit.missingBacktestTrades) {
            return audit.missingBacktestTrades.map(t => {
                return {
                    algoId: t.algoId,
                    name: t.name,
                    symbol: t.symbol,
                    display_name: t.display_name,
                    trade_symbol: t.trade_symbol,
                    purchase_date: t.purchase_date,
                    sell_date: t.sell_date
                }
            })
        } else {
            return [];
        }
    }

    function getMissingTrades() {
        if (audit && audit.missingTrades) {
            return audit.missingTrades.map(t => {
                return {
                    algoId: t.algoId,
                    display_name: t.display_name,
                    trade_symbol: t.trade_symbol,
                    action: t.action,
                    name: t.name,
                    symbol: t.symbol,
                }
            })
        } else {
            return [];
        }
    }

    function loadAlertId(row) {
        setAlertId(null);
        let alert = alerts.find(a => a.algo_name === row.name && a.symbol === row.symbol);
        if (alert)
            setAlertId(alert.idtrading_strategy_alerts);
    }

    function algoLinkFormatter(cell, row) {
        let algoid = row.algoId;
        return (
            <>
                <Link className="bg-transparent" to={"/algo/" + algoid}>{cell}</Link>

                &nbsp;&nbsp;<Tooltip width={'500px'} onOpen={() => loadAlertId(row)} textClass="tooltip-link small-text" text="Updates" position={"right center"}>
                    {alertId ? <AlertConfigChanges alertId={alertId} /> : <>Not Found</>}
                </Tooltip>
            </>
        )
    }

    return (
        <>
            <br></br>
            Date: <DatePicker className="bg-transparent" selected={date} onChange={(d) => setDate(d)} />
            <br></br>
            <br></br>
            <h4>Missing Trades {(audit && audit.trades ? "(" + audit.trades.length + " total trades)" : null)}</h4>
            <RichTable data={getMissingTrades()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter, sorters: true },
                { title: 'Symbol', field: 'trade_symbol', sorters: true },
                { title: 'Action', field: 'action', sorters: true },
            ]} />
            <h4>Missing Backtest Trades {(audit && audit.backtestTrades ? "(" + audit.backtestTrades.length + " total trades)" : null)}</h4>
            <RichTable data={getMissingBacktestTrades()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter, sorters: true },
                { title: 'Symbol', field: 'trade_symbol', sorters: true },
                { title: 'Purchase Date', field: 'purchase_date', formatter: tableHelpers.dateFormmatter },
                { title: 'Sell Date', field: 'sell_date', sorters: true, formatter: tableHelpers.dateFormmatter },
            ]} />

            <br></br>
            <br></br>
        </>
    );
}

export default TradeAudit;