export default function UserAgreement() {
  return (
    <div>
      <h3>User Agreement</h3>
      <br></br>
      <br></br>
      Last modified: July 9, 2024
      <br></br>
      <br></br>
      DISCLAIMER: The Stockpiler website (www.stockpiler.net) is designed to help investors and those interested in investing obtain information concerning stocks and exchange-traded funds (ETFs). IF YOU DO NOT AGREE WITH THE TERMS OF THIS DISCLAIMER, EXIT THIS SITE IMMEDIATELY. PLEASE BE ADVISED THAT YOUR CONTINUED USE OF THIS SITE OR THE INFORMATION PROVIDED HEREIN SHALL INDICATE YOUR CONSENT TO AND AGREEMENT WITH THESE TERMS.
      <br></br>
      <br></br>

      Impossible Things LLC DOES NOT PROVIDE INDIVIDUAL INVESTMENT ADVICE. We provide information in the form of “Buy” and “Sell” alerts (“Alerts”) that we believe to be accurate and that constitutes our opinion as to the subject matter covered in the Alert. We are not an investment advisor and we are not licensed as such (or in any other capacity) by the U.S. Securities and Exchange Commission (SEC) or any other federal or state regulatory authority. All information provided by Impossible Things LLC is only to be construed as opinion and is to be used as an information service only.
      <br></br>
      <br></br>

      Impossible Things LLC PERSONNEL ACTIVELY TRADE IN STOCKS, INCLUDING IN ALERTED SECURITIES. Impossible Things LLC is a company comprised of investors and as such, those responsible for publishing Alerts and other information on this site actively trade in the stocks and ETFs mentioned by the Service. In most cases, due to the way the Service operates, the managers and/or other personnel of Impossible Things LLC will have already bought or sold the stock or ETF covered by an Alert.
      <br></br>
      <br></br>

      YOU ARE RESPONSIBLE FOR YOUR OWN INVESTMENT DECISIONS. Impossible Things LLC will not be responsible for any errors or omissions in any information on our website, or on any hyperlinked sites, or for any results obtained from the use of such information. Impossible Things LLC will not be liable for any loss or damage caused by a reader’s reliance on information obtained on our website, or from a hyperlinked site. If you don’t accept this responsibility for yourself, then you should not use Stockpiler.
      <br></br>
      <br></br>

      Impossible Things LLC MAKES NO REPRESENTATION THAT YOU WILL PROFIT FROM ANY TRADES MADE BASED ON INFORMATION WE PROVIDE. Furthermore, in no event shall Impossible Things LLC be liable for direct, indirect, or incidental damages resulting from the use of the information found on, linked or distributed through our website. Impossible Things LLC shall be indemnified and held harmless from any actions, claims, proceedings, or liabilities with respect to the information we provide and its use.
      <br></br>
      <br></br>

      IF YOU CHOOSE NOT TO DO INDEPENDENT RESEARCH, THAT’S ENTIRELY YOUR DECISION. We strongly suggest you do your own research of industries, companies, and stocks, and not rely solely on information, Alerts or other opinions found on our site or on a hyperlinked site. You should use this site and others as just a part of your independent research on companies and on investing in general. Choosing not to do such research is a conscious, willing, free and personal decision on your part and an acceptance of the risk involved in such decision. It’s also most likely a mistake. You should also strongly consider seeking the advice of a duly licensed, professional investment advisor.
      <br></br>
      <br></br>

      WE CANNOT GUARANTEE THE RELIABILITY OF THIRD-PARTY DATA AND ANALYSIS. We obtain data from various sources that we believe to be reliable, such as finance.yahoo.com and 12Data. We cannot, however, guarantee that such data is always accurate or that it will be accurate in the future. Accordingly, Impossible Things LLC makes no claims or representations as to the accuracy, completeness, or truth of any material or information contained on our website. Impossible Things LLC is not liable for any errors or delays in the content or transmission of the data on our site.
      <br></br>
      <br></br>

      RISK DISCLOSURE
      <br></br>
      <br></br>

      Impossible Things LLC will not be liable for any investment decision you make, or action you take based upon reliance on any Alert, other opinion, or other material you view on our website. Your subscription fee only pays for you to access the Service subscribed for. As noted above, this site only expresses our opinion on the investments described herein and in Alerts. Our opinion may and will be wrong at times due to the inherent limitations of technical analysis. Technical analysis is not a science that predicts precise and accurate results. Technical analysis cannot be used with any degree of accuracy to predict or forecast the unknown factors that make up the short-term volatility, trends, and the future direction of the stock market or of any individual stocks or ETFs. The information on the site should not be relied upon for purposes of transacting in stocks or other investments. We cannot and do not assess or guarantee the suitability or profitability of any particular investment, or the potential value of any investment or informational source. The stocks and related actions mentioned on our site or in Alerts may be unsuitable for investors depending on their specific investment objectives and financial position. There is a substantial amount of risk in trading stocks, and the possibility exists that you can lose all or a portion of your capital. There is a substantial amount of risk if you use our opinions expressed on this site or in any Alerts to buy and sell stocks.
      <br></br>
      <br></br>

      WE MAY PUBLISH THE RESULTS OF SIMULATED OR HYPOTHETICAL TRADES OR ACCOUNTS. To the extent we do so, the following specific disclaimer applies to such results: These results are based on simulated or hypothetical performance results that have certain inherent limitations. Unlike the results shown in an actual performance record, these results do not represent actual trading. Also, because these trades have not actually been executed, these results may have under-or over-compensated for the impact, if any, of certain market factors, such as lack of liquidity. Simulated or hypothetical trading programs in general are also subject to the fact that they are designed with the benefit of hindsight. No representation is being made that any account will or is likely to achieve profits or losses similar to those being shown. Past performance is never indicative of future results.
      <br></br>
      <br></br>

      TESTIMONIALS DISCLAIMER
      <br></br>
      <br></br>

      In accordance with 16 CFR 255 and Federal Trade Commission Guidelines concerning the use of endorsements and testimonials in marketing and advertising, please be aware of the following:
      <br></br>
      <br></br>

      Testimonials appearing on this site are received via text, audio, or video submission. They are individual experiences, reflecting real-life experiences of those who have used our services in some way or another. However, they are individual results, and results do vary. We do not claim that they are typical results that consumers will generally achieve, nor can we make any guarantees for the results of individuals using the Service. The testimonials are not necessarily representative of all of those who will use our products and/or services. The testimonials are not indicative of future performance and should not replace a subscriber’s due diligence.
      <br></br>
      <br></br>

      The testimonials displayed (text, audio, and/or video) are given verbatim except for correction of grammatical or typing errors. Some have been shortened. In other words, not the whole message received by the testimonial writer is displayed when it seemed lengthy or not the whole testimonial seemed relevant for the general public.
      <br></br>
      <br></br>

      Testimonials that appear on Stockpiler were not paid or compensated for said statements.
      <br></br>
      <br></br>

      CHECK OUT OTHER VALUABLE INFORMATION SOURCES. Among them, the SEC and other regulatory authorities, including the Financial Industry Regulatory Authority (FINRA) and the North American Securities Administrators Association (NASAA), maintain websites that include valuable information on numerous subjects, including cyber fraud, online stock trading, and other relevant topics. See, http://www.sec.gov, http://www.finra.org, and http://www.nasaa.org, respectively.
      <br></br>
      <br></br>

      TERMS OF SERVICE: Welcome to Impossible Things LLC Buy/Sell Alert Service (the “Service”). This page sets forth the special Terms and Conditions by which Impossible Things LLC provides you with access to the Service (the “Terms”). By subscribing to and using the Service you are agreeing to abide by these Terms INCLUDING THE BINDING ARBITRATION AND CLASS ACTION WAIVER DETAILED BELOW.
      <br></br>
      <br></br>

      Buy/Sell Alert Service
      <br></br>
      <br></br>

      The Service provides recommendations for buying and selling stock in publicly-traded companies and exchange-traded funds (“ETFs”), as well as suggesting a percent allocation of total portfolio value for each “Buy” or “Sell” alert (each, an “Alert”). The Service is restricted in its recommendations to publicly-traded securities including stocks and ETFs. You will also receive weekly newsletters and Alert summaries. From time to time, we may remove, add to, or change the features, offerings, and specific services included in your Service membership, but we will provide you with advance notice via email of any material changes.
      <br></br>
      <br></br>

      All services and features provided included with the Service are provided “AS IS.” Impossible Things LLC assumes no responsibility for the timeliness or delivery of any content or the functionality or suitability of the Service.
      <br></br>
      <br></br>

      Not Investment Advice
      <br></br>
      <br></br>

      Impossible Things LLC does not offer personalized investment advice. The Alerts and information provided by the Service do not constitute a recommendation that a particular security, strategy, or action is suitable for you or any specific person for that matter. No information on the site is intended as investment advice, as an offer or solicitation of an offer to sell or buy, or as an endorsement or recommendation of any company, security, or fund.
      <br></br>
      <br></br>

      Trading Disclosure
      <br></br>
      <br></br>

      Impossible Things LLC personnel actively trade in stocks, including in alerted securities. Impossible Things LLC is a company comprised of investors and as such, those responsible for publishing Alerts and other information on this site actively trade in the stocks and ETFs mentioned by the Service. In most cases, due to the way the Service operates, the managers and/or other personnel of Impossible Things LLC will have already bought or sold the stock or ETF covered by an Alert.
      <br></br>
      <br></br>

      Disclaimer of Warranties and Liabilities
      <br></br>
      <br></br>

      By using the Service, you agree that your access to and use of the Service is at your own risk. Impossible Things LLC and its representatives disclaim all warranties, either express or implied, statutory, or otherwise, including but not limited to the implied warranties of merchantability, non-infringement of third parties' rights, and fitness for particular purpose.
      <br></br>
      <br></br>

      Applicable Law
      <br></br>
      <br></br>

      The Terms and any dispute arising under or related to them will be governed by the laws of the State of Missouri without regard to its conflict of law provisions. Any dispute arising under or related to these Terms shall be resolved exclusively within the state courts of the State of Missouri.
      <br></br>
      <br></br>

      Dispute Resolution
      <br></br>
      <br></br>

      ANY DISPUTES ARISING OUT OF OR RELATED TO THESE TERMS OR THE SERVICE SHALL BE RESOLVED THROUGH BINDING ARBITRATION. Arbitration shall take place in Missouri, and shall be conducted in accordance with the American Arbitration Association’s rules for arbitration of consumer-related disputes. The arbitrator's award shall be final and binding and may be entered as a judgment in any court of competent jurisdiction. To the fullest extent permitted by applicable law, no arbitration under these Terms shall be joined to an arbitration involving any other party subject to these Terms, whether through class arbitration proceedings or otherwise.
      <br></br>
      <br></br>

      Intellectual Property
      <br></br>
      <br></br>

      All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the site, is the property of Impossible Things LLC or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights.
      <br></br>
      <br></br>

      Membership
      <br></br>
      <br></br>

      Subscriptions to the Service are for online access only and may include automatic billing. You may cancel your subscription at any time before the next billing date.
      <br></br>
      <br></br>

      Forum Conduct
      <br></br>
      <br></br>

      Impossible Things LLC may provide discussion forums or other communication facilities where members can post information and share opinions, but we do not endorse any content posted by members and do not assume any responsibility for it.
      <br></br>
      <br></br>

      Indemnification
      <br></br>
      <br></br>

      You agree to indemnify and hold Impossible Things LLC, its affiliates, officers, agents, and other partners and employees, harmless from any loss, liability, claim, or demand, including reasonable attorney's fees, made by any third party due to or arising out of your use of the Service in violation of these Terms and/or arising from a breach of these Terms and/or any breach of your representations and warranties set forth herein.
      <br></br>
      <br></br>

      Limitation of Liability
      <br></br>
      <br></br>

      Impossible Things LLC and its representatives shall not be liable to you for any damages resulting from your use of the Service or reliance on any information provided therein. In no event shall Impossible Things LLC or its representatives be liable to you or any third party for any indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit damages arising from or in connection with the use of the Service or any content provided on or through the Service, even if Impossible Things LLC has been advised of the possibility of such damages.
      <br></br>
      <br></br>

      Changes
      <br></br>
      <br></br>

      Impossible Things LLC reserves the right, at its sole discretion, to modify or replace any part of these Terms. It is your responsibility to check these Terms periodically for changes. Your continued use of or access to the Service following the posting of any changes to these Terms constitutes acceptance of those changes.
      <br></br>
      <br></br>



    </div>
  )
}