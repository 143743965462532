import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import tableHelpers from '../helpers/TableHelpers.js'
import RichTable from '../components/richTable/RichTable.jsx'
import { createDateSorter } from '../components/richTable/sorters.js'
import AlgoQuickStats from '../components/AlgoQuickStats.js'
import Tooltip from '../components/Tooltip.js'

function AllocationChanges(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        (function () {
            fetch(`/api/allocationChanges/?planId=` + props.planId)
                .then(res => res.json())
                .then(text => {
                    let mergedTrades = mergeTrades(text);
                    setData(mergedTrades);
                });
        })();
    }, [props.planId]);

    function isToday(date) {
        let now = new Date();
        return now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear();
    }

    function getAction(change) {
        let retVal = "";
        if (change.action === 'buy') {
            retVal = "Added " + change.percentage + "%";
        } else {
            retVal = "Reduced " + change.percentage + "%";
        }
        return retVal;
    }

    function mergeTrades(trades) {
        let merged = [];
        for (let i = 0; i < trades.length; i++) {
            let trade = { ...trades[i] };
            if (trade.merged === true)
                continue;

            trade.algos = [{
                display_name: trade.display_name,
                algoId: trade.algoId,
                algo_type: trade.algo_type,
                profitPercentage: trade.profitPercentage,
                winning_percentage: trade.winning_percentage,
                avg_profit: trade.avg_profit,
                avg_duration: trade.avg_duration,
                avg_win: trade.avg_win,
                avg_loss: trade.avg_loss,
                robustness_overall: trade.robustness_overall,
                allocation: trade.percentage,
                netProfitPercentage: trade.profitPercentage * trade.percentage / 100
            }];

            for (let j = i + 1; j < trades.length; j++) {
                let otherTrade = trades[j];
                if (otherTrade.merged !== true && trade.date === otherTrade.date && trade.trade_symbol === otherTrade.trade_symbol && trade.action === otherTrade.action) {
                    console.log("Merging trades", trade, otherTrade);
                    otherTrade.merged = true;
                    trade.percentage += otherTrade.percentage;
                    trade.algos.push({
                        display_name: otherTrade.display_name,
                        algoId: otherTrade.algoId,
                        algo_type: otherTrade.algo_type,
                        profitPercentage: otherTrade.profitPercentage,
                        winning_percentage: otherTrade.winning_percentage,
                        avg_profit: otherTrade.avg_profit,
                        avg_duration: otherTrade.avg_duration,
                        avg_win: otherTrade.avg_win,
                        avg_loss: otherTrade.avg_loss,
                        robustness_overall: otherTrade.robustness_overall,
                        allocation: otherTrade.percentage,
                        netProfitPercentage: otherTrade.profitPercentage * otherTrade.percentage / 100
                    });
                }
            }
            merged.push(trade);
        }
        return merged;
    }

    function getChanges() {
        return data.map(change => {
            return {
                date: change.date,
                trade_symbol: change.trade_symbol,
                display_name: change.display_name,
                algoId: change.algoId,
                action: getAction(change),
                close: change.close,
                profitPercentage: change.profitPercentage,
                shares: change.close > 0 ? (props.accountValue * change.percentage / 100 / change.close) : 0,
                algo_type: change.algo_type,
                winning_percentage: change.winning_percentage,
                avg_profit: change.avg_profit,
                avg_duration: change.avg_duration,
                avg_win: change.avg_win,
                avg_loss: change.avg_loss,
                robustness_overall: change.robustness_overall,
                algos: change.algos,
            }
        })
    }

    function algoLinksFormatter(cell, row) {
        return (
            <>
                {
                    row.algos.map(algo => {
                        return (
                            <>
                                <Link className="bg-transparent" to={"/algo/" + algo.algoId}>{algo.display_name}</Link>
                                &nbsp;&nbsp;<Tooltip textClass={"tooltip-link"} text={"🔍"} width={225} borderWidth={"0px"} position="right center" >
                                    <center>
                                        <AlgoQuickStats winningPercentage={algo.winning_percentage}
                                            avgProfit={algo.avg_profit}
                                            avgWin={algo.avg_win}
                                            avgLoss={algo.avg_loss}
                                            avgDuration={algo.avg_duration}
                                            robustness={algo.robustness_overall}
                                            algoType={row.algo_type.toUpperCase()}
                                        />
                                    </center>
                                </Tooltip >
                                {renderNewLineIfNecessary(row.algos.length)}
                            </>)
                    })
                }
            </>
        );
    }

    function renderNewLineIfNecessary(count) {
        return count > 1 ? (<><br></br><br></br></>) : null;
    }

    function algoAllocationFormatter(cell, row) {
        return (
            <>
                {
                    row.algos.map(algo => {
                        return (<>{algo.allocation}%{renderNewLineIfNecessary(row.algos.length)}</>)
                    })
                }
            </>
        );
    }

    function profitFormatter(cell, row) {
        return (
            <>
                {
                    row.algos.map(algo => {
                        return (<>{tableHelpers.percentChangeFormatter(algo.profitPercentage, row)}{renderNewLineIfNecessary(row.algos.length)}</>)
                    })
                }
            </>
        );
    }

    function netProfitFormatter(cell, row) {
        return (
            <>
                {
                    row.algos.map(algo => {
                        return (<>{tableHelpers.percentChangeFormatter(algo.netProfitPercentage, row)}{renderNewLineIfNecessary(row.algos.length)}</>)
                    })
                }
            </>
        );
    }

    function rowClassNameFormat(row) {
        // row is whole row object
        let d = new Date(row.date);
        d.setHours(d.getHours() + 8);
        if (isToday(d)) {
            return "tableRowBold";
        } else {
            return "tableRow"
        }
    }

    return (
        <>
            <br></br>
            <br></br>
            <>
                <center>
                    <div className='bold'>
                        Please visit <Link id="link" className="bg-transparent" to={"/newTrades"}>Today's Trades</Link> to get the trades for today.
                        <br></br>
                        This will ensure that long/short hedge trades take each other into account.
                    </div>
                </center>
            </>

            <br></br>
            <RichTable pageSize={10} rowClassName={rowClassNameFormat} data={getChanges()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Date', field: 'date', sorters: createDateSorter('date'), formatter: tableHelpers.dateFormmatter },
                { title: 'Symbol', field: 'trade_symbol', sorters: true },
                { title: 'Action', field: 'action', sorters: true },
                { title: 'Shares', field: 'shares', formatter: tableHelpers.intFormatter, sorters: true },
                { title: 'P/L', field: 'profitPercentage', formatter: profitFormatter, hidden: props.mobileView },
                { title: 'Algos', field: 'algos', formatter: algoLinksFormatter, hidden: props.mobileView },
                { title: 'Allocation', field: 'algos', formatter: algoAllocationFormatter, hidden: props.mobileView },
                { title: 'Net P/L', field: 'algos', formatter: netProfitFormatter, hidden: props.mobileView },
            ]} />
            <br></br>
        </>
    );
}

export default AllocationChanges;