import React from 'react';
import { Link } from 'react-router-dom'

function TCFooter(props) {

    return (
        <div className='tc-footer'>

            Copyright © 2024 Impossible Things LLC
            <br></br>
            <Link to="/tc/privacy" className='smallest-text'>Privacy Policy</Link> | <Link to="/tc" className='smallest-text'>Terms & Conditions</Link> | <Link to="/tc/user" className='smallest-text'>User Agreement</Link> | <Link to="/tc/personalInfo" className='smallest-text'>Personal Information</Link>
            <br></br><br></br>
            Disclaimer. The content on this website and in any Alerts or other communications you receive from us is for informational and educational purposes only and is not and should not be construed as professional financial, investment, tax, or legal advice.
            <br></br>
            Trading in stocks, futures trading, and options trading have large potential rewards but also large potential risk. You must be aware of the risks and be willing to accept them in order to trade stocks and other securities.
            <br></br><br></br>
            The past performance of any trading system, methodology, or particular trader is not indicative of future results. Impossible Things LLC is not liable for any money lost and cannot guarantee any gains. Traders should only invest what they can afford to lose, and always trade with caution. Stockpiler users should independently investigate and fully understand all risks before investing.
            <br></br><br></br>
            Review our full Disclaimer before using this website. If you disagree with the terms of the Disclaimer, please exit this website.
        </div >
    );
}

export default TCFooter;