import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ActionResult from '../components/ActionResult.js';

function Authorized(props) {

    let [params] = useSearchParams();

    const [result, setResult] = useState(null);
    const [state, setState] = useState(null);

    useEffect(() => {
        (async function () {
            let code = params.get("code");
            let state = params.get("state");
            if (code) {
                const url = "/api/brokerageAuth?userId=" + props.userId + "&brokerage=schwab&code=" + code;
                console.log(url);
                let response = await (await fetch(url, { method: 'POST' })).json();
                console.log("brokerageAuth response", response);
                setResult(response);
            }
            if (state)
                setState(state);
        })();
    }, [params, props.userId]);

    function renderLoggedInComponent() {
        setTimeout(() => {
            if (state === "trade")
                window.location.href = '/accountsSummary'
            else if (state === "link") {
                window.location.href = '/addAccount'
            } else {
                window.location.href = '/accountsSummary'
            }
        }, 2000)

        return (
            <div>
                <p>Successfully logged into brokerage....Redirecting in a few seconds</p>
            </div>
        )
    }

    function renderErrorComponent() {
        return (
            <div>
                <h4>Authentication failed with brokerage</h4>
                <br></br>
                <p>Return to <a href="/overview">Overview</a></p>
            </div>
        )
    }

    return (
        <>
            {result === null ? <h4>Verifying credentials, please wait...</h4> : null}
            {result && result.success ? renderLoggedInComponent() : null}
            {result && result.error ? renderErrorComponent() : null}

            <ActionResult result={result} />
        </>
    );
}

export default Authorized;