import React, { useState } from 'react';
import Replay from './Replay';
import StressTest from './StressTest'
import AlgoCorrelation from '../algos/AlgoCorrelation'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AlgoRobustness from '../algos/AlgoRobustness';


function PlanAnalysis(props) {

    const [tab, setTab] = useState('robustness');

    function shouldAllow() {
        return (props.role >= 2);
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };


    return (
        <div>
            {shouldAllow() ?
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab value="robustness" label="Robustness" />
                            <Tab value="correlations" label="Correlations" />
                            <Tab value="replay" label="Replay" />
                            <Tab value="montecarlo" label="Monte Carlo" />
                        </Tabs>
                    </Box>
                    <br></br>

                    {tab === "replay" ?
                        <>
                            <Replay planId={props.planId} />
                        </>
                        : null}
                    {tab === "montecarlo" ?
                        <>
                            <StressTest planId={props.planId} onAlgoSelected={props.onAlgoSelected} />
                        </>
                        : null}
                    {tab === "correlations" ?
                        <>
                            <AlgoCorrelation planId={props.planId} onAlgoSelected={props.onAlgoSelected} />
                        </>
                        : null}

                    {tab === "robustness" ?
                        <>
                            <AlgoRobustness mobileView={props.mobileView} planId={props.planId} />
                        </>
                        : null}


                </>
                :
                <div class="empty-page" >Not allowed</div>
            }


        </div>
    );
}

export default PlanAnalysis;