import React, { useEffect, useState } from 'react';
import Overview from './Overview';
import AccountsSummary from './accounts/AccountsSummary'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';


function Home(props) {

    let { activeTab } = useParams();

    const [tab, setTab] = useState(activeTab ? activeTab : 'overview');
    const [account, setAccount] = useState(null);

    function shouldAllow() {
        return true;
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    useEffect(() => {
        async function loadAccounts() {
            if (props.accountId && props.userId) {
                const response = await fetch(`/api/accounts?userId=${props.userId}`)
                if (response.ok) {
                    const text = await response.json()
                    let account = text.find(act => act.idaccounts === props.accountId);
                    console.log(props.accountId, account);
                    setAccount(account)
                }
            }
        }
        loadAccounts()
    }, [props.userId, props.accountId]);

    return (
        <div>
            {shouldAllow() ?
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab value="overview" label="Plan Overview" />
                            <Tab value="accounts" label="Accounts Summary" />
                        </Tabs>
                    </Box>
                    <br></br>

                    {tab === "overview" ?
                        <>
                            <Overview accountId={props.accountId} isOptions={props.isOptions} accountStartDate={props.accountStartDate} planId={props.planId} accountValue={props.accountValue} userId={props.userId}
                                mobileView={props.mobileView}
                                brokerage={account ? account.brokerage : undefined}
                                brokerageHref={account ? account.brokerage_href : undefined}
                                brokerageAuthenticated={account ? account.access_token && account.access_token.length > 0 : undefined}
                            />
                        </>
                        : null}
                    {tab === "accounts" ?
                        <>
                            <AccountsSummary mobileView={props.mobileView} accountId={props.accountId} userId={props.userId} onAccountsUpdated={props.onAccountsUpdated} />
                        </>
                        : null}
                </>
                :
                <div class="empty-page" >Not allowed</div>
            }


        </div>
    );
}

export default Home;