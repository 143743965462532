import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import DailyBalanceChart from '../plan/DailyBalanceChart.js'
import PlanScorecard from '../plan/PlanScorecard.js';
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker"
import forceLogout from '../helpers/forceLogout.js'
import ActionResult from '../components/ActionResult.js';
import Dropdown from 'react-bootstrap/Dropdown';


function AddAccount(props) {
    const [subscriptions, setSubscriptions] = useState([]);
    const [currentBalance, setCurrentBalance] = useState('')
    const [planId, setPlanId] = useState(0)
    const [name, setName] = useState('')
    const [backtestResults, setBacktestResults] = useState({})
    const [chartData, setChartData] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [updates, setUpdates] = useState(0)
    const [result, setResult] = useState(null);
    const [brokerages, setBrokerages] = useState([]);
    const [brokerageAccounts, setBrokerageAccounts] = useState([]);

    useEffect(() => {
        async function getSubscriptions() {
            const response = await fetch(`/api/subscriptions?userId=${props.userId}`)
            if (response.ok) {
                const text = await response.json()
                setSubscriptions(text)
            } else {
                console.error(`Failed to load subscriptions for user ${props.userId} - HTTP ${response.status}`)
                forceLogout()
                return
            }
        }
        getSubscriptions()

        async function getAccounts() {
            const response = await fetch(`/api/accounts?userId=${props.userId}`)
            if (response.ok) {
                const text = await response.json()
                console.log("accounts", text);
                setAccounts(text)
            } else {
                console.error(`Failed to load accounts for user ${props.userId} - HTTP ${response.status}`)
                forceLogout()
                return
            }
        }
        getAccounts()

        async function getBrokerages() {
            if (props.userId === 1 || props.userId === 3) {
                const url = "/api/brokerages"
                const brokerages = await (await fetch(url)).json();
                setBrokerages(brokerages);
            }
        }
        getBrokerages();

        async function getBrokerageAccounts() {
            console.log("Loading brokerage accounts");
            const url = "/api/brokerageAccounts?userId=" + props.userId + "&brokerage=schwab"
            const brokerageAccounts = await (await fetch(url)).json();
            console.log("brokerageAccounts", brokerageAccounts);
            if (brokerageAccounts.success && brokerageAccounts.accounts)
                setBrokerageAccounts(brokerageAccounts.accounts);
        }
        getBrokerageAccounts();

    }, [props.userId, updates])

    const plans = getPlans()


    async function handleSubmit() {
        setResult(null);
        console.log(`Adding account for user ${props.userId} with planId ${planId}, balance ${currentBalance}, name ${name}`)
        if (parseInt(planId) > 0 && parseFloat(currentBalance) > 0 && name.length) {
            const url = "/api/addAccount?userId=" + props.userId + "&planId=" + planId + "&balance=" + currentBalance + "&name=" + name;
            console.log(url);

            const response = await fetch(url)
            let result = await response.json()
            setResult(result);
            setUpdates(updates + 1);
            setName('');
            setCurrentBalance('');
            setPlanId(undefined)
            if (props.onAccountsUpdated)
                props.onAccountsUpdated();
        }
    }

    function getPlans() {
        return subscriptions.map(plan => {
            return {
                name: plan.name,
                description: plan.description,
                planId: plan.planId,
                selected: planId === plan.planId,
                annual_profit_percentage: plan.annual_profit_percentage,
                weekly_trade_count: plan.annual_trade_count / 52,
                max_drawdown_percentage: plan.max_drawdown_percentage,
                average_weekly_drawdown: plan.average_weekly_drawdown,
                best_year: plan.best_year,
                worst_year: plan.worst_year,
                backtest_years: plan.backtest_years
            };
        })
    }

    async function onPlanSelected(planId) {
        console.log("onPlanSelected", planId);
        setPlanId(planId);

        const url = `/api/lastBacktestResults?planId=` + planId + "&liveTradesOnly=0";
        let text = await (await fetch(url)).json();

        const tempChartData = [];//[["Date", "Account Value", "S&P 500"]]
        text.dailyResults.forEach(day => tempChartData.push([new Date(day.date), day.total, day.baseline]))
        setChartData(tempChartData)
        setBacktestResults(text);
    }

    function selectedFormatter(cell, row) {
        return (
            <input type='checkbox' checked={row.selected} onClick={() => onPlanSelected(row.planId)} />
        );
    }

    async function updateAccountLinking(accountId, accountNumber) {
        console.log("updateAccountLinking", accountId, accountNumber);
        const url = `/api/brokerageLinking?brokerage=schwab&accountId=` + accountId + "&accountNumber=" + accountNumber;
        let result = await (await fetch(url, { method: 'post' })).json();
        setResult(result);
        setUpdates(updates + 1);
    }

    function brokerageFormatter(cell, row) {

        if (brokerageAccounts && brokerageAccounts.length) {
            let brokerageAccount = brokerageAccounts.find(act => act.accountNumber === row.brokerage_account);
            return (
                <Dropdown width="150" onSelect={(e) => updateAccountLinking(row.idaccounts, e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {row.brokerage && row.brokerage_account ? row.brokerage + " " + (brokerageAccount ? tableHelpers.moneyFormatterNoCents(brokerageAccount.value, null) : "N/A") : null}
                    </Dropdown.Toggle>

                    <Dropdown.Menu  >
                        <Dropdown.Item as='accountNumber' eventKey={null} value={null}>Do not Link</Dropdown.Item>
                        {brokerageAccounts.map(account => {
                            return (<Dropdown.Item as='accountNumber' eventKey={account.accountNumber} value={account.accountNumber}>{account.accountNumber} - {tableHelpers.moneyFormatterNoCents(account.value)}</Dropdown.Item>)
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            );
        } else {
            return row.brokerage;
        }
    }

    function getAccounts() {
        return accounts.map(a => {
            return {
                name: a.name,
                plan_name: a.plan_name,
                current_balance: a.current_balance,
                create_date: a.create_date,
                idaccounts: a.idaccounts,
                isOptions: a.is_options,
                brokerage: a.brokerage,
                brokerage_href: a.brokerage_href,
                brokerage_account: a.brokerage_account
            }
        })
    }

    async function onDeleteAccount(accountId) {
        console.log("onDeleteAccount", accountId);
        setResult(null);
        const url = "/api/accountDelete?userId=" + props.userId + "&accountId=" + accountId;
        let result = await (await fetch(url, { method: "post" })).json();
        setResult(result);
        setUpdates(updates + 1);
        if (props.onAccountsUpdated)
            props.onAccountsUpdated();
    }

    function deleteLinkFormatter(cell, row) {

        let accountId = row.idaccounts;
        return (
            <Link className="bg-transparent" onClick={() => onDeleteAccount(accountId)}>Delete</Link>
        )
    }

    async function updateStartDate(accountId, date) {
        console.log("Updating start date", accountId, date);
        const url = "/api/accountStartDate?accountId=" + accountId + "&startDate=" + date;
        console.log(url);
        await fetch(url, { method: "post" });
        setUpdates(updates + 1);
        if (props.onAccountsUpdated)
            props.onAccountsUpdated();
    }

    async function updateIsOptions(accountId, isOptions) {
        console.log("Updating isOptions", accountId, isOptions);
        const url = "/api/accountSettings?accountId=" + accountId + "&is_options=" + isOptions;
        console.log(url);
        let results = await ((await fetch(url, { method: "post" }))).json();
        setResult(results);
        setUpdates(updates + 1);
        if (props.onAccountsUpdated)
            props.onAccountsUpdated();
    }

    function startDateFormatter(cell, row) {

        let d = new Date(row.create_date);
        return (
            <DatePicker className="bg-transparent" selected={d} onChange={(date) => updateStartDate(row.idaccounts, date)} />
        )
    }

    function isOptionsFormatter(cell, row) {
        return <input type='checkbox' checked={cell} onClick={(e) => updateIsOptions(row.idaccounts, !cell)} />
    }

    function getRowClassName(row) {
        return "analytics_hidden"
    }

    return (
        <div>

            <h3>
                Current Accounts
            </h3>
            <RichTable className="table-striped table-hover table-condensed" rowClassName={getRowClassName} data={getAccounts()} mappers={[
                { title: 'Account', field: 'name' },
                { title: 'Plan', field: 'plan_name' },
                { title: 'Balance', field: 'current_balance', formatter: tableHelpers.moneyFormatterNoCents },
                { title: 'Start Date', field: 'create_date', formatter: startDateFormatter },
                { title: 'Brokerage', field: 'brokerage', formatter: brokerageFormatter },
                { title: 'Is Options', field: 'isOptions', formatter: isOptionsFormatter },
                { title: 'Manage', field: 'idaccounts', formatter: deleteLinkFormatter }
            ]} />

            <hr></hr>

            {brokerages.map(brokerage => {
                return (
                    <>
                        🛜 Login to <Link to={brokerage.linkHref + "&state=link"}>{brokerage.name}</Link>
                    </>
                )
            })}

            <hr></hr>
            <ActionResult result={result} />

            <h3>
                Add Account
            </h3>
            Adding an account will make it easier to manage multiple accounts. It will save the plan
            and current balance associated with each account and allow you to quickly switch between them via the
            dropdown menu at the bottom of the site.
            <table width="100%">
                <tbody>
                    <tr>
                        <td width="125" valign='top'>

                            <br></br>
                            Current Balance: <input type="text" size="10" placeholder="10000" value={currentBalance} onChange={(e) => setCurrentBalance(e.target.value)}></input>

                            <br></br>
                            <br></br>
                            Account Nickname: <input type="text" size="20" placeholder="Nickname" value={name} onChange={(e) => setName(e.target.value)}></input>

                            <br></br>
                            <br></br>
                            Select a plan:
                            <RichTable className="table-striped table-hover table-condensed" headless data={plans} mappers={[
                                { title: 'Selected', field: 'selected', formatter: selectedFormatter },
                                { title: 'Plan', field: 'name' }
                            ]} />

                            <br></br>
                            * Note - your account value will be stored
                            <br></br>
                            <button class="btn btn-secondary" onClick={(e) => handleSubmit()}>Add Account</button>

                        </td>
                        <td>
                            {
                                backtestResults && backtestResults.periodSummaries ?
                                    <div width="100%">
                                        <DailyBalanceChart defaultToYtd={false} defaultToSyncValues={false} backtestResults={backtestResults} chartData={chartData} hideAllocationChart={true} hideChartSummary={true}></DailyBalanceChart>
                                        <div width="75%">
                                            <center>
                                                <PlanScorecard backtestResults={backtestResults} />
                                            </center>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </td>
                    </tr>
                </tbody>
            </table>

        </div >);
}

export default AddAccount;