import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import TableHelpers from '../helpers/TableHelpers.js';
import Dropdown from 'react-bootstrap/Dropdown';

function StockCorrelation(props) {

    const [data, setData] = useState(null);
    const [days, setDays] = useState(20);

    useEffect(() => {
        (async function () {

            setData(null);

            if (props.symbols && Array.isArray(props.symbols) && props.symbols.length) {
                console.log("Getting data", props.symbols)
                let symbols = "";
                props.symbols.forEach(a => {
                    symbols += a + ",";
                })
                if (!props.symbols.find(a => a === "SPY")) {
                    symbols += "SPY";
                }
                const query = `/api/stockCorrelation?symbols=` + symbols +
                    "&days=" + days;
                let matrix = await (await fetch(query)).json();
                console.log("matrix", matrix);
                setData(matrix);
            }
        })();
    }, [props.symbols, days]);

    function getCorrelationMatrix() {

        if (data && data.matrix) {

            let correlationData = data.matrix;

            let keys = Object.keys(correlationData);
            let rows = [];
            let columns = [];

            columns.push({ title: "Symbol", field: 'symbol' })
            keys.forEach(key => {
                columns.push({ title: key, field: key, formatter: TableHelpers.percentFormatterNoDecimals })

                let row = {};
                row.symbol = key
                keys.forEach(subKey => {
                    if (key === subKey)
                        row[subKey] = undefined;
                    else
                        row[subKey] = correlationData[key][subKey];
                })
                rows.push(row)
            })

            return {
                rows: rows,
                columns: columns
            }
        } else {
            return null;
        }
    }

    function getAggregateSpyCorrelation() {
        if (data && data.matrix && data.matrix["SPY"] && props.allocations && props.allocations.length) {
            let total = 0;
            let correlationData = data.matrix;

            props.allocations.forEach(a => {
                let correlation = correlationData["SPY"][a.symbol];
                if (correlation) {
                    let multiplier = 1;
                    // TODO! remove this
                    if (a.symbol === 'TQQQ' || a.symbol === "SPXL" || a.symbol === "UGL" || a.symbol === "SPXU" || a.symbol === "SQQQ")
                        multiplier = 3;
                    total += correlation * a.allocation / 100 * multiplier;
                }
            })
            return total;
        } else {
            return undefined;
        }
    }

    let matrix = getCorrelationMatrix();
    let netCorrelation = getAggregateSpyCorrelation();

    return (
        <div>
            {props.showSpyCorrelation && netCorrelation !== undefined ?
                <div style={{ padding: '10px' }}>
                    <h4>Net to S&P 500: {getAggregateSpyCorrelation().toFixed(1)}%</h4>
                </div>
                :
                null
            }

            {matrix ?

                <div class="smallest-text">
                    <RichTable data={matrix.rows} className="table-striped table-hover table-condensed fixed" mappers={matrix.columns} />
                </div>
                :
                null}

            <center>
                <Dropdown width="150" onSelect={(e) => setDays(Number(e))}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {days} Days
                    </Dropdown.Toggle>

                    <Dropdown.Menu  >
                        <Dropdown.Item eventKey={5} value={5}>5</Dropdown.Item>
                        <Dropdown.Item eventKey={10} value={10}>10</Dropdown.Item>
                        <Dropdown.Item eventKey={20} value={20}>20</Dropdown.Item>
                        <Dropdown.Item eventKey={50} value={50}>50</Dropdown.Item>
                        <Dropdown.Item eventKey={200} value={200}>200</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </center>
        </div >

    );
}

export default StockCorrelation;