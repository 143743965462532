import React, { useState, useEffect } from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import tableHelpers from '../helpers/TableHelpers.js'
import RichTable from '../components/richTable/RichTable.jsx'
import TwoStepConfirmationButton from '../components/TwoStepConfirmButton.js'
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '../components/Tooltip.js'

function AutomateTrades(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [brokerageAccount, setBrokerageAccount] = useState(null);
    const [planPositions, setPlanPosiitons] = useState(null);
    const [tradeResults, setTradeResults] = useState(null);
    const [instructions, setInstructions] = useState([]);
    const [marketOrders, setMarketOrders] = useState(false);
    const [shouldAllocateCash, setShouldAllocateCash] = useState(false);
    const [updates, setUpdates] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isPlacingOrders, setIsPlacingOrders] = useState(false);

    useEffect(() => {
        (async function () {
            setBrokerageAccount(null);
            setIsLoading(true);
            const url = "/api/brokerageAccounts?userId=" + props.userId + "&brokerage=schwab"
            const brokerageAccounts = await (await fetch(url)).json();
            console.log("brokerageAccounts", brokerageAccounts);
            if (brokerageAccounts.success && brokerageAccounts.accounts) {
                let brokerageAccount = brokerageAccounts.accounts.find(act => act.accountId === props.accountId);
                if (brokerageAccount) {
                    console.log("Found brokerage account", brokerageAccount);
                    setBrokerageAccount(brokerageAccount);

                    console.log("Loading positions for " + brokerageAccount.planId);
                    let positions = await (await fetch(`/api/positions?planId=` + brokerageAccount.planId)).json();
                    if (positions) {
                        setPlanPosiitons(positions);
                    }
                } else
                    setBrokerageAccount(null);
            }
            setIsLoading(false);

        })();
    }, [props.userId, props.accountId, updates]);

    useEffect(() => {
        (async function () {
            if (props.isOpen)
                setModalOpen(true);
        })();
    }, [props.isOpen]);

    const handleCloseModal = () => {
        setModalOpen(false);
        setTradeResults(null);
        setInstructions([]);
        if (props.onClose)
            props.onClose();
    }

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: props.mobileView ? 425 : 475,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 2,
    };

    function updateInstruction(symbol, shares) {
        let numShares = parseInt(shares);
        console.log("Updating " + symbol + " to " + shares, numShares)
        if (numShares === null || numShares === 0 || isNaN(numShares)) {
            console.log("removing", symbol, shares);
            let temp = JSON.parse(JSON.stringify(instructions));
            temp = temp.filter(ins => ins.symbol !== symbol);
            console.log(temp);
            setInstructions(temp);
        } else {
            let temp = JSON.parse(JSON.stringify(instructions));
            let instruction = temp.find(ins => ins.symbol === symbol);
            if (instruction) {
                instruction.shares = numShares;
            } else {
                temp.push({
                    symbol: symbol,
                    shares: numShares
                })
            }
            console.log(temp);
            setInstructions(temp);
        }
    }

    function instructionFormatter(cell, row) {
        if (row.securityType === "EQUITY") {
            let instruction = instructions.find(i => i.symbol === row.symbol);
            let instructionShares = instruction ? Number(instruction.shares) : 0;
            return <div className='sameline'>
                <input size={5} style={{ "height": 16 }} value={instructionShares ? instructionShares : ""} onChange={(e) => updateInstruction(row.symbol, e.target.value)} /> <Link onClick={() => setSymbolInstructionsForRebalacne(row.symbol)}>❓</Link> <Link onClick={() => setSymbolInstructionsForLiquidate(row.symbol)}>⏹️</Link>
            </div>
        } else return null;
    }

    function symbolFormatter(cell, row) {
        if (row.openAmount === 0) {
            return cell;
        } else {
            return (
                <>
                    {cell}<Tooltip text={" ✳️"}>
                        Open Shares: {row.openAmount}
                        <br></br>
                        Limit Price: {row.openPrice}
                        <br></br>
                        Stop Price: {row.openStopPrice}
                    </Tooltip>
                </>
            );
        }
    }

    async function onPlaceTrades() {
        setTradeResults(null);
        setIsPlacingOrders(true);

        const url = "/api/brokerageTrades?brokerage=schwab&userId=" + props.userId + "&accountId=" + props.accountId + "&marketOrders=" + marketOrders + "&shouldAllocateCash=" + shouldAllocateCash
        let tradesPayload = instructions;
        let result = await (await fetch(url, { method: 'post', body: JSON.stringify(tradesPayload) })).json();

        setIsPlacingOrders(false);
        setInstructions([]);
        setTradeResults(result);
        setUpdates(updates + 1);
        console.log(result);
    }

    async function setInstructionsFromPropTrades() {
        if (props.trades) {
            console.log("Setting instructions from trades")
            let newInstructions = [];
            props.trades.forEach(trade => {
                newInstructions.push({
                    symbol: trade.symbol,
                    shares: trade.shares
                })
            })
            setInstructions(newInstructions);
        }
    }

    async function setInstructionsForLiquidate() {
        let positions = getPositions();
        let temp = [];
        positions.forEach(pos => {
            if (pos.securityType === "EQUITY" && pos.shares) {
                temp.push({
                    symbol: pos.symbol,
                    shares: pos.shares * -1
                })
            }
        })
        setInstructions(temp);
    }

    async function setInstructionsForRebalance() {
        let positions = getPositions();
        let temp = [];
        let brokerageValue = brokerageAccount ? brokerageAccount.value : 0;
        console.log("planPositions", planPositions);
        positions.forEach(pos => {
            if (pos.securityType === "EQUITY") {
                let planPosition = planPositions ? planPositions.find(pp => pp.netSymbol === pos.symbol) : null;
                if (planPosition) {
                    let shares = Math.round((brokerageValue * (planPosition.netPosition / 100) / pos.price) - pos.shares);
                    if (shares) {
                        temp.push({
                            symbol: pos.symbol,
                            shares: shares
                        })
                    }
                } else {
                    temp.push({
                        symbol: pos.symbol,
                        shares: pos.shares * -1
                    })
                }
            }
        })
        console.log(temp);
        setInstructions(temp);
    }

    async function setSymbolInstructionsForRebalacne(symbol) {
        let positions = getPositions();
        let temp = JSON.parse(JSON.stringify(instructions.filter(ins => ins.symbol !== symbol)));
        let brokerageValue = brokerageAccount ? brokerageAccount.value : 0;
        positions.forEach(pos => {
            if (pos.symbol === symbol) {
                let planPosition = planPositions ? planPositions.find(pp => pp.netSymbol === pos.symbol) : null;
                if (planPosition) {
                    let shares = Math.round((brokerageValue * (planPosition.netPosition / 100) / pos.price) - pos.shares);
                    console.log("shares", shares, brokerageValue, pos);
                    if (shares) {
                        temp.push({
                            symbol: pos.symbol,
                            shares: shares
                        })
                    }
                } else {
                    temp.push({
                        symbol: pos.symbol,
                        shares: pos.shares * -1
                    })
                }
            }
        })
        setInstructions(temp);
    }

    function setSymbolInstructionsForLiquidate(symbol) {
        let positions = getPositions();
        let temp = JSON.parse(JSON.stringify(instructions.filter(ins => ins.symbol !== symbol)));
        positions.forEach(pos => {
            if (pos.symbol === symbol && pos.shares) {
                temp.push({
                    symbol: pos.symbol,
                    shares: pos.shares * -1
                })
            }
        })
        setInstructions(temp);
    }

    function getPositions() {
        if (brokerageAccount) {
            let all = [];
            brokerageAccount.positions.forEach(position => {
                let planPosition = planPositions ? planPositions.find(pp => pp.netSymbol === position.symbol) : null;
                let instruction = instructions.find(i => i.symbol === position.symbol);
                let instructionShares = instruction ? Number(instruction.shares) : 0;
                let openOrder = brokerageAccount.openOrders ? brokerageAccount.openOrders.find(order => order.symbol === position.symbol) : null;
                all.push({
                    symbol: position.symbol,
                    shares: position.shares,
                    planPercent: planPosition ? planPosition.netPosition : undefined,
                    percent: position.marketValue / brokerageAccount.value * 100,
                    instructions: instructionShares,
                    price: position.marketValue / position.shares,
                    resultPercent: (position.marketValue / position.shares) * (position.shares + instructionShares) / brokerageAccount.value * 100,
                    securityType: position.securityType,
                    openAmount: openOrder ? openOrder.shares : 0,
                    openPrice: openOrder ? openOrder.price : 0,
                    openStopPrice: openOrder ? openOrder.stopPrice : 0
                });
            })
            planPositions && planPositions.forEach(pos => {
                if (!all.find(p => p.symbol === pos.netSymbol)) {
                    let trade = props.trades ? props.trades.find(t => t.symbol === pos.netSymbol) : undefined;
                    let instruction = instructions.find(i => i.symbol === pos.netSymbol);
                    let instructionShares = instruction ? Number(instruction.shares) : 0;
                    all.push({
                        symbol: pos.netSymbol,
                        shares: 0,
                        planPercent: pos.netPosition,
                        percent: 0,
                        price: pos.currentPrice,
                        instructions: trade ? trade.shares : null,
                        resultPercent: pos.currentPrice * instructionShares / brokerageAccount.value * 100,
                        securityType: "EQUITY",
                        openAmount: 0,
                        openPrice: 0,
                        openStopPrice: 0
                    });
                }
            })
            return all;
        } else {
            return [];
        }
    }

    return (
        <>
            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={handleCloseModal}
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Automate Trades
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {!isLoading ?
                            <>
                                <RichTable data={getPositions()} className="table-striped table-hover table-condensed"
                                    mappers={[
                                        { title: `Symbol`, field: 'symbol', formatter: symbolFormatter, isDefaultSort: true },
                                        { title: `Schwab Shares`, field: 'shares', formatter: tableHelpers.intFormatter, hidden: props.mobileView, width: 30 },
                                        { title: `Schwab %`, field: 'percent', formatter: tableHelpers.percentFormatterOneDecimal, width: 30 },
                                        { title: 'Plan %', field: 'planPercent', formatter: tableHelpers.percentFormatterNoDecimals, width: 30 },
                                        { title: `Trade`, field: 'instruction', formatter: instructionFormatter },
                                        { title: `Result %`, field: 'resultPercent', formatter: tableHelpers.percentFormatterOneDecimal, width: 30 },
                                    ]}
                                />
                                <Link onClick={() => setInstructionsFromPropTrades()}>Todays Trades</Link> | <Link onClick={() => setInstructionsForRebalance()}>Rebalance</Link> | <Link onClick={() => setInstructionsForLiquidate()}>Liquidate</Link> | <Link onClick={() => setInstructions([])}>Clear All</Link>
                                <br></br>
                                <br></br>
                                Account Value: {brokerageAccount ? tableHelpers.moneyFormatterNoCents(brokerageAccount.value, null) : null}
                                <br></br>
                                Cash: {brokerageAccount ? tableHelpers.moneyFormatterNoCents(brokerageAccount.cash, null) : null}
                                <br></br>
                                ✳️ Open Orders: {brokerageAccount && brokerageAccount.openOrders ? brokerageAccount.openOrders.length : null}

                                <div style={{ textAlign: 'right' }}>
                                    {instructions.length ?
                                        <TwoStepConfirmationButton id={0} text="Place Trades" onConfirmed={onPlaceTrades} >
                                            <ul>
                                                {instructions.map(ins => {
                                                    return <li>{(ins.shares >= 0 ? "BUY " : "SELL ") + Math.abs(ins.shares) + " of " + ins.symbol}</li>
                                                })}
                                            </ul>
                                            <input type="checkbox" checked={marketOrders} onClick={() => setMarketOrders(!marketOrders)} /> Market day orders
                                            <br></br>
                                            <input type="checkbox" checked={shouldAllocateCash} onClick={() => setShouldAllocateCash(!shouldAllocateCash)} /> Allocate CASH to SGOV
                                            <br></br>
                                        </TwoStepConfirmationButton>
                                        :
                                        null}
                                </div>
                                <br></br>
                                <div style={{ textAlign: 'right' }}>
                                    <button class="btn btn-secondary" onClick={handleCloseModal} type="button">Cancel</button>
                                </div>
                                <br></br>
                                <Link onClick={() => setUpdates(updates + 1)}>Reload</Link>
                            </>
                            :
                            <CircularProgress />}
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <b>
                            {isPlacingOrders ? <><br></br><LinearProgress /></> : null}
                            {tradeResults ? (tradeResults.error ? tradeResults.error : tradeResults.orders.length + " orders placed") : null}
                        </b>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}

export default AutomateTrades;