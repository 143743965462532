import React, { useState } from 'react';
import { Chart } from "react-google-charts";
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'


function MonthlyBacktestResults(props) {

    const [monthlyView, setMonthlyView] = useState("Profit");

    let backtestResults = props.backtestResults;

    function getMonthlyValue(year, month) {
        if (monthlyView === "Profit")
            return backtestResults.periodSummaries.profitByMonth[year][month] ? backtestResults.periodSummaries.profitByMonth[year][month].toFixed(props.mobileView ? 0 : 2) : "0"
        else if (monthlyView === "SPY")
            return backtestResults.periodSummaries.spyProfitByMonth[year][month] ? backtestResults.periodSummaries.spyProfitByMonth[year][month].toFixed(props.mobileView ? 0 : 2) : "0"
        else if (monthlyView === "Diff")
            return backtestResults.periodSummaries.profitByMonth[year][month] ? (backtestResults.periodSummaries.profitByMonth[year][month] - backtestResults.periodSummaries.spyProfitByMonth[year][month]).toFixed(props.mobileView ? 0 : 2) : "0"
        else if (monthlyView === "WinRate")
            return backtestResults.periodSummaries.tradeCountByMonth[year][month] ? (backtestResults.periodSummaries.winningTradeCountByMonth[year][month] / backtestResults.periodSummaries.tradeCountByMonth[year][month] * 100).toFixed(0) : "0"
        else if (monthlyView === "TradeCount")
            return backtestResults.periodSummaries.tradeCountByMonth[year][month] ? backtestResults.periodSummaries.tradeCountByMonth[year][month].toFixed(0) : "0"
        else if (monthlyView === "MaxDrawdown")
            return backtestResults.periodSummaries.maxDrawdownByMonth[year][month] ? backtestResults.periodSummaries.maxDrawdownByMonth[year][month].toFixed(1) : "0"

        else
            return "0"
    }

    function renderYearRow(year) {
        if (props.currentYearOnly === true && Number(year) !== new Date().getFullYear()) {
            return null;
        } else {
            let total = 1.0;
            let count = 0;
            for (let i = 0; i < 12; i++) {
                total = (total * (1.0 + getMonthlyValue(year, i.toString()) / 100));
                if (Number(getMonthlyValue(year, i.toString())) !== 0) ++count;
            }
            total = (total - 1.0) * 100;
            return (
                <tr>
                    <td className="bold">{year}</td>
                    <td>{getMonthlyValue(year, '0')}</td>
                    <td>{getMonthlyValue(year, '1')}</td>
                    <td>{getMonthlyValue(year, '2')}</td>
                    <td>{getMonthlyValue(year, '3')}</td>
                    <td>{getMonthlyValue(year, '4')}</td>
                    <td>{getMonthlyValue(year, '5')}</td>
                    <td>{getMonthlyValue(year, '6')}</td>
                    <td>{getMonthlyValue(year, '7')}</td>
                    <td>{getMonthlyValue(year, '8')}</td>
                    <td>{getMonthlyValue(year, '9')}</td>
                    <td>{getMonthlyValue(year, '10')}</td>
                    <td>{getMonthlyValue(year, '11')}</td>
                    <td>{(total / count).toFixed(props.mobileView ? 0 : 1)}%</td>
                    <td>{total.toFixed(props.mobileView ? 0 : 1)}%</td>
                </tr>
            );
        }
    }


    function getMonthlyResultsBreakdown(backtestResults) {

        let chartData = [];
        chartData.push(['Profit', 'Count', { role: "style" }]);

        if (backtestResults === undefined || backtestResults.periodSummaries === undefined || backtestResults.periodSummaries.profitByMonth === undefined)
            return chartData;

        let distribution = {};
        for (let i = -10; i <= 10; i++) {
            distribution[i] = 0;
        }

        Object.keys(backtestResults.periodSummaries.profitByMonth).forEach(year => {
            for (let month = 0; month < 12; month++) {
                let profit = 0;
                if (monthlyView === "Diff")
                    profit = Math.floor(backtestResults.periodSummaries.profitByMonth[year][month] - backtestResults.periodSummaries.spyProfitByMonth[year][month])
                else if (monthlyView === "SPY")
                    profit = Math.floor(backtestResults.periodSummaries.spyProfitByMonth[year][month]);
                else if (monthlyView === "WinRate")
                    profit = Math.floor(backtestResults.periodSummaries.winningTradeCountByMonth[year][month] / backtestResults.periodSummaries.tradeCountByMonth[year][month] * 100);
                else if (monthlyView === "TradeCount")
                    profit = Math.floor(backtestResults.periodSummaries.tradeCountByMonth[year][month]);
                else
                    profit = Math.floor(backtestResults.periodSummaries.profitByMonth[year][month]);

                if (profit < -10) profit = -10;
                if (profit > 10) profit = 10;

                distribution[profit] = distribution[profit] + 1;
            }
        })

        for (let i = -10; i <= 10; i++) {
            const color = i >= 0 ? "#B2D8B2" : "#B85D5D";
            chartData.push([i.toFixed(0) + "%", distribution[i], color]);
        }

        return chartData;
    }


    return (
        < div >
            {props.hideOptions === true ? null :
                <>
                    <Link id="profit" className="menu-item" onClick={() => setMonthlyView("Profit")}>Profit</Link>&nbsp;
                    <Link id="SPY" className="menu-item" onClick={() => setMonthlyView("SPY")}>SPY</Link>&nbsp;
                    <Link id="diff" className="menu-item" onClick={() => setMonthlyView("Diff")}>Diff</Link>&nbsp;
                    <Link id="MaxDrawdown" className="menu-item" onClick={() => setMonthlyView("MaxDrawdown")}>Max Drawdown</Link>&nbsp;
                    <Link id="WinRate" className="menu-item" onClick={() => setMonthlyView("WinRate")}>Win %</Link>&nbsp;
                    <Link id="TradeCount" className="menu-item" onClick={() => setMonthlyView("TradeCount")}>Trade Count</Link>
                </>
            }
            <Table className='monthly-table'>
                <tr>
                    <td className="bold">Yr</td>
                    <td className="bold">Ja</td>
                    <td className="bold">Fe</td>
                    <td className="bold">Ma</td>
                    <td className="bold">Ap</td>
                    <td className="bold">Ma</td>
                    <td className="bold">Ju</td>
                    <td className="bold">Ju</td>
                    <td className="bold">Au</td>
                    <td className="bold">Se</td>
                    <td className="bold">Oc</td>
                    <td className="bold">No</td>
                    <td className="bold">De</td>
                    <td className="bold">Avg</td>
                    <td className="bold">Tot</td>
                </tr>
                {backtestResults.periodSummaries && backtestResults.periodSummaries.profitByMonth ? Object.keys(backtestResults.periodSummaries.profitByMonth).map(year => {
                    return renderYearRow(year);
                }) : null}
            </Table>

            {props.hideChart === true ? null :
                <center>
                    <Chart
                        chartType="ColumnChart"
                        data={getMonthlyResultsBreakdown(props.backtestResults)}
                        width={"75%"}
                        options={{ legend: { position: 'none' }, title: "Monthly Count" }}
                        chartPackages={["corechart", "controls"]}
                    />
                </center>
            }


        </div>
    );
}

export default MonthlyBacktestResults;