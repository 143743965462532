import React from 'react';
import { Link } from 'react-router-dom'
import Collapsible from 'react-collapsible'

function HelpFAQ(props) {

    function reload() {
        window.location.reload();
    }

    return (
        <>
            <div>
                <div style={{ width: "75%", padding: "20px" }}>

                    <h3>Overview</h3>
                    <ol>
                        <li>Trade alerts only occur at market close and are intended to be placed in the extended hours session for that day.</li>
                        <li>These plans are based on an active trading strategy, which may trigger new trade alerts every day. It is crucial to consistently place the trades each day you are alerted.</li>
                        <li>Start by allocating a specific amount of money to the plan and fully commit. Mixing discretionary trading with algorithmic trading will likely lead to unpredictable results.</li>
                        <li>This is a long-term strategy, and you can expect to see fluctuating performance over each day, week, and month. It’s important to manage your emotions about short-term performance by not getting too discouraged by drawdowns or too excited by run-ups.</li>
                        <li><Link onClick={() => reload()}>Update app</Link> to make sure you have the latest version</li>
                        <li>Read the FAQ below for more information</li>
                    </ol>

                    <h3>FAQ</h3>

                    <Collapsible className="bold" trigger="+ Can you give me an overview of the site?" open={false}>
                        <br></br>
                        When you login, you will see that plan that you’re assigned to by default. All links on the main menu will take
                        you to more information about that particular plan. You can change your plan by going to “Change Plan” and
                        then select a new plan. When you do that, all menu links will take you to more information about THAT plan.
                        <br></br>
                        <br></br>
                        Explore all of the links in the "Your Plan" section.
                        Check out past performance, review the currently active trades and trades that have closed out, and really spend some time going through the most recent backtest--drill down into each year and get a feel for how the plan performs against the S&P 500 and pay special attention to the volatility of the plan to make sure its a good fit for you.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ What's a Plan?" open={false}>
                        <br></br>
                        A Plan is a collection of trading algorithms that trade different stocks. Each Plan is created with a different purpose in mind,
                        whether it's to maximize gains, minimize drawdowns and losses, or striking a balance between the two.
                        Plans will likely share algorithms with each other, but they all act indepently of each other. You can review some of the different plans
                        on the Change Plan page to see their past performance and risks and pick the one(s) that are right for you.
                    </Collapsible>
                    <br></br>


                    <Collapsible className="bold" trigger="+ How do I know when I’m supposed to place a trade?" open={false}>
                        <br></br>
                        You’ll receive an email with the subject “Trade alerts” from DoNotReply@mail.stockpiler.net. It’s best to add a notification
                        on your phone so that you get alerted immediately when you get the email.
                        The email should only come after the stock market closes at 4pm EST. If you don’t receive an email, it wouldn’t
                        hurt to login to the site and go to “Trade History” and see if there are any for today.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ How do I know what trades to place?" open={false}>
                        <br></br>
                        Login to the site and go to “Today's Trades”. This will show you which stocks and how much to buy. When it says
                        to buy 5% of a stock–that means you should be 5% of your total account balance worth of shares. E.g. if you
                        have an account with $10,000 in it, you should buy 5% of $10,0000, or $500 worth of that stock.
                        You can also type in your total account value in the input box at the bottom of the screen and this will then fill in the
                        Shares column and show you exactly how many shares to buy or sell.
                        <br></br>
                        On Sell transactions, you'll need to use a little bit of judgement to determine how many shares to sell. For example, if you're holding 5.3% of a position in QQQ and just have a single QQQ Active Trade, and you get instructions to sell 5%,
                        the intention is to close out that position completely and sell all 5.3%. No need to do any mental gymnastics on Buy transactions.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ Can I skip trades that I don't like?" open={false}>
                        <br></br>
                        You can absolutely do whatever you feel comfortable with, but you really need to ask yourself what the long
                        term probability is of you making the right decisions. The algorithms usually have a success rate of over 70%. You might
                        be able to skip a bad trade here and there, but you're probably just as likely to skip a good trade. Skipping a handful of really good trades
                        per year can really have negative impact on your overall performance.
                        <br></br>
                        All that being said, I will personally never skip trades. I know that my decision to skip a trade would be made mostly by emotion and
                        that is a recipe for disaster.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ Why do I have to do extended hours orders?" open={false}>
                        <br></br>
                        All of the algorithms are backtested using this approach, and this approach has worked significantly better than
                        executing orders when the market opens the next day. Your goal is to execute your order as close to market
                        close as possible after getting the alerts, so that you can get as close to the same price as possible that the
                        algorithms expect you to.
                        <br></br>
                        There will be days when it’s just not feasible, and that’s fine. Just do it at your earliest convenience, but really
                        try hard not to make that a habit.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ How do I place an extended hours order?" open={false}>
                        <br></br>
                        Every brokerage is a little bit different, but in general these steps should apply to all:
                        <br></br>
                        Go to the stock/ETF trading screen. Select Order Type of Limit. Enter a limit price--it will probably prepopulate a good limit price for you, otherwise check the bid/ask and enter a price between them. Select Time-in-force of Extended Hours PM.
                        <br></br>
                        Always go to Order Status afterwards and make sure your order filled. If it didn't, you can either wait a minute or adjust your limit price until you get a fill.
                    </Collapsible>
                    <br></br>


                    <Collapsible className="bold" trigger="+ When I place an order, I’d like to know more about why I’m doing it and its potential outcome." open={false}>
                        <br></br>
                        Go to the “Active Trades” page. Here you can see why (ie. which algorithm) each of the buy orders were
                        placed, and how those algorithms have performed historically.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ What are these algorithms and how do they make their decisions?" open={false}>
                        <br></br>
                        These algorithms are all based on recent price activity of the stock. They analyze candlestick charts (either
                        daily or weekly bars) and leverage calculations based on those bars. Most of the calculations are all widely
                        used technical indicators such as RSI (Relative Strength Indicator), MACD, moving averages, IBS, etc. Every
                        stock has different values that they tend to react to for each of these indicators, and the indicators might each
                        have different configurations as well.
                        <br></br>
                        The system uses a combination of trend following algorithms and mean-reversion algorithms. A trend following
                        algorithm will try to take advantage of sustained upward (or downward) moves in stocks. The goal is to
                        recognize a trend, hop on, and ride it until that trend shows signs of slowing down or exhaustion. A
                        mean-reversion algorithm will try to take advantage of the smaller peaks and valleys of a stocks price
                        movement. These algorithms typically buy when a stock is down because stocks very often will return back to
                        their value point (ie mean).
                        <br></br>
                        The algorithms purposefully ignores the latest news headlines, geo-political events, upcoming market catalysts (e.g. Fed announcements, earnings, etc), upgrades/downgrades/price targets
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ How do I know if I own the correct stocks that I’m supposed to own?" open={false}>
                        <br></br>
                        Go to the Positions page. You’ll see what percentage of your total account value should be in each stock.
                        Compare this to your actual investment account and reconcile it against what you’re supposed to own.
                        Don’t be too worried about a small amount of drift in the percentages that you own. It’s natural for them to not
                        match by a percent or two.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ How do I track the performance of the Plan?" open={false}>
                        <br></br>
                        1. Go to the Detailed Performance page to see a detailed breakdown of how the plan is performing. This will show you your P/L each day, and a graph down below for a historical
                        view. By default, the Detailed Performance page will track then Plans performance since the Plan began trading live. If you would like to see how your plan has been performing since you began trading it:
                        <br></br>
                        <ul>
                            <li>Go to “Select Accounts” at the bottom of the page and select “ Manage Accounts”. Here you can add your account ( or multiple accounts), their balance, a nickname, select the Plan the account is following and the date you started trading that plan.
                                Now when you’re on the Detailed Performance page the graph will start from the date you entered when adding the account for the account selected in the “Select Accounts" dropdown at the bottom of the page.
                            </li>
                        </ul>
                        Note-the starting account value in the detailed performance page is not based on the value you entered, currently it always defaults to 100k starting value.

                        <br></br><br></br>2. You can also go to the Active Trades page and view the current P/L of your open trades.
                        <br></br><br></br>3. You can also go to Closed trades and see the realized P/L of all closed trades.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ Why does the Detailed Performance Page not match the Backtest Pages when looking at the same time period?" open={false}>
                        <br></br><br></br>
                        Periodically, to improve the Plans on a go forward basis, we make slight adjustments to the algorithms within the plans, when this happens the Backtest Data reflects the updated Plan configuration.  The Detailed Performance Page will not change because it only reflects the actual trades that have been placed by the plans.  To better understand, we’ve provided more details as to what data is represented by each page.
                        <br></br><br></br>
                        <b>Detailed Performance Page:</b> Displays actual trades triggered by the plans as of November 2nd, 2023.  This data is not hypothetical, it’s based on the actual trades triggered by the plans with real profit and loss for people following the plans. If the plans change, the Detailed Performance page Never changes it's data retroactively when Plans are improved, the trades are the trades, you can’t change your profit and loss.  We hope that by improving the Plans, the trades on a go forward basis continue to improve the Plans actual profit and loss.
                        <br></br><br></br>
                        <b>Backtest Pages:</b> Displays the backtest data based on how the Plans are configured today.  Over time, we try to improve the Plans by adding/removing algos from the plans or adjusting the percent of the account value that is allocated towards the algo’s in the plan(s).  As these changes are implemented we will communicate them via the Discord channel to you so you can see how it improves the Plan performance.
                        <br></br><br></br>
                        Note: for any start date on the Detailed Performance Page, it assumes that you entered into the current positions that were on at that time. Reminder, you do NOT have to start following the plans by putting on all existing positions.  There is no way to know if this is the best thing to do, you are more them welcome to gradually add positions as they are triggered by the plan.
                    </Collapsible>
                    <br></br>


                    <Collapsible className="bold" trigger="+ I lost money today, I thought the system wasn’t supposed to lose money?" open={false}>
                        <br></br>
                        Don’t be too worried about day to day performance. These strategies are designed to perform well over
                        multiple months and years, but still might only be profitable 50-60% of days. Remember, there are over 2000
                        days of historical performance that they’re tested against, and it lost money on a lot of those days.
                        Typically, down days are opportunities for algorithms to kick in that buy stocks at lower prices.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ Can I see how this strategy has performed in the past?" open={false}>
                        <br></br>
                        Yes, go to the Most Recent Backtest page to view the most recently ran backtest of greater than 5 years. You
                        can also run your own backtest by going to Run Backtest. You can modify that starting dollar amount and
                        start/end dates.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ Am I going to get rich quick?" open={false}>
                        <br></br><br></br>
                        This is a long-term strategy, and you can expect to see fluctuating performance over each day, week, and month. It’s important to manage your emotions about short-term performance by not getting too discouraged by drawdowns or too excited by run-ups.  It’s easy to get excited when you see the backtest data and imagine what could be but remember, there are no guarantees the Plans will continue to perform that way in the future.  Also, you never know if you are in a shorter term period where the Plans have a significant drawdown or have great performance.  Stay disciplined, trade with what you’re comfortable trading/losing and keep the big picture in mind.
                        <br></br><br></br>
                        Use the tools on the site and ask questions in Discord to help gain confidence and trust in the system.  One thing that has kept me grounded is to look at the Plans win % on a daily, weekly, monthly basis etcetera on the “Most Recent Backtest” page.  On a daily basis, the Plans perform slightly better than the S&P but compound this out performance up over weeks, month’s, quarter’s and years and that’s when you begin to see the value in following the Plans.                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ Will this plan continue to perform how it has in the past?" open={false}>
                        <br></br>
                        Only time will tell since this is a very new system. The performance will be reviewed each quarter to review
                        how the algorithms are performing against how they've expected to perform. If algorithms aren't performing well
                        then they will be adjusted or removed the plan and replaced with something else. Each algorithm might go through hot and
                        cold spells, so these will be very careful decisions.
                        <br></br>
                        But you should be reassured to know that you’re trading a strategy with a proven history of success.
                        Remember in the stock market, that’s the best you can do, and most traders don’t have that, which gives you
                        an edge over them.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ What's the risk of things just going haywire and me losing a lot of money?" open={false}>
                        <br></br>
                        You really should spend time reviewing the Most Recent Backtest, specifically diving down into each
                        year and paying attention to the peaks and valleys. Hopefully you'll agree that each and every Plan
                        recovers quickly from any big losses, and gets back on track of outperfoming the S&P 500.
                        <br></br>
                        Obviously there are no guarantees in the stock market, but algorithms and Plans will never be put into the system where there
                        is deemed a high risk of major sustained losses. The creation of this system started with how to best manage risk and
                        everything revolves around those key principles.
                        <br></br>
                        Another thing to keep in mind is that you can always just stop trading at any time. You are in complete control.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ What about Black Swan events?" open={false}>
                        <br></br>
                        Those are absolutely the hardest thing for any trading strategy to predict and protect against. When you're reviewing the
                        backtest results, pay special attention to early 2020 and late 2018 to see if you're happy with how the Plan
                        handled those massive drops in the market.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ I’m not ready to trade, can I just observe for a little bit?" open={false}>
                        <br></br>
                        Great idea, do that. Login daily and check out the Active Trades, Daily Snapshot, and Positions. That will really
                        give you a feel for how actively and aggressively the system trades to make sure it’s a fit for you.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ I’m ready to start trading, what should I do?" open={false}>
                        <br></br>
                        You have a few options, and you'll have to decide which you think is best for you. If you haven’t already, enter your account value at the bottom of the page so the system automatically calculates the number of shares you’ll need to buy/sell.
                        <br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;Pro tip, by clicking “Manage Accounts” in the Select Account dropdown at the bottom of the page, you can add your account so that your account value will be saved.  Adding accounts is especially helpful when managing multiple accounts.
                        Don’t forget to update your account value periodically, this is incredibly important if you want your account performance 		  to match the Plan Performance in terms of dollars and cents.
                        <br></br>
                        1. You could go to the positions page and buy those stocks so that you start with exactly where the Plan is currently at.
                        <br></br>
                        2. You could go to the Positions page and buy some of those shares
                        each day so that you're dollar cost averaging into the positions (but still copy any new trade instructions that come in).
                        <br></br>
                        3. Or you could just start with no positions and just follow new trades.
                        <br></br>
                        <br></br>

                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ Is there a minimum amount of money that's required?" open={false}>
                        <br></br>
                        Not really, but it defintely gets a little more difficult on small accounts to achieve the same gains as a larger account. A small account will lead to a little more
                        dillution of the performance due to rounding down when calculating how many shares to buy. For example, if you round down
                        to 4 shares from 4.4, that's a lot more impactful long-term than rounding 50.4 down to 50.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ Won't I be paying a lot of money in commissions trading this often?" open={false}>
                        <br></br>
                        If your brokerage is charging you commissions, then you should open an account somewhere else for this. Most discount brokerages don't charge commissions on the securities the Plans will be trading.                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ Should I reinvest dividends?" open={false}>
                        <br></br>
                        Nope, take the cash payout. The plan will naturally reinvest them for you when appropriate.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ Can a friend of mine use this?" open={false}>
                        <br></br>
                        No, DO NOT share your login information with anybody else. If you do your login will be disabled.
                    </Collapsible>
                    <br></br>

                    <Collapsible className="bold" trigger="+ How often is the site enhanced?" open={false}>
                        <br></br>
                        There are small changes rolled out pretty much every day. Do a full reload of the site in your browser from time to time to make sure
                        you always have the latest version.
                    </Collapsible>
                    <br></br>

                </div>
            </div>
        </>
    );
}

export default HelpFAQ;