import React from 'react';
import tableHelpers from '../helpers/TableHelpers.js'
import RichTable from '../components/richTable/RichTable.jsx'

function PlanScorecard(props) {

    function getScorecardArray() {

        return [
            {
                name: 'Annual Profit',
                result: getPeriodResult("yearly", "avgResult"),
                spyResult: getPeriodResult("yearly", "avgResultSpy"),
                format: 'percent'
            },
            {
                name: 'Profitable Months',
                result: getPeriodResult("monthly", "winningPercentage"),
                spyResult: getPeriodResult("monthly", "spyWinningPercentage"),
                format: 'percent'
            },
            {
                name: 'Avg Daily Volatility',
                result: getPeriodResult("daily", "avgVolatility"),
                spyResult: getPeriodResult("daily", "avgSpyVolatility"),
                format: 'percent'
            },
            {
                name: 'Max Drawdown',
                result: getPeriodResult("daily", "maxDrawdown"),
                spyResult: getPeriodResult("daily", "maxSpyDrawdown"),
                format: 'percent'
            },
            {
                name: 'Avg Pullback (over 5%)',
                result: getPeriodResult("daily", "avgDrawdown"),
                spyResult: getPeriodResult("daily", "avgDrawdownSpy"),
                format: 'percent'
            },
            {
                name: 'Pullback Count (over 5%)',
                result: getPeriodResult("daily", "totalDrawdowns"),
                spyResult: getPeriodResult("daily", "totalSpyDrawdowns"),
                format: 'int'
            },
            {
                name: 'Worst Year',
                result: getPeriodResult("yearly", "worst"),
                spyResult: getPeriodResult("yearly", "worstSpy"),
                format: 'percent'
            },
            {
                name: 'Monthly Profit',
                result: getPeriodResult("monthly", "avgResult"),
                spyResult: getPeriodResult("monthly", "avgResultSpy"),
                format: 'percent'
            },
            {
                name: 'Sharpe Ratio',
                result: getPeriodResult("monthly", "sharpeRatio"),
                spyResult: getPeriodResult("monthly", "spySharpeRatio"),
                format: 'decimal'
            },
            {
                name: 'MAR Ratio',
                result: getPeriodResult("yearly", "marRatio"),
                spyResult: getPeriodResult("yearly", "spyMarRatio"),
                format: 'decimal'
            },
        ];
    }

    function dynamicFormatter(cell, row) {
        if (row.format === 'int')
            return tableHelpers.intFormatter(cell, row);
        else if (row.format === 'decimal')
            return tableHelpers.decimalFormatter(cell, row);
        else
            return tableHelpers.percentFormatter(cell, row);
    }

    function getPeriodResult(timeframe, key) {
        return (props.backtestResults && props.backtestResults.periodSummaries && props.backtestResults.periodSummaries[timeframe] && props.backtestResults.periodSummaries[timeframe][key]) ?
            props.backtestResults.periodSummaries[timeframe][key] : 0;
    }

    const returnMapper = [
        { title: 'Category', field: 'name' },
        { title: 'S&P 500', field: 'spyResult', formatter: dynamicFormatter },
        { title: 'Plan', field: 'result', formatter: dynamicFormatter },
    ]

    return (
        <>
            <div>
                <table width="75%">
                    <RichTable data={getScorecardArray()} mappers={returnMapper} className="table-striped table-hover table-condensed" />
                </table>
            </div>
        </>
    );
}

export default PlanScorecard; 