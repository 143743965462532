import React, { useState, useEffect } from 'react';
import tableHelpers from '../helpers/TableHelpers.js'
import { Link } from 'react-router-dom'

function EditableNumber(props) {

    const [isEditing, setIsUpdatingValue] = useState(false);
    const [value, setValue] = useState(props.value);
    const [prevValue, setPrevValue] = useState(0);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);


    async function onUpdateValue() {
        console.log("onUpdateValue")
        if (props.onValueUpdated)
            props.onValueUpdated(value);
        setIsUpdatingValue(false);
    }

    async function onCancel() {
        setIsUpdatingValue(false);
        setValue(prevValue);
    }

    function onUpdateClicked() {
        setIsUpdatingValue(true);
        setPrevValue(value);
    }

    return (
        <div>
            {isEditing ?
                <div className='analytics_hidden'>
                    <input type="text" defaultValue={props.value} value={value} onChange={(e) => setValue(e.target.value)} size={props.length ? props.length : "8"} />
                    &nbsp;<Link onClick={() => onUpdateValue()}>Save</Link>&nbsp;<Link onClick={() => onCancel()}>Cancel</Link>
                </div>
                :
                <div className='analytics_hidden'>
                    {tableHelpers.moneyFormatterNoCents(value, null)}&nbsp;<Link className={"small-text"} onClick={() => onUpdateClicked()}>(Update)</Link>
                </div>
            }

        </div >);
}

export default EditableNumber;