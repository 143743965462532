import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ManagePlans from './manage/ManagePlans.js';
import Subscriptions from './Subscriptions.js';
import { useParams } from 'react-router-dom';

function UpdatePlan(props) {

    let { activeTab } = useParams();
    const [tab, setTab] = useState(activeTab ? activeTab : 'subscriptions');

    useEffect(() => {
        (async function () {
        })();
    }, []);

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    return (
        <div>
            <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        textColor="secondary"
                        indicatorColor="secondary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab value="subscriptions" label="Manage Subscriptions" />
                        <Tab value="manage" label="Manage Plans" />
                    </Tabs>
                </Box>
                <br></br>

                {tab === "subscriptions" ?
                    <>
                        <Subscriptions mobileView={props.mobileView} userId={props.userId} onPlanSelected={props.handlePlanSelected} />
                    </>
                    : null}
                {tab === "manage" ?
                    <>
                        <ManagePlans onPlansUpdated={props.onPlansUpdated} userId={props.userId} onPlanSelected={props.onPlanSelected} />
                    </>
                    : null}
            </>
        </div>
    );
}

export default UpdatePlan;