import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import Tooltip from '../components/Tooltip.js';
import tableHelpers from '../helpers/TableHelpers.js'

function JobStatus(props) {

    const [jobs, setJobs] = useState([]);
    const [jobHistory, setJobHistory] = useState([]);

    useEffect(() => {
        (async function () {
            let jobData = await (await fetch(`/api/jobsStatus`)).json();
            setJobs(jobData);
        })();
    }, []);

    async function getJobHistory(name) {
        console.log("getJobHistory", name);
        setJobHistory([])
        const url = "/api/jobHistory?name=" + name;
        const r = await (await fetch(url)).json();
        setJobHistory(r);
    }

    function getJobData() {
        return jobs.map(job => {
            return {
                name: job.name,
                datetime: new Date(job.datetime),
                status: job.success ? "Success" : "Failed",
                note: job.note
            }
        });
    }

    function jobNameFormatter(cell, row) {
        let data = jobHistory.map(j => {
            return (j.success ? "Success" : "Failed") + " at " + tableHelpers.datetimeESTFormmatter(j.datetime, row);
        });
        return (
            <>
                <Tooltip textClass="tooltip-link" onOpen={() => getJobHistory(row.name)} text={cell} json={data} position="right center" width="500px" />
            </>
        )
    }

    return (
        <div>
            <RichTable data={getJobData()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Name', field: 'name', formatter: jobNameFormatter },
                { title: 'Status', field: 'status' },
                { title: 'Last Ran', field: 'datetime', formatter: tableHelpers.datetimeESTFormmatter },
                { title: 'Notes', field: 'note' },
            ]} />
        </div>
    );
}

export default JobStatus;