import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import BacktestResults from './BacktestResults.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AlertConfiguration from '../alerts/AlertConfiguration.js';
import Dropdown from 'react-bootstrap/Dropdown';
import CircularProgress from '@mui/material/CircularProgress';

function RunBacktest(props) {

    const [startDate, setStartDate] = useState(new Date('2014-01-02'))
    const [endDate, setEndDate] = useState(new Date())
    const [startAmount, setStartAmount] = useState(100000);
    const [activeTradesOnly, setActiveTradesOnly] = useState(false)
    const [officialTest, setOfficialTest] = useState(false)
    const [liveTradesOnly, setLiveTradesOnly] = useState(false)
    const [backtestResults, setBacktestResults] = useState({});
    const [testRunning, setTestRunning] = useState(false)
    const [testId, setTestId] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [planMetadata, setPlanMetadata] = useState({});
    const [allocationStrategyId, setAllocationStrategyId] = useState(-1);
    const [allocationStrategyName, setAllocationStrategyName] = useState('Default allocations')
    const [allocationConfig, setAllocationConfig] = useState({});
    const [allocationStrategies, setAllocationStrategies] = useState([]);


    useEffect(() => {
        (async function () {

            async function fetchPlanMetadata() {
                let metadata = await (await fetch(`/api/planMetaData?planId=` + props.planId)).json();
                if (metadata && metadata.length)
                    setPlanMetadata(metadata[0])
            }

            async function fetchAllocationStrategies() {
                let strategies = await (await fetch(`/api/allocationStrategies`)).json();
                strategies.push({ idallocation_strategies: -1, name: "Default allocations" })
                setAllocationStrategies(strategies);
            }

            fetchPlanMetadata();
            fetchAllocationStrategies();
        })();
    }, [props.planId, testRunning, testId]);

    async function handleRunTest(event) {
        if (!testRunning) {
            event.preventDefault()
            setBacktestResults({});
            setTestRunning(true);
            setTestId(0);
            setChartData([]);

            const config = allocationStrategyId >= 0 ? JSON.stringify(allocationConfig) : JSON.stringify({});
            console.log("Using config", config);

            const url = `/api/backtestRunComplete?planId=` + props.planId +
                "&startDate=" + startDate.toLocaleDateString() +
                "&endDate=" + endDate.toLocaleDateString() +
                "&startAmount=" + startAmount +
                "&saveDailyPositions=" + (false ? "1" : "0") +
                "&activeTradesOnly=" + (activeTradesOnly ? "1" : "0") +
                "&liveTradesOnly=" + (liveTradesOnly ? "1" : "0") +
                "&deleteResults=" + (officialTest ? "0" : "1") +
                "&allocationStrategyId=" + (allocationStrategyId > 0 ? allocationStrategyId : null) +
                "&isOfficial=" + (officialTest ? "1" : "0");
            const text = await (await fetch(url, {
                method: 'POST',
                body: config
            })).json();

            console.log("Results from backtestRun", text, text.testId);
            setTestId(text.testId)
            setBacktestResults(text);
            setTestRunning(false);

            const tempChartData = [];
            text.dailyResults.forEach(day => tempChartData.push([new Date(day.date), day.total, day.baseline]))
            setChartData(tempChartData)
        }
    };

    function isOwner() {
        if (props.role === 3) return true;
        if (props.userId === planMetadata.userIdOwner) return true;
        return false;
    }

    function handleConfigUpdate(c) {
        setAllocationConfig(c);
    }

    function onStrategyDropdownSelected(strategyId) {
        console.log("Looking for " + strategyId + " in", allocationStrategies);
        const strat = allocationStrategies.find(s => s.idallocation_strategies === Number(strategyId));
        if (strat) {
            setAllocationStrategyId(strategyId);
            setAllocationStrategyName(strat.name)
            loadAllocationConfig(strategyId);
        }
    }

    async function loadAllocationConfig(strategyId) {
        let config = await (await fetch(`/api/allocationStrategyConfig?allocationStrategyId=` + strategyId)).json();
        setAllocationConfig(config);
    }

    return (
        <div>
            <center>
                <>
                    {props.role >= 1 ? <Link id="managealgos" className="bg-transparent" to={"/managealgos"}>Update Algos</Link> : null}
                    <br></br>
                    Start Date: <DatePicker showMonthYearPicker className="bg-transparent" selected={startDate} onChange={(date) => setStartDate(date)} />
                    &nbsp;&nbsp;End Date: <DatePicker showMonthYearPicker className="bg-transparent" selected={endDate} onChange={(date) => setEndDate(date)} />
                    <br></br>
                    Starting Amount: <input className="analytics_hidden" type="text" size="7" defaultValue={startAmount} onChange={(e) => setStartAmount(e.target.value)}></input>
                    {isOwner() ?
                        <>
                            <br></br>
                            &nbsp;&nbsp;Active Algos Only: <input type="checkbox" onChange={(e) => setActiveTradesOnly(e.target.value)}></input>
                            &nbsp;&nbsp;Live Trades Only: <input type="checkbox" onChange={(e) => setLiveTradesOnly(e.target.value)}></input>
                            &nbsp;&nbsp;Is Official: <input type="checkbox" onChange={(e) => setOfficialTest(e.target.value)}></input>

                            <br></br>
                            <Dropdown width="150" onSelect={(e) => onStrategyDropdownSelected(e)}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {allocationStrategyName}
                                </Dropdown.Toggle>

                                <Dropdown.Menu  >
                                    {allocationStrategies.map(strategy => {
                                        return (<Dropdown.Item as='strategyId' eventKey={strategy.idallocation_strategies} value={strategy.name}>{strategy.name}</Dropdown.Item>)
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>

                            {allocationStrategyId >= 0 ? <AlertConfiguration config={allocationConfig} defaultConfig={allocationConfig} onConfigUpdated={handleConfigUpdate} showMaxConfig={false} /> : null}
                        </>
                        :
                        null
                    }
                    <br></br>
                    <Button className="btn btn-primary" onClick={handleRunTest}>{testRunning ? "Running..." : "Run Test"}</Button>
                </>
            </center>

            <br></br>

            {testRunning ?

                <center>
                    <CircularProgress />
                </center>

                : backtestResults && backtestResults.testId ?

                    <BacktestResults mobileView={props.mobileView} backtestResults={backtestResults} onAlgoSelected={props.onAlgoSelected} chartData={chartData} />

                    :

                    null
            }
        </div >
    );
}

export default RunBacktest;