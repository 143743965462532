import React from 'react';
import { Chart } from "react-google-charts";

function PositionsChart(props) {

    function getTodaysChangeData() {
        let chartData = {};
        chartData.data = [];
        chartData.minValue = 0;
        chartData.maxValue = 0;

        props.positions.forEach(pos => {
            const change = pos.todayChangePercent / 100;
            if (pos.netPosition !== 0) {
                chartData.data.push([pos.netSymbol, change, change >= 0 ? "#B2D8B2" : "#B85D5D"])
                if (change > chartData.maxValue) chartData.maxValue = change;
                if (change < chartData.minValue) chartData.minValue = change;
            }
        });
        chartData.data.sort((a, b) => {
            return a[1] - b[1];
        })

        if (chartData.data.length)
            chartData.data.unshift(['Symbol', 'P/L', { role: "style" }]);

        return chartData;
    }

    let todaysChangeChartData = getTodaysChangeData();

    /*
    var chartOptionsPL = {
        vAxis: {
            format: '#%',
            viewWindowMode: 'explicit',
            viewWindow: {
                max: maxValue + (Math.abs(maxValue) * .1),
                min: minValue - (Math.abs(minValue) * .1)
            },
            minValue: maxValue + (Math.abs(maxValue) * .1),
            maxValue: minValue - (Math.abs(minValue) * .1)
        },
        title: "P/L by Stock",
        legend: { position: 'none' },
        bar: { width: "25" },
    }
        */

    function getImpactChartData() {
        let data = [];
        let total = 0;
        props.positions.forEach(pos => {
            const change = Math.abs(pos.todayChangePercent) * pos.netPosition / 100;
            if (pos.netPosition !== 0) {
                total += change;
            }
        });

        props.positions.forEach(pos => {
            const change = Math.abs(pos.todayChangePercent) * pos.netPosition / 100 / total;
            if (pos.netPosition !== 0) {
                data.push([pos.netSymbol, change, pos.todayChangePercent >= 0 ? "#B2D8B2" : "#B85D5D"])
            }
        });
        data.sort((a, b) => {
            return a[1] - b[1];
        })
        if (data.length)
            data.unshift(['Symbol', 'P/L Impact', { role: "style" }]);
        return data;
    }

    var chartOptionsTodaysChange = {
        vAxis: {
            format: '#%',
            viewWindowMode: 'explicit',
            viewWindow: {
                max: todaysChangeChartData.maxValue + (Math.abs(todaysChangeChartData.maxValue) * .1),
                min: todaysChangeChartData.minValue - (Math.abs(todaysChangeChartData.minValue) * .1)
            },
            minValue: todaysChangeChartData.maxValue + (Math.abs(todaysChangeChartData.maxValue) * .1),
            maxValue: todaysChangeChartData.minValue - (Math.abs(todaysChangeChartData.minValue) * .1)
        },
        title: "Today's Change",
        legend: { position: 'none' },
        bar: { width: "20" },
    }

    var pieChartOptions = {
        title: "Impact to Today's Change",
        legend: { position: 'none' },
        isStacked: true,
        vAxis: {
            format: '#%',
        },
        bar: { width: "20" },
    }

    return (
        <table width="100%">
            <tbody>
                <tr>
                    <td width="50%">
                        {props.positions && props.positions.length ?
                            <Chart
                                chartType="ColumnChart"
                                data={getImpactChartData()}
                                width={"100%"}
                                options={pieChartOptions}
                                chartPackages={["corechart", "controls"]}
                            />
                            : null
                        }
                    </td>
                    <td>
                        {props.positions && props.positions.length ?
                            <Chart
                                chartType="ColumnChart"
                                data={todaysChangeChartData.data}
                                options={chartOptionsTodaysChange}
                                width={"100%"}
                                chartPackages={["corechart", "controls"]}
                            />
                            : null
                        }
                    </td>
                </tr>
            </tbody>
        </table >
    );
}

export default PositionsChart;