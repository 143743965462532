import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';

function SessionExpiredPopup(props) {

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        (async function () {
            if (props.isExpired)
                setOpen(true);
        })();
    }, [props.isExpired]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <a onClick={props.onLogout} id="runtest" href='/login'>Login</a>
        </React.Fragment>
    );

    if (props.isExpired) {
        console.log("Showing session expired popup");
        return (
            <div style={{ padding: '5px' }}>
                <Snackbar
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    action={action}
                    message={"Session expired"}
                />
            </div>
        );
    } else {
        return <></>
    }
}

export default SessionExpiredPopup;