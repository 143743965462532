import React, { useState, useEffect } from 'react';
import AlertEvaluationResults from './AlertEvaluationResults.js';
import AlertConfiguration from './AlertConfiguration.js';
import AlertSettings from './AlertSettings.js';
import StockChart from '../stock/StockChart.js';
import AlertDataSample from './AlertDataSample.js';
import QuoteDateAnalysis from './QuoteDateAnalysis.js';
import { Link } from 'react-router-dom'
import AlertProfitChart from "./AlertProfitChart.js"
import AlertTradeResultsChart from './AlertTradeResultsChart.js';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ActionResult from '../components/ActionResult.js'

function AlertSandbox(props) {

    const [config, setConfig] = useState({})
    const [defaultConfig, setDefaultConfig] = useState({})
    const [performance, setPerformance] = useState({})
    const [performanceArray, setPerformanceArray] = useState([])
    const [symbol, setSymbol] = useState(null)
    const [timeframe, setTimeframe] = useState(0)
    const [updates, setUpdates] = useState(0);
    const [selectedStrategyId, setSelectedStrategyId] = useState(0);
    const [startDate, setStartDate] = useState(new Date(2014, 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [addRandomness, setAddRandomness] = useState(false);
    const [showDrawdown, setShowDrawdown] = useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [algoName, setAlgoName] = React.useState(null);
    const [result, setResult] = useState(null);

    useEffect(() => {
        (async function () {

            if (selectedStrategyId) {
                console.log("Loading default config", selectedStrategyId);
                setSelectedStrategyId(selectedStrategyId);

                let text = await (await fetch(`/api/strategyDefaultConfig?strategyId=` + selectedStrategyId)).json();
                if (text) {
                    setConfig(text);
                    setDefaultConfig(text);
                }
            }
        })();
    }, [selectedStrategyId, updates]);

    async function handleReset() {
        setUpdates(updates + 1);
    }

    async function handleEvaluate(conf = null, start = null, end = null) {
        if (selectedStrategyId && symbol) {

            console.log("addRandomness", addRandomness);

            let startToUse = start ? start : startDate;
            let endToUse = end ? end : endDate;
            let url = "/api/strategySandboxEvaluator?strategyId=" + selectedStrategyId + "&symbol=" + symbol + "&timeframe=" + timeframe
            if (startDate) url += "&startDate=" + startToUse.getFullYear().toString() + "-" + (startToUse.getMonth() + 1).toString() + "-" + (startToUse.getDate()).toString();
            if (endDate) url += "&endDate=" + endToUse.getFullYear().toString() + "-" + (endToUse.getMonth() + 1).toString() + "-" + (endToUse.getDate()).toString();
            url += "&addRandomness=" + (addRandomness ? 'true' : 'false');
            const configToUse = conf ? JSON.stringify(conf) : JSON.stringify(config)
            console.log("handleEvaluate", configToUse);
            console.log("handleEvaluate", url);

            const r = await (await fetch(url, {
                method: 'POST',
                body: configToUse
            })).json();
            console.log(r);
            setPerformance(r);

            let perfArray = [];
            perfArray.push({ id: performanceArray.length + 1, ...r });
            performanceArray.forEach(p => {
                perfArray.push(p);
            })
            setPerformanceArray(perfArray);
        }
    }

    function handleClearResults() {
        setPerformanceArray([]);
    }

    function handleConfigUpdate(c) {
        console.log(c);
        setConfig(c);
        handleEvaluate(c);
    }

    function handleAlertUpdated(strategyId, symbol, timeframe, name) {
        console.log(strategyId, symbol, timeframe, name);
        setSelectedStrategyId(strategyId);
        setSymbol(symbol);
        setTimeframe(timeframe);
    }

    function onDataSampleUpdated(startDate, endDate) {
        setStartDate(startDate);
        setEndDate(endDate);
        handleEvaluate(null, startDate, endDate);
    }

    function onAddRandomnessUpdated(shouldAddRandomness) {
        setAddRandomness(shouldAddRandomness);
    }

    const handleCloseModal = () => {
        setModalOpen(false);
    }
    const handleOpenModal = () => {
        setModalOpen(true);
    }

    function shouldAllowEdits() {
        if (props.role === 3) return true;
        else return false;
    }

    async function handleCreateAlert() {
        const url = "/api/strategyAlertCreate?strategyId=" + selectedStrategyId + "&symbol=" + symbol + "&timeframe=" + timeframe + "&algo=" + algoName;
        console.log(url);
        const r = await (await fetch(url, { method: 'POST' })).json();
        if (r.success && r.id) {
            const updateConfigUrl = "/api/strategyAlertUpdate?alertId=" + r.id
            console.log(updateConfigUrl);
            await (await fetch(updateConfigUrl, {
                method: 'POST',
                body: JSON.stringify(config)
            })).json();
            setResult({ success: "Alert created" })
        } else {
            setResult({ error: "Could not create alert" })
        }

        handleCloseModal();
    }

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    function getTrades() {
        if (performance && performance.trades && performance.trades.length) {
            return performance.trades.map(t => {
                return {
                    purchase_date: t.purchaseDate,
                    purchase_price: t.purchasePrice,
                    sell_date: t.sellDate,
                    sell_price: t.sellPrice,
                    profitPercentage: t.profitPercentage
                }
            })
        } else {
            return [];
        }
    }

    return (
        <div>
            <table width="500px">
                <AlertSettings onAlertUpdated={handleAlertUpdated} hideAlgoName={true} /><br></br>
            </table>

            {selectedStrategyId && symbol ?
                <>

                    <table width="100%" cellPadding={10}>
                        <tr>
                            <td>
                                <AlertConfiguration config={config} defaultConfig={defaultConfig} onConfigUpdated={handleConfigUpdate} showMaxConfig={false} />
                            </td>
                            <td align='left' valign='top' width="250px">
                                <AlertEvaluationResults mobileView={props.mobileView} performance={performance} performanceArray={performanceArray} shouldShowTrades={!props.mobileView} />
                            </td>
                            <td align='left' valign='top'>
                                <Link onClick={() => setShowDrawdown(!showDrawdown)}>{showDrawdown ? "Show Profit" : "Show Drawdown"}</Link>
                                <AlertTradeResultsChart showDrawdown={showDrawdown} performance={performance} />
                                <AlertProfitChart showDrawdown={showDrawdown} performance={performance} />
                            </td>
                        </tr>
                    </table>

                    <AlertDataSample onDataSampleUpdated={onDataSampleUpdated} onAddRandomnessUpdated={onAddRandomnessUpdated} />

                    <>
                        <br></br>
                        <button onClick={() => handleReset()}>Reset</button>
                        &nbsp;&nbsp;&nbsp;<button onClick={() => handleEvaluate()}>Evaluate</button>
                        &nbsp;&nbsp;&nbsp;<button onClick={handleClearResults}>Clear Results</button>
                        &nbsp;&nbsp;&nbsp;<button onClick={handleOpenModal}>Create Alert</button>
                    </>

                    <br></br>
                    <br></br>
                    <QuoteDateAnalysis symbol={symbol} trades={performance.trades} />

                    <StockChart symbol={symbol} trades={getTrades()} />

                </>
                :
                null}

            <ActionResult result={result} />

            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={handleCloseModal}
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Name?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <input type="text" onChange={(e) => setAlgoName(e.target.value)} />
                        <br></br>
                        <br></br>
                        {shouldAllowEdits() ? <button class="btn btn-primary" onClick={handleCreateAlert} type="button">Submit</button> : null}
                    </Typography>
                </Box>
            </Modal>

        </div >);
}

export default AlertSandbox;