import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AlgoStats from './AlgoStats';
import AlgoPerformanceComparison from './AlgoPerformanceComparison';

function AlgoPerformance(props) {

    const [value, setValue] = useState('comparison');

    useEffect(() => {
        (async function () {
        })();
    }, []);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                >
                    <Tab value="comparison" label="Comparison" />
                    <Tab value="summary" label="Summary" />
                </Tabs>
            </Box>

            {value === "summary" ?
                <>
                    <AlgoStats mobileView={props.mobileView} planId={props.planId} onAlgoSelected={props.onAlgoSelected} />
                </>
                : null}
            {value === "comparison" ?
                <>
                    <AlgoPerformanceComparison mobileView={props.mobileView} planId={props.planId} onAlgoSelected={props.onAlgoSelected} />
                </>
                : null}

        </>
    );
}

export default AlgoPerformance;