import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import Tooltip from '../components/Tooltip.js';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from 'react-router-dom'


function SingleAlgoRobustness(props) {
    const [data, setData] = useState(null);
    const [updates, setUpdates] = useState(0);

    useEffect(() => {
        (function () {
            setData(null);
            fetch(`/api/algoRobustness?algoId=` + props.algoId)
                .then(res => res.json())
                .then(text => {
                    setData(text);
                });
        })();
    }, [props.algoId, updates]);

    function getSccores() {
        if (data)
            return [data];
        else
            return [];
    }

    function liveVsBacktestFormatter(liveVsBacktest, row) {
        if (liveVsBacktest)
            return <>{tableHelpers.intFormatter(liveVsBacktest, null)}<Tooltip width="600px" text={" 🔍"} json={row.details.liveVsBacktest} /></>
        return null;
    }
    function recentVsLongtermFormatter(recentVsLongterm, row) {
        if (recentVsLongterm)
            return <>{tableHelpers.intFormatter(recentVsLongterm, row)}<Tooltip width="600px" text={" 🔍"} json={row.details.recentVsLongterm} /></>
        else return null;
    }
    function oosFormatter(oos, row) {
        if (oos)
            return <>{tableHelpers.intFormatter(oos, row)}<Tooltip width="600px" text={" 🔍"} json={row.details.oos} /></>
        else
            return null;
    }
    function monteCarloFormatter(monteCarlo, row) {
        if (monteCarlo)
            return <>{tableHelpers.intFormatter(monteCarlo, row)}<Tooltip width="600px" text={" 🔍"} json={row.details.monteCarlo} /></>
        else return null;
    }

    async function rerunTests() {
        setData(null);
        const url = `/api/algoRobustnessTests?algoId=` + props.algoId;
        await (await (fetch(url, { method: 'POST' }))).json();
        setUpdates(updates + 1);
    }

    if (data) {
        return (
            <>
                <RichTable data={getSccores()} className="table-striped table-hover table-condensed" mappers={[
                    { title: 'Overall', field: 'overall', formatter: tableHelpers.decimalFormatter, sorters: true },
                    { title: 'Live Trades', field: 'live_vs_backtest', formatter: liveVsBacktestFormatter, sorters: true },
                    { title: 'Recent Trades', field: 'recent_vs_longterm', formatter: recentVsLongtermFormatter, sorters: true },
                    { title: 'Out Of Sample', field: 'oos', formatter: oosFormatter, sorters: true },
                    { title: 'Monte Carlo', field: 'monte_carlo', formatter: monteCarloFormatter, sorters: true },
                ]} />
                <Link onClick={rerunTests}>Re-Run Tests</Link>
            </>
        );
    } else {
        return (
            <div style={{ width: '100%' }}>
                <LinearProgress />
            </div>
        );
    }
}

export default SingleAlgoRobustness;