import React, { useState, useEffect } from 'react';
import TradingStrategies from './TradingStrategies'
import AlertSandbox from "./AlertSandbox"
import CreateAlert from "./CreateAlert"
import ConfigureAlert from "./ConfigureAlert"
import Indicators from "../positions/Indicators"
import StockCorelation from '../plan/StockCorrelation'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';


function Alerts(props) {

    let { activeTab } = useParams();

    const [tab, setTab] = useState(activeTab ? activeTab : 'summary');
    const [symbols, setSymbols] = useState([]);

    useEffect(() => {
        (async function () {
            const syms = await (await fetch(`/api/strategySymbols`)).json();
            setSymbols(syms);
        })();
    }, []);

    function shouldAllow() {
        return true;
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    return (
        <div>
            {shouldAllow() ?
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab value="summary" label="View" />
                            <Tab value="indicators" label="Indicators" />
                            <Tab value="create" label="Create Alert" />
                            <Tab value="sandbox" label="Sandbox" />
                            <Tab value="correlations" label="Stock Correlations" />
                        </Tabs>
                    </Box>
                    <br></br>

                    {tab === "summary" ?
                        <>
                            <TradingStrategies planId={props.planId} mobileView={props.mobileView} role={props.role} onAlgoSelected={props.onAlgoSelected} onAlertSelected={props.onAlertSelected} />
                        </>
                        : null}
                    {tab === "create" ?
                        <>
                            <CreateAlert role={props.role} />
                        </>
                        : null}

                    {tab === "sandbox" ?
                        <>
                            <AlertSandbox mobileView={props.mobileView} role={props.role} />
                        </>
                        : null}
                    {tab === "configure" ?
                        <>
                            <ConfigureAlert mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} role={props.role} alertId={props.alertId} />
                        </>
                        : null}
                    {tab === "indicators" ?
                        <>
                            <Indicators symbols={symbols} />
                        </>
                        : null}
                    {tab === "correlations" ?
                        <>
                            <StockCorelation symbols={symbols} />
                        </>
                        : null}
                </>
                :
                <div class="empty-page" >Not allowed</div>
            }


        </div>
    );
}

export default Alerts;