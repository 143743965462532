import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import tableHelpers from '../helpers/TableHelpers.js'
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'
import { createDateSorter } from '../components/richTable/sorters.js'
import { useParams } from 'react-router-dom';
import SingleAlgoRobustness from './SingleAlgoRobustness.js';
import ExpectancyChart from "../components/ExpectancyChart.js"
import QuoteDateAnalysis from '../alerts/QuoteDateAnalysis.js';
import Tooltip from '../components/Tooltip.js'
import TwoStepConfirmationButton from "../components/TwoStepConfirmButton.js"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AlgoAverageTrade from './AlgoAverageTrade.js';
import Stock from '../stock/Stock.js'
import ConfigureAlert from "../alerts/ConfigureAlert"

function getYearsAgo(years) {
    let date = new Date();
    date.setFullYear(date.getFullYear() - years);
    return date;
}

function Algo(props) {
    let { algoIdParam } = useParams();

    const [tradeHistory, setTradeHistory] = useState([]);
    const [backtestTrades, setBacktestTrades] = useState([]);
    const [plans, setPlans] = useState([]);
    const [quote, setQuote] = useState(null);
    const [algoInfo, setAlgoInfo] = useState({})
    const [quoteHistory, setQuoteHistory] = useState([])
    const [startDate, setStartDate] = useState(getYearsAgo(3))
    const [endDate, setEndDate] = useState(new Date())
    const [stats, setStats] = useState({})
    const [timeframeStats, setTimeframeStats] = useState([])
    const [updates, setUpdates] = useState(0);
    const [view, setView] = useState('Backtest Trades')
    const [alert, setAlert] = useState(null);
    const [alertStatus, setAlertStatus] = useState(null);
    const [algoId] = useState(algoIdParam ? algoIdParam : props.algoId)
    const [tab, setTab] = useState('average');

    const shouldRenderTrade = (trade, start, end) => {
        const buyDate = new Date(trade.purchase_date);
        const sellDate = new Date(trade.sell_date);
        if (buyDate >= start && buyDate <= end)
            return true;
        if (sellDate >= start && sellDate <= end)
            return true;
        return false;
    }

    function getStats(trades, start, end) {
        let stats = { count: 0, winners: 0, winningPct: 0.0, losers: 0, avgDuration: 0.0, avgProfit: 0.0, avgWin: 0.0, avgLoss: 0.0, maxWin: 0.0, maxLoss: 0.0, netProfit: 0.0, kelly: 0.0 }

        trades.forEach(trade => {
            if (trade.sell_price && trade.purchase_price) {
                const buyDate = new Date(trade.purchase_date);
                const sellDate = new Date(trade.sell_date);

                if ((buyDate >= start && buyDate <= end) || (sellDate >= start && sellDate <= end)) {
                    const durationInDays = (sellDate.getTime() - buyDate.getTime()) / (1000 * 60 * 60 * 24);

                    let profit = ((trade.sell_price - trade.purchase_price) / trade.purchase_price * 100);
                    stats.netProfit += profit;
                    stats.avgProfit = ((stats.avgProfit * stats.count) + profit) / (stats.count + 1);
                    stats.avgDuration = ((stats.avgDuration * stats.count) + durationInDays) / (stats.count + 1);

                    if (trade.sell_price >= trade.purchase_price) {
                        if (profit > stats.maxWin) stats.maxWin = profit;
                        stats.avgWin = ((stats.avgWin * stats.winners) + profit) / (stats.winners + 1);
                        ++stats.winners;
                    } else {
                        if (profit < stats.maxLoss) stats.maxLoss = profit;
                        stats.avgLoss = ((stats.avgLoss * stats.losers) + profit) / (stats.losers + 1);
                        ++stats.losers;
                    }
                    ++stats.count;
                    stats.winningPct = stats.winners / stats.count * 100;
                }
            }
        });

        const probabilityOfWinning = (stats.winningPct / 100);
        const winLossRatio = Math.abs(stats.avgWin / stats.avgLoss);
        stats.kelly = (probabilityOfWinning - ((1 - probabilityOfWinning) / winLossRatio)) * 100

        return stats;
    }

    useEffect(() => {
        (async function () {

            let algo = await (await fetch(`/api/algo?algoId=` + algoId)).json();
            setTradeHistory(algo && algo.trades ? algo.trades : []);
            setAlgoInfo(algo && algo.algo && algo.algo.length ? algo.algo[0] : {});
            setBacktestTrades(algo && algo.backtestTrades ? algo.backtestTrades : []);
            setPlans(algo && algo.plans ? algo.plans : []);
            setStats(getStats(algo.backtestTrades, new Date(startDate), new Date(endDate)));
            setTimeframeStats(algo.stats);

            setTradeHistory(algo && algo.trades ? algo.trades : []);
            setAlgoInfo(algo && algo.algo && algo.algo.length ? algo.algo[0] : {});
            setBacktestTrades(algo && algo.backtestTrades ? algo.backtestTrades : []);
            setPlans(algo && algo.plans ? algo.plans : []);
            setQuote(algo && algo.quote ? algo.quote : null);
            setStats(getStats(algo.backtestTrades, new Date(startDate), new Date(endDate)));
            setTimeframeStats(algo.stats);

            let info = algo && algo.algo && algo.algo.length ? algo.algo[0] : null;
            if (info) {
                let alert = await (await fetch(`/api/strategyAlertLookup?name=` + info.name + `&symbol=` + info.symbol)).json();
                setAlert(alert);

                const url = "/api/strategyAlertEvaluator?alertId=" + alert.idtrading_strategy_alerts + "&currentBarOnly=1"
                const r = await (await fetch(url, {
                    method: 'POST',
                })).json();
                setAlertStatus(r);
            }

            const symbol = algo && algo.algo && algo.algo.length ? algo.algo[0].trade_symbol : "";
            const url = `/api/quoteHistory?symbol=` + symbol +
                "&startDate=" + startDate.toLocaleDateString() +
                "&endDate=" + endDate.toLocaleDateString();
            let history = await (await fetch(url)).json();
            history.reverse();
            console.log(history);
            setQuoteHistory(history);
        })();
    }, [algoId, startDate, endDate, updates]);

    function getChartData(includeLiveTrades = true, includeBacktestTrades = true) {

        let retVal = {
            maxPrice: 0,
            minPrice: 10000000,
            data: []
        };

        quoteHistory.forEach(quote => {
            let long = 0;

            if (quote.close > retVal.maxPrice) retVal.maxPrice = quote.close;
            if (quote.close < retVal.minPrice) retVal.minPrice = quote.close;

            const date = new Date(quote.date);
            if (includeLiveTrades) {
                tradeHistory.forEach(trade => {
                    const buyDate = new Date(trade.purchase_date);
                    const sellDate = new Date(trade.sell_date);
                    if (date >= buyDate && (trade.sell_date === null || date <= sellDate)) {
                        long = quote.close;
                    }
                })
            }
            if (includeBacktestTrades) {
                backtestTrades.forEach(trade => {
                    const buyDate = new Date(trade.purchase_date);
                    const sellDate = new Date(trade.sell_date);
                    if (trade.sell_date === null) console.log(trade);
                    if (date >= buyDate && (trade.sell_date === null || date <= sellDate)) long = quote.close;
                })
            }
            retVal.data.push([(date.getUTCMonth() + 1) + "/" + date.getUTCDate() + "/" + (date.getUTCFullYear() - 2000),
            quote.close, long]);
        })

        if (retVal.data.length)
            retVal.data.unshift(['Date', 'Price', 'Long']);

        return retVal;
    }

    function renderChart(includeLiveTrades, includeBacktestTrades) {
        let chartData = getChartData(includeLiveTrades, includeBacktestTrades);

        const options = {
            vAxis: {
                viewWindowMode: 'explicit',
                viewWindow: {
                    max: chartData.maxPrice * 1.05,
                    min: chartData.minPrice * .9
                },
                minValue: chartData.minPrice * .9,
                maxValue: chartData.maxPrice * 1.05
            },
            chartArea: { 'width': '75%', 'height': '75%' },
        };

        return (
            <>
                {
                    chartData.data.length ?
                        <Chart
                            chartType="AreaChart"
                            data={chartData.data}
                            options={options}
                            //width={"100%"}
                            height={"400px"}
                            chartPackages={["corechart", "controls"]}
                        />
                        :
                        null
                }
            </>
        );
    }

    function renderPLCharts() {
        let chartData = [];
        chartData.push(['Profit', 'Count', { role: "style" }]);

        let distribution = {};
        for (let i = -20; i <= 21; i++) {
            distribution[i] = 0;
        }

        backtestTrades.filter(trade => shouldRenderTrade(trade, startDate, endDate)).forEach(trade => {
            if (trade.profit < -20) {
                distribution[-20] += 1;
            } else if (trade.profit < -10) {
                distribution[-10] += 1;
            } else if (trade.profit < -5) {
                distribution[-5] += 1;
            } else if (trade.profit < 0) {
                distribution[0] += 1;
            } else if (trade.profit < 5) {
                distribution[5] += 1;
            } else if (trade.profit < 10) {
                distribution[10] += 1;
            } else if (trade.profit < 20) {
                distribution[20] += 1;
            } else {
                distribution[21] += 1;
            }
        })

        chartData.push(['<-20%', distribution[-20], "#B85D5D"]);
        chartData.push(['-10 to -20%', distribution[-10], "#B85D5D"]);
        chartData.push(['-5 to -10%', distribution[-5], "#B85D5D"]);
        chartData.push(['0 to -5%', distribution[0], "#B85D5D"]);
        chartData.push(['0 to 5%', distribution[5], "#B2D8B2"]);
        chartData.push(['5 to 10%', distribution[10], "#B2D8B2"]);
        chartData.push(['10 to 20%', distribution[20], "#B2D8B2"]);
        chartData.push(['>20%', distribution[21], "#B2D8B2"]);

        var options = { legend: { position: 'none' }, bar: { width: "25" } }
        var optionsPercent = { legend: { position: 'none' }, vAxis: { format: '#%' }, bar: { width: "25" } }

        let netChartData = [];
        netChartData.push(['Total', 'Percent', { role: "style" }]);
        netChartData.push(["Avg Loss", Math.abs(stats.avgLoss / 100), "#B85D5D"]);
        netChartData.push(["Avg Per Trade", stats.avgProfit / 100, "#A0DDFD"]);
        netChartData.push(["Avg Win", stats.avgWin / 100, "#B2D8B2"]);

        return (
            <>
                <table border="0" width="100%">
                    <tr>
                        <td width="50%">
                            <Chart
                                chartType="ColumnChart"
                                data={netChartData}
                                width={"100%"}
                                options={optionsPercent}
                                chartPackages={["corechart", "controls"]}
                            />
                        </td>
                        <td>
                            <Chart
                                chartType="ColumnChart"
                                data={chartData}
                                width={"100%"}
                                options={options}
                                chartPackages={["corechart", "controls"]}
                            />
                        </td>
                    </tr>
                </table>
            </>
        );

    }


    function getStatsArray(useLiveTrades) {
        if (useLiveTrades)
            return [getStats(tradeHistory, startDate, endDate)]
        else
            return [getStats(backtestTrades, startDate, endDate)]
    }

    function getPlanData() {
        return plans.map(plan => {
            return {
                name: plan.name,
                percentage: plan.percentage.toFixed(0)
            }
        })
    }

    function getBacktestTrades() {
        return backtestTrades.filter(trade => shouldRenderTrade(trade, startDate, endDate)).map(trade => {
            const durationInDays = trade.sell_date ? (new Date(trade.sell_date).getTime() - new Date(trade.purchase_date).getTime()) / (1000 * 60 * 60 * 24) : 0;
            return {
                purchase_date: trade.purchase_date,
                purchase_price: trade.purchase_price,
                sell_date: trade.sell_date,
                sell_price: trade.sell_price,
                duration: durationInDays > 0 ? durationInDays : null,
                profit: trade.profit
            }
        })
    }

    function getTimeframeStats() {
        return timeframeStats.sort((a, b) => a.years - b.years).map(stats => {
            return {
                years: stats.years === 0 ? 'Live Trades' : stats.years + " year Backtest",
                years_number: stats.years,
                count: stats.count,
                winning_percentage: stats.winning_percentage,
                avg_profit: stats.avg_profit,
                avg_win: stats.avg_win,
                avg_loss: stats.avg_loss,
                max_win: stats.max_win,
                max_loss: stats.max_loss,
                average_duration: stats.average_duration,
                total_profit: stats.count * stats.avg_profit,
                annual_profit: stats.years > 0 ? (stats.count * stats.avg_profit) / stats.years : 0
            }
        });
    }

    function getLiveTrades() {
        return tradeHistory.map(trade => {
            return {
                purchase_date: trade.purchase_date,
                purchase_price: trade.purchase_price,
                sell_date: trade.sell_date,
                sell_price: trade.sell_price,
                profit: trade.sell_price ? (trade.sell_price - trade.purchase_price) / trade.purchase_price * 100 : 0
            }
        });
    }

    function handleYearRangeClicked(years) {
        let d = new Date();
        d.setFullYear(d.getFullYear() - years);
        setStartDate(d);
    }

    async function handleDeleteAlgo() {

        console.log("handleDeleteAlgo", algoId);
        const url = `/api/algoDelete?algoId=` + algoId + `&userId=` + props.userId;
        console.log(url);
        await (await fetch(url)).json();
        setUpdates(updates + 1)

    }

    function renderRealizedGainChart(useLiveTrades = false) {

        let chartData = [];
        chartData.push(['Date', 'Gain', { role: "style" }]);

        const trades = useLiveTrades ? getLiveTrades() : getBacktestTrades();
        const end = new Date(endDate);
        let date = new Date(startDate);
        let balance = 0;
        let maxValue = balance;
        let minValue = balance;
        while (date <= end) {
            for (const trade of trades) {
                const closeDate = new Date(trade.sell_date);
                if (date.getFullYear() === closeDate.getFullYear() &&
                    date.getMonth() === closeDate.getMonth() &&
                    date.getDate() === closeDate.getDate()) {

                    const profit = trade.profit / 100;
                    balance += profit;
                }
                if (balance > maxValue) maxValue = balance;
                if (balance < minValue) minValue = balance;
            }

            let color = balance > 0 ? "#A0DDFD" : "#B85D5D";
            chartData.push([new Date(date), balance, color])
            date.setDate(date.getDate() + 1)
        }

        const options = {
            title: "Realized P/L (not compounded)",
            vAxis: {
                format: '#%',
                viewWindowMode: 'explicit',
                viewWindow: {
                    max: maxValue + (Math.abs(maxValue) * .1),
                    min: minValue - (Math.abs(minValue) * .1)
                },
                minValue: maxValue + (Math.abs(maxValue) * .1),
                maxValue: minValue - (Math.abs(minValue) * .1)
            },
            chartArea: { 'width': '75%', 'height': '80%' },
            legend: { position: 'none' },
        };

        if (chartData.length <= 1) {
            return (<></>);
        } else {
            return (
                <div>
                    <center>
                        <Chart
                            chartType="AreaChart"
                            data={chartData}
                            width={"100%"}
                            height={"150px"}
                            options={options}
                            chartPackages={["corechart", "controls"]}
                        />
                    </center>
                </div>
            )
        }
    }

    const liveTradesMapper = [
        { title: `Purch Date`, field: 'purchase_date', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('purchase_date') },
        { title: `Purch Price`, field: 'purchase_price', hidden: props.mobileView, formatter: tableHelpers.moneyFormatter, sorters: true },
        { title: `Sell Date`, field: 'sell_date', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('sell_date') },
        { title: `Sell Price`, field: 'sell_price', hidden: props.mobileView, formatter: tableHelpers.moneyFormatter, sorters: true },
        { title: `P/L`, field: 'profit', hidden: props.mobileView, formatter: tableHelpers.percentFormatter, sorters: true },
    ]

    const statsMapper = [
        { title: `Net Profit`, field: 'netProfit', formatter: tableHelpers.percentFormatter },
        { title: `Winners`, hidden: props.mobileView, field: 'winners', formatter: tableHelpers.intFormatter },
        { title: `Losers`, hidden: props.mobileView, field: 'losers', formatter: tableHelpers.intFormatter },
        { title: `Win %`, field: 'winningPct', formatter: tableHelpers.percentFormatter },
        { title: `Avg Profit`, field: 'avgProfit', formatter: tableHelpers.percentFormatter },
        { title: `Avg Duration`, field: 'avgDuration', formatter: tableHelpers.intFormatter },
        { title: `Kelly`, hidden: props.mobileView, field: 'kelly', formatter: tableHelpers.percentFormatter },
        { title: `Avg Win`, hidden: props.mobileView, field: 'avgWin', formatter: tableHelpers.percentFormatter },
        { title: `Avg Loss`, hidden: props.mobileView, field: 'avgLoss', formatter: tableHelpers.percentFormatter },
        { title: `Max Win`, hidden: props.mobileView, field: 'maxWin', formatter: tableHelpers.percentFormatter },
        { title: `Max Loss`, hidden: props.mobileView, field: 'maxLoss', formatter: tableHelpers.percentFormatter },
    ]

    const timeframeStatsMapper = [
        { title: `Years`, field: 'years', sorters: true },
        { title: `Count`, field: 'count', formatter: tableHelpers.intFormatter, sorters: true },
        { title: `Net Profit`, hidden: props.mobileView, field: 'total_profit', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: `Annual Profit`, hidden: props.mobileView, field: 'annual_profit', formatter: tableHelpers.percentFormatter },
        { title: `Win %`, field: 'winning_percentage', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: `Avg Profit`, field: 'avg_profit', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: `Avg Win`, hidden: props.mobileView, field: 'avg_win', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: `Avg Loss`, hidden: props.mobileView, field: 'avg_loss', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: `Max Win`, hidden: props.mobileView, field: 'max_win', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: `Max Loss`, hidden: props.mobileView, field: 'max_loss', formatter: tableHelpers.percentFormatter, sorters: true },
    ]

    const backtestTradeMapper = [
        { title: `Purch Date`, field: 'purchase_date', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('purchase_date') },
        { title: `Purch Price`, field: 'purchase_price', formatter: tableHelpers.moneyFormatter, sorters: true },
        { title: `Sell Date`, field: 'sell_date', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('sell_date') },
        { title: `Sell Price`, field: 'sell_price', formatter: tableHelpers.moneyFormatter, sorters: true },
        { title: `Duration`, field: 'duration', formatter: tableHelpers.intFormatter, sorters: true },
        { title: `Profit`, field: 'profit', formatter: tableHelpers.percentFormatter, sorters: true },
    ]

    const plansMapper = [
        { title: `Plan`, field: 'name', sorters: true },
        { title: `Allocation`, field: 'percentage', formatter: tableHelpers.percentFormatter, sorters: true },
    ]

    function displayCurrentTrade() {
        if (tradeHistory && tradeHistory.length) {
            let trade = tradeHistory.find(t => t.sell_price === null);
            let stats = timeframeStats.find(s => s.years === 3);
            if (trade && quote && stats) {
                let profit = (quote.close - trade.purchase_price) / trade.purchase_price * 100;
                return (
                    <div style={{ borderWidth: 1, borderStyle: 'solid', padding: '2px', backgroundColor: '#F0F0F0' }}>
                        <h5>Active Trade P/L: {tableHelpers.percentFormatter(profit)}</h5>
                        <ExpectancyChart width={250} profit={profit} avgWin={stats.avg_win} avgLoss={stats.avg_loss} />
                        {alertStatus ?
                            <><b>Sneak Peek: {alertStatus.currentBarStatus.toUpperCase()}</b>
                                <Tooltip text={" 🔍"} json={alertStatus.currentBarData} position={"left center"} />
                            </>
                            :
                            null}
                    </div>
                )
            }
        }
        return null;
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    let trades = getBacktestTrades().map(t => {
        return {
            purchaseDate: t.purchase_date,
            sellDate: t.sell_date
        }
    })

    function renderTradeOverview() {
        return (
            <>
                <h5>Trades</h5>
                <center>
                    Start Date: <DatePicker className="bg-transparent" selected={startDate} onChange={(date) => setStartDate(date)} />&nbsp;&nbsp;
                    End Date: <DatePicker className="bg-transparent" selected={endDate} onChange={(date) => setEndDate(date)} />

                    <br></br>
                    &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(1) }}>1 Year</Link>
                    &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(2) }}>2 Years</Link>
                    &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(3) }}>3 Years</Link>
                    &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(5) }}>5 Years</Link>
                    &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(10) }}>10 Years</Link>
                </center>

                {renderChart(view === 'Live Trades', view === 'Backtest Trades')}
                {renderRealizedGainChart(view === 'Live Trades')}
            </>
        )
    }

    return (
        <>
            <center>
                <h4>{algoInfo.trade_symbol} {algoInfo.display_name}
                </h4>
            </center>

            <center>
                <div>
                    {displayCurrentTrade()}
                    <RichTable data={getTimeframeStats().filter(stats => stats.years_number === 0 || stats.years_number === 10)} mappers={timeframeStatsMapper} className="table-striped table-hover table-condensed" />
                </div>
            </center>

            <br></br>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab value="average" label="Average Trade" />
                    <Tab value="performance" label="Performance" />
                    <Tab value="backtesttrades" onClick={() => setView("Backtest Trades")} label="Backtest" />
                    <Tab value="livetrades" onClick={() => setView("Live Trades")} label="Live Trades" />
                    <Tab value="robustness" label="Robustness" />
                    <Tab value="stock" label="Stock" />
                    {props.role >= 3 ? <Tab value="alert" label="Alert" /> : null}
                    <Tab value="plans" label="Plans" />
                </Tabs>
            </Box>
            <br></br>

            {tab === "robustness" ?
                <>
                    <h5>Robustness</h5>
                    <SingleAlgoRobustness algoId={algoId} />
                </>
                : null}

            {tab === "performance" ?
                <>
                    <h5>Performance By Time</h5>
                    <RichTable data={getTimeframeStats()} mappers={timeframeStatsMapper} className="table-striped table-hover table-condensed" />
                </>
                : null}
            {tab === "entriesexits" ?
                <>
                    <h5>Entries / Exits</h5>
                    <QuoteDateAnalysis symbol={algoInfo.symbol} trades={trades} />
                </>
                : null}

            {tab === "backtesttrades" ?
                <>
                    {renderTradeOverview()}
                    <h5>Backtest Trades</h5>

                    <div style={{ width: '100%' }}>
                        <RichTable pageSize={10} data={getStatsArray(false)} mappers={statsMapper} className="table-striped table-hover table-condensed" />
                    </div>

                    <center>
                        <div>
                            {renderPLCharts()}
                        </div>
                    </center>

                    <Accordion>
                        <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Review Trades
                        </AccordionSummary>
                        <AccordionDetails>
                            <RichTable pageSize={10} data={getBacktestTrades()} mappers={backtestTradeMapper} className="table-striped table-hover table-condensed" />
                        </AccordionDetails>
                    </Accordion>
                </>
                : null}

            {tab === "livetrades" ?
                <>
                    {renderTradeOverview()}
                    <div>
                        <h5>Live Trades</h5>
                        <div style={{ width: '100%' }}>
                            <RichTable pageSize={10} data={getStatsArray(true)} mappers={statsMapper} className="table-striped table-hover table-condensed" />
                        </div>

                        <Accordion>
                            <AccordionSummary
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                Review Trades
                            </AccordionSummary>
                            <AccordionDetails>
                                <RichTable pageSize={10} data={getLiveTrades()} mappers={liveTradesMapper} className="table-striped table-hover table-condensed" />
                            </AccordionDetails>
                        </Accordion>
                    </div>

                </>
                : null}

            {tab === "plans" ?
                <>
                    <div hidden={props.role < 3} >
                        <h5>Plans</h5>
                        <RichTable data={getPlanData()} mappers={plansMapper} className="table-striped table-hover table-condensed" />

                        {props.role === 3 ? <><br></br><center><TwoStepConfirmationButton id={algoId} text="DELETE ALGO" onConfirmed={handleDeleteAlgo} /></center></> : null}
                    </div>
                </>
                : null}

            {tab === "average" ?
                <>
                    <h5>Average Trade Timeline</h5>
                    <AlgoAverageTrade algoId={algoId} />
                </>
                : null}
            {tab === "stock" ?
                <>
                    <Stock symbol={algoInfo.trade_symbol} planId={props.planId} mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} />
                </>
                : null}

            {tab === "alert" ?
                <>
                    <ConfigureAlert mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} role={props.role} alertId={alert ? alert.idtrading_strategy_alerts : null} />
                </>
                : null}
        </>
    );
}

export default Algo;