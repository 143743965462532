import React from 'react';
import tableHelpers from '../helpers/TableHelpers.js'

function AlgoQuickStats(props) {

    if (isNaN(props.winningPercentage) || isNaN(props.avgProfit) || isNaN(props.avgWin) || isNaN(props.avgLoss) || isNaN(props.avgDuration) || isNaN(props.robustness))
        return <>Missing data for AlgoQuickStats</>

    return (
        <table style={{ width: '200px', height: '150px', border: 'solid', borderWidth: '1px' }}>
            <tr style={{ border: 'solid', borderWidth: '1px' }}>
                <td colspan="2" style={{ textAlign: 'center', fontSize: '12px', borderTop: 'black' }}>{props.algoType}</td>
            </tr>
            <tr style={{ border: 'solid', borderWidth: '1px', height: '100px' }}>
                <td style={{ width: '50%' }}>
                    <div style={{ fontSize: '35px', textAlign: 'center' }}>{tableHelpers.percentFormatterNoDecimals(props.winningPercentage, null)}</div>
                    <div style={{ fontSize: '12px', textAlign: 'center', fontWeight: 'bolder' }}>WIN RATE</div>
                </td>
                <td style={{ borderLeft: 'solid', borderLeftWidth: '1px' }}>
                    <table style={{ width: '100%' }}>
                        <tr>
                            <td><div style={{ fontSize: '20px', textAlign: 'center' }}>{tableHelpers.percentChangeFormatterOneDecimal(props.avgProfit, null)}</div>
                                <div style={{ width: '100%', textAlign: 'center', fontSize: '10px' }}>L:{tableHelpers.percentChangeFormatterOneDecimal(props.avgLoss, null)}   W:{tableHelpers.percentChangeFormatterOneDecimal(props.avgWin, null)}</div>
                                <div style={{ fontSize: '12px', textAlign: 'center', fontWeight: 'bolder' }}>AVG PROFIT</div>
                            </td>
                        </tr>
                        <tr style={{ borderTop: 'solid', borderTopWidth: '1px' }}>
                            <td>
                                <div style={{ textAlign: 'center', fontSize: '20px' }}>{tableHelpers.intFormatter(props.avgDuration, null)} days</div>
                                <div style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bolder' }}>AVG LENGTH</div>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr style={{ border: 'solid', borderWidth: '1px' }}>
                <td colspan="2" style={{ textAlign: 'center', fontSize: '12px', borderTop: 'black' }}>ROBUSTNESS: {tableHelpers.decimalFormatter(props.robustness, null)}</td>
            </tr>
        </table>
    )
}

export default AlgoQuickStats;