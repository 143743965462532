import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AlertConfiguration(props) {

    const [config, setConfig] = useState({})
    const [maxConfig, setMaxConfig] = useState({})
    const [defaultConfig, setDefaultConfig] = useState({})
    const [focusKey, setFocusKey] = useState(null);

    useEffect(() => {
        (async function () {

            if (props.config) {
                setConfig(props.config);
            }

            if (props.defaultConfig)
                setDefaultConfig(props.defaultConfig);

        })();
    }, [props.config, props.defaultConfig]);

    function updateConfigValue(key, value) {
        let temp = JSON.parse(JSON.stringify(config));
        if (typeof (temp[key] === 'Number'))
            temp[key] = value;
        else
            temp[key] = value;
        setConfig(temp);
        return temp;
    }

    function updateBooleanConfigValue(key) {
        let temp = JSON.parse(JSON.stringify(config));
        temp[key] = temp[key] !== undefined ? !temp[key] : !defaultConfig[key]
        setConfig(temp);
        props.onConfigUpdated(temp);
    }

    function updateMaxConfigValue(key, value) {
        let temp = JSON.parse(JSON.stringify(maxConfig));
        if (value.length)
            temp[key] = value
        else
            delete temp[key];

        setMaxConfig(temp);
        props.onMaxConfigUpdated(temp);
    }

    function addToConfigValue(key, increaseBy) {
        const newConfig = updateConfigValue(key, Number(config[key]) + increaseBy);
        props.onConfigUpdated(newConfig);
    }

    function handleKeyDown(event) {
        console.log("Key press", event.key);
        console.log(focusKey, config[focusKey]);
        if (event.key === 'Enter') {
            props.onConfigUpdated(config);
        } else if (event.key === 'ArrowUp') {
            if (focusKey != null) {
                addToConfigValue(focusKey, 1);
            }
        } else if (event.key === 'ArrowDown') {
            if (focusKey != null) {
                addToConfigValue(focusKey, -1);
            }
        }
    }

    return (
        <table border="2" >
            <tr>
                <th>Name</th>
                <th>Value</th>
                <th hidden={!props.showMaxConfig}>Range</th>
            </tr>
            {Object.keys(defaultConfig).map(key => {
                return (
                    <tr class="sameline">
                        <td>{key}</td>
                        {typeof (defaultConfig[key]) === "boolean" ?
                            <>
                                <td><center><input type="checkbox" checked={config[key]} onChange={(e) => updateBooleanConfigValue(key)}></input></center></td>
                                <td hidden={!props.showMaxConfig}></td>
                            </>
                            :
                            <>
                                <td class="sameline"><Link className="plus-minus-button" onClick={() => addToConfigValue(key, -1)}>⬇️</Link>
                                    &nbsp;&nbsp;&nbsp;<input onFocus={() => setFocusKey(key)} onBlur={() => { setFocusKey(null); }} type="text" size="5" value={config[key]} onKeyDown={handleKeyDown} onChange={(e) => updateConfigValue(key, e.target.value)}></input>
                                    &nbsp;&nbsp;<Link className="plus-minus-button" onClick={() => addToConfigValue(key, 1)}>⬆️</Link>
                                </td>
                                <td hidden={!props.showMaxConfig}><input type="text" size="5" value={maxConfig[key] ? maxConfig[key] : ""} onKeyDown={handleKeyDown} onChange={(e) => updateMaxConfigValue(key, e.target.value)}></input></td>
                            </>
                        }
                    </tr>
                )
            })}
        </table>
    );
}

export default AlertConfiguration;