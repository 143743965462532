import BacktestResults from './BacktestResults';
import React, { useState, useEffect } from 'react';


function LastBacktest(props) {

    const [backtestResults, setBacktestResults] = useState({});
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async function () {
            setIsLoading(true);

            const url = `/api/lastBacktestResults?planId=` + props.planId + "&liveTradesOnly=0";
            let text = await (await fetch(url)).json();

            const tempChartData = [];//[["Date", "Account Value", "S&P 500"]]
            text.dailyResults.forEach(day => tempChartData.push([new Date(day.date), day.total, day.baseline]))
            setChartData(tempChartData)

            console.log("response", text);
            setBacktestResults(text);

            setIsLoading(false);
        })();
    }, [props.planId]);


    return (
        <>
            <div>
                <BacktestResults isLoading={isLoading} mobileView={props.mobileView} planId={props.planId} backtestResults={backtestResults} onAlgoSelected={props.onAlgoSelected} chartData={chartData} />
            </div>
        </>
    );
}

export default LastBacktest;