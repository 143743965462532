import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap'
import ActionResult from '../components/ActionResult.js'

function ManageUsers(props) {
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [updates, setUpdates] = useState(0);
    const [result, setResult] = useState(null);

    useEffect(() => {
        (async function () {
            if (props.role === 3) {
                console.log("Loading users");
                const text = await (await fetch(`/api/users`)).json();
                setData(text);
                setOriginalData(text);
            } else {
                console.log("Skipping load because not authorized")
            }
        })();
    }, [updates, props.role]);

    async function handleClone(userId) {
        console.log("handleClone", userId);
        setResult(null);
        const url = `/api/userClone?userId=` + props.userId + "&cloneUserId=" + userId;
        console.log(url);
        let result = await (await fetch(url)).json();
        console.log(result);
        setResult(result);
        console.log(result);
        setUpdates(updates + 1)
    }
    async function handleDelete(userId) {
        console.log("handleDelete", userId);
        setResult(null);
        const url = `/api/userDelete?userId=` + props.userId + "&deleteUserId=" + userId;
        console.log(url);
        let result = await (await fetch(url)).json();
        console.log(result);
        setResult(result);
        setUpdates(updates + 1)
    }
    function shouldAllowEdits() {
        if (props.role === 3) return true;
        else return false;
    }
    function onEmailUpdate(userId, email) {
        const newData = data.map((user) => {
            if (user.idusers === userId) {
                return { ...user, email: email };
            }
            return user;
        });

        setData(newData);
    }
    async function handleSubmitUpdatedEmail(userId) {
        console.log("handleSubmitUpdatedEmail", userId);
        setResult(null);
        let user = data.find(u => u.idusers === userId);
        if (user) {
            const url = "/api/userUpdate?userId=" + props.userId + "&email=" + user.email + "&updateUserId=" + userId;
            console.log(url);
            let result = await (await fetch(url)).json();
            setResult(result);
            setUpdates(updates + 1)
        } else {
            console.log("Could not find user to update", userId);
        }
    }

    return (
        <div>
            {shouldAllowEdits() ?
                <Table border="1">
                    <tr>
                        <th>Email</th>
                        <th>Email opt out</th>
                        <th>Disabled</th>
                        <th>Manage</th>
                    </tr>
                    {originalData.map(user =>
                        <tr key={user.isusers}>
                            <td><input type="text" size="20" defaultValue={user.email} onChange={(e) => onEmailUpdate(user.idusers, e.target.value)}></input>
                                &nbsp;{shouldAllowEdits() ? <button class="btn btn-secondary" onClick={(e) => handleSubmitUpdatedEmail(user.idusers)}>Update</button> : null}
                            </td>
                            <td>{user.email_opt_out}</td>
                            <td>{user.disabled}</td>
                            <td><button class="btn btn-primary" onClick={() => handleClone(user.idusers)}>Clone</button>
                                {shouldAllowEdits() ? <button class="btn btn-danger" onClick={() => handleDelete(user.idusers)}>Delete</button> : null}</td>
                        </tr>
                    )}
                </Table>
                :
                <div class="empty-page" >Not allowed</div>
            }
            <br></br>

            <ActionResult result={result} />

        </div >);
}

export default ManageUsers;