/**
 * Checks the text value against a filter using a case-insensitive comparison
 * @param {string} filter The value of the user-entered filter
 * @param {*} value The value of the field to be filtered
 * @returns True if the filter matches, false otherwize
 */
function textFilter(filter, value) {
  if (value && filter) {
    return value.toLowerCase().includes(filter.toLowerCase())
  }
  return false
}

/**
 * Retrieve the filter function for the given filter type
 * Currently `text` and `choice` are implemented
 * @param {string} filterType `text` and `choice` are the available filter types
 * @returns Filter function for the given filter type
 */
export function getFilter(filterType) {
  switch (filterType) {
    case 'text':
    case 'choice':
      return textFilter;
    default:
      console.error(`Unknown filter type: ${filterType}`)
      return undefined;
  }
}